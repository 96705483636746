import boxOneImg from "../../../assets/images/sec-3-box-1-img.png";
import boxTwoImg from "../../../assets/images/sec-3-box-2-img.png";
import boxThreeImg from "../../../assets/images/sec-3-box-3-img.png";
import { useNavigate } from "react-router-dom";
import "./sectionThree.css";

const SectionThree = () => {
  const navigate = useNavigate();

  return (
    <div className="ethical__section-three--container">
      <div className="section__three-content">
        <h2 className="ethical__section-three--heading">
          Why Should You Choose Us?
        </h2>
        <div className="ethical__section-three--boxes">
          <div className="ethical__section-three--box">
            <img
              src={boxOneImg}
              alt="mentorship-img"
              className="ethical__section-three--box-img"
            />
            <p className="ethical__section-three-box--heading">Mentorship</p>
            <p className="ethical__section-three-box--sub-text">
              You will have access to experienced mentors who will provide
              guidance, support, and career advice throughout your journey in
              the Cykea Ethical Hacking Academy and beyond. 
            </p>
          </div>
          <div className="ethical__section-three--box">
            <img
              src={boxTwoImg}
              alt="development img"
              className="ethical__section-three--box-img"
            />
            <p className="ethical__section-three-box--heading">
              Career Development 
            </p>
            <p className="ethical__section-three-box--sub-text">
              We will guide you on writing CVs, job interview preparation, and
              job hunting. Empowering you to pursue and secure rewarding careers
              in offensive security roles, including ethical hacking,
              penetration testing, and related fields. 
            </p>
          </div>
          <div className="ethical__section-three--box">
            <img
              src={boxThreeImg}
              alt="Experience img"
              className="ethical__section-three--box-img"
            />
            <p className="ethical__section-three-box--heading">
              Hands-On Experience 
            </p>
            <p className="ethical__section-three-box--sub-text">
              We focus on practical, hands-on labs and real-world scenarios to
              provide you with valuable experience in vulnerability assessment,
              penetration testing, and ethical hacking techniques. 
            </p>
          </div>
        </div>
        {/* <button
          className="ethical__section-3__enroll-CTA"
          onClick={() => navigate("/ethical-hacking-enrollment")}
        >
          Enroll Now!
        </button> */}
      </div>
    </div>
  );
};

export default SectionThree;
