import { useEffect, useMemo, useState } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-mark-icon.svg";
import { ReactComponent as GreyedCheckIcon } from "../../assets/icons/greyed-checkmark-icon.svg";
import {
  setShowQuiz,
  setShowVideoPlayer,
  setShowPassedPracticalQ,
  setShowPracticalQustion,
} from "../../redux/course/courseActions";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../..";
// import axios from "axios";

const QuizItem = ({ video, courseId, setAddPracticalQ }) => {
  const module = useSelector((state) => state.module.module);

  const [quizAvailable, setQuizAvailable] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get(`/api/v1/quiz/quizavailablity/${courseId}`)
      .then((res) => {
        setQuizAvailable(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (quizAvailable === true) {
      setAddPracticalQ(true);
    }
  }, [quizAvailable]);

  // const moduleDone = () => {
  //   if (showPassed) {
  //     setShowPassed(false);
  //     setShowVideoPlayer(true);
  //   } else {
  //     return;
  //   }
  // };

  const displayQuiz = () => {
    // checkQuizElegibility();
    if (quizAvailable) {
      dispatch(setShowVideoPlayer(false));
      dispatch(setShowPassedPracticalQ(false));
      dispatch(setShowPracticalQustion(false));
      dispatch(setShowQuiz(true));
    } else {
      return;
    }
  };

  const checkQuizElegibility = () => {
    api
      .get(`/api/v1/practicalQuestion/passedmodule/${module.id}`)
      .then((res) => console.log())
      .catch((error) => console.log(error));
  };

  return (
    <div
      className="accordion__child-container"
      style={{
        cursor: "pointer",
        pointerEvents: quizAvailable ? "" : "none",
      }}
      onClick={() => displayQuiz("reeeeed")}
    >
      <span className="content__check-and-text">
        {quizAvailable ? (
          <CheckIcon style={{ marginRight: "1rem" }} />
        ) : (
          <GreyedCheckIcon style={{ marginRight: "1rem" }} />
        )}
        <span className="accordion__child-text">
          {video ? video?.title : "Final Quiz"}
          <span className="duration-and-start--CTA">
            {/* <p className="content-duration">
              {video ? video?.duration : 8}mins
            </p> */}
            <p className="start__practical-questions--CTA">Start</p>
          </span>
        </span>
      </span>
    </div>
  );
};

export default QuizItem;
