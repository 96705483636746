import { combineReducers } from "redux";
import courseReducer from "./course/courseReducer";
import { videoReducer } from "./video/videoReducer";
import practicalQuestionReducer from "./practicalTask/practicalTaskReducer";
import moduleReducer from "./module/moduleReducer";
import notificationReducer from "./Notification/notificationReducer";

export default combineReducers({
  course: courseReducer,
  video: videoReducer,
  module: moduleReducer,
  notification: notificationReducer,
  practicalQuestion: practicalQuestionReducer,
});
