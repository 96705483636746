import React, { useState, useContext, createContext } from "react";

const CourseIdContext = createContext();

export const useCourseIdContext = () => {
  return useContext(CourseIdContext);
};

export const CourseIdProvider = ({ children }) => {
  const [courseId, setCourseId] = useState("");

  return (
    <CourseIdContext.Provider value={{ courseId, setCourseId }}>
      {children}
    </CourseIdContext.Provider>
  );
};
