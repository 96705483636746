import { practicalQuestionActionTypes } from "./practicalTaskActionType";

const INITIAL_STATE = {
  allTasksDone: null,
  taskDone: null,
  practicalQuestions: null,
};

const practicalQuestionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case practicalQuestionActionTypes.TOGGLE_ALL_TASKS_DONE:
      return {
        ...state,
        allTasksDone: action.payload,
      };

    case practicalQuestionActionTypes.STORE_PRACTICAL_QUESTIONS:
      return {
        ...state,
        practicalQuestions: action.payload,
      };

    default:
      return state;
  }
};

export default practicalQuestionReducer;
