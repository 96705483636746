// import bannerImage from "../../assets/images/our-mission-bg.png";
import bannerImage from "../../assets/images/test-img.png";
import { ReactComponent as BannerIcon } from "../../assets/icons/our-mission-banner-icon.svg";
import "./ourMission.css";

const OurMission = () => {
  return (
    <div className="our__mission-container">
      <div className="our__mission-banner">
        {/* <BannerIcon className="our__mission-banner--icon" /> */}
        <img
          src={bannerImage}
          alt="our mission banner"
          className="our__mission-image"
        />
      </div>
      <p className="our__mission-heading">Our Mission</p>
      <div className="mission__text-container">
        <p className="mission__text">
          We're on a mission to make cybersecurity accessible to everyone, and
          give people what they need to start a career in cybersecurity. We
          noticed that a lot of cybersecurity learning providers are not
          accessible and inclusive enough. We also noticed that there is a large
          swath of people starting their journey, but lacking a clear direction
          on how to build skills that companies actually want. We know it's
          tough to get your foot in. But that's why we're here! In addition to
          core cybersecurity knowledge, we will teach you real-life case
          studies, the skills that matter, the industry career landscape, and
          the job market. The ambition is clear: to bring cybersecurity
          knowledge to the millions of people interested in it, regardless of
          location, disability, background or current level of knowledge. You
          are now one of us.
        </p>
      </div>
      <p className="our__mission-heading">Why Cykea Is Free</p>
      <div className="mission__text-container">
        <p className="mission__text">
          We understand the limitations that new starters face, and we didn't
          want a price tag to be added to the list. We want our Cybersecurity
          Fundamentals Certificate to be accessible to anybody interested in it.
          Our ambition to train and grow millions of cybersecurity professionals
          starts here, and the basic knowledge and guidance should be accessible
          to all. The ambition is clear: to bring cybersecurity knowledge to the
          millions of people interested in it, regardless of location,
          disability, background or current level of knowledge. You are now one
          of us.
        </p>
      </div>
    </div>
  );
};

export default OurMission;
