import { ReactComponent as CykeaWhiteLogo } from "../../assets/icons/cykea-white-logo.svg";
import { ReactComponent as NewLogo } from "../../assets/icons/cy-new-icon.svg";
import { useNavigate } from "react-router-dom";
import sectionImg from "../../assets/images/cy-new-section-two-img.png";
import "./sectionTwo.css";

const SectionTwo = () => {
  const navigate = useNavigate();

  return (
    <div className="sectionTwo__container">
      <img src={sectionImg} alt="" className="section__two-img" />
      <div className="sectionTwo__text-container">
        <p className="sectionTwo__main-text">
          <span className="new__icon-and-text">
            <NewLogo className="new__icon" /> to Cyber?
          </span>
          <br /> No Problem!
        </p>
        <p className="sectionTwo__sub-text">
          You don’t need previous experience in cybersecurity or tech to get
          started. We’ll start with the basics.
        </p>

        <button
          className="sectionTwo__CTA"
          onClick={() => navigate("/register")}
        >
          Start for FREE!
        </button>
      </div>
    </div>
  );
};

export default SectionTwo;
