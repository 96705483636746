import { useState } from "react";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-logo.svg";
import { ReactComponent as SuitcaseIcon } from "../../assets/icons/suitcase-icon.svg";
import { ReactComponent as IdeaIcon } from "../../assets/icons/idea-icon.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FezzantLogo } from "../../assets/icons/fezzant-logo2.svg";
import { ReactComponent as JoinArrowIcon } from "../../assets/icons/join-arrow.svg";
import background from "../../assets/images/video-background.png";
import backgroundM from "../../assets/images/cykea-mobile-back.png";
import mixpanel from "mixpanel-browser";
// import HubspotForm from "react-hubspot-form";
import HubspotContactForm from "../HubspotContactForm/HubspotContactForm";
import reactvideo from "../../assets/video/sample.mp4";

import "./waitList.css";

mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
  debug: true,
});

const WaitList = () => {
  const navigate = useNavigate();
  return (
    <div className="waitlist__container">
      <div className="waitList__container--content">
        <span className="logo-and-join--CTA">
          <span className="logo-and-text" style={{ display: "flex" }}>
            <CykeaLogo />
          </span>
          <button
            className="join__CTA"
            onClick={() => {
              mixpanel.track("JOIN-NOW-CLICK", { source: "page_top" });
              navigate("/wait-list-form");
            }}
          >
            Join waitlist
          </button>
        </span>
        <div className="waitlist__text-and-image--container">
          <div className="waiitlist__txt-container">
            <h1 className="waitList__main--text">
              Ignite Your
              <br /> Cyber Security Career.{" "}
              <SuitcaseIcon className="suitcase__icon" />
            </h1>
            <p className="waitList__sub--text">
              Curated to give you the best cybersecurity knowledge and equip you
              with what you need to land your first job.
            </p>
            {/* <p className="waitList__sub--text">
              Curated to give you the best cybersecurity
              <br /> knowledge and equip you with what you need
              <br /> to land your first job.
            </p> */}
          </div>
          <div className="video__container-and-background">
            <div
              className="guarding__div"
              // style={{ backgroundImage: `url(${background})` }}
            >
              <img src={background} alt="" className="another" />
              {/* <img src={backgroundM} alt="" className="another" /> */}
              <div
                className="video__player--div"
                // style={{ backgroundImage: `url(${background})` }}
              >
                <video
                  muted
                  loop
                  autoPlay
                  id="video"
                  // max-width="691"
                  // height="100%"
                  style={{ borderRadius: "1.5rem", background: "black" }}
                  className="video__player"
                >
                  <source
                    src={
                      // "https://fezzant-videos.s3.eu-west-2.amazonaws.com/Cykea/portrait-of-software-engineer-writing-code-arranging-glasses-and-being-happy-about--SBV-346764500-HD.mp4"
                      reactvideo
                    }
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
        <span className="join__CTA-and-poweredBy">
          <button
            className="join__waitlList-CTA"
            onClick={() => {
              mixpanel.track("JOIN-NOW-CLICK", { source: "page_bottom" });
              navigate("/wait-list-form");
            }}
          >
            Join Waitlist{" "}
            <JoinArrowIcon
              style={{ marginLeft: "2rem" }}
              className="join__arrow"
            />
          </button>
          {/* <div className="poweredBy__container"> */}
          <span className="poweredBy__container">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <IdeaIcon />
              <p
                style={{
                  marginLeft: "1rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#ADADAD",
                }}
              >
                Powered by
              </p>
            </span>
            <span className="partners__logos-span">
              <FezzantLogo />
            </span>
          </span>
          {/* </div> */}
        </span>
      </div>
    </div>
    //   )}
    // </>
  );
};

export default WaitList;
