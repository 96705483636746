export const faqsContent = [
  {
    question: "What is the best cybersecurity course for beginners?",
    answer:
      "The Cykea Cyber Security Fundamentals course is the best for beginners. It covers essential cybersecurity concepts and practices, providing a strong foundation.",
  },

  {
    question: "How should I start learning cybersecurity?",
    answer:
      "We’d recommend you have a fair level of knowledge about computing, IT and Networking concepts, then proceed to cybersecurity foundational courses and then more advanced or specialized training.",
  },
  {
    question: "Is cybersecurity easy to learn?",
    answer:
      "Cybersecurity can be challenging to learn, but with the right resources and community like Cykea, you'll get the hang of it in no time",
  },
  {
    question: "I have no IT knowledge; can I learn cybersecurity?",
    answer:
      "Yes, absolutely! Even without prior IT knowledge, you can learn cybersecurity. You’ll just have to dedicate some time to learn computing fundamentals before moving on to cybersecurity-specific training.",
  },
  {
    question: "Can I switch from my career to cybersecurity?",
    answer:
      "Yes, many successful cybersecurity professionals transitioned from fields such as law, psychology, anthropology, etc. Cykea offers programs designed to help you make a smooth switch, regardless of your background.",
  },
  {
    question: "Can I switch from my career to cyber security?",
    answer:
      "You can switch from any career. You just need a good foundation and that is what Cykea will give to you.",
  },
  {
    question: "How long is the Cykea Ethical Hacking Academy?",
    answer:
      "The Cykea Ethical Hacking Academy is an 18-week program, divided into two- or three-week sprints filled with engaging learning activities. This structure ensures an immersive and comprehensive learning experience.",
  },
  {
    question: "Is the Ethical Hacking Academy Beginner-Friendly?",
    answer:
      "Yes, Cykea’s ethical hacking academy is beginner friendly. However, you would need foundational knowledge of networking and computing. Cykea’s Cybersecurity Fundamentals Course can help you gain the required knowledge.",
  },
  // {
  //   question: "Do you offer pen testing?",
  //   answer:
  //     " This is a cybersecurity fundamentals course. Pentesting will be discussed as a career path, not in-depth. We will have more specialized courses as we build.",
  // },
];

export const ethicalHackingContent = [
  {
    question: "What Is Ethical Hacking?",
    answer:
      "Ethical Hacking is the practice of legally identifying and exploiting vulnerabilities in computer systems and networks to improve the security posture of an organization.",
  },
  {
    question: "Is The Ethical Hacking Course Live or Pre-Recorded?",
    answer:
      "Our ethical hacking course is offered online through live sessions. Recordings of classes would be available for all participants.",
  },
  {
    question: "Is the Ethical Hacking Course Beginner-Friendly?",
    answer:
      "Cykea’s ethical hacking course is beginner friendly, although you would need foundational knowledge of networking and computing. Our Cybersecurity Fundamentals Course can help you gain the required knowledge.",
  },
  {
    question: "How Is Penetration Testing Different from Ethical Hacking?",
    answer:
      "Penetration Testing involves actively testing computer systems and networks for vulnerabilities and exploiting them to test if they are secure. It is but one of the many duties of an ethical hacker, which also includes vulnerability assessment and security audits.",
  },
  {
    question: "What Are the Requirements for the Ethical Hacking Course?",
    answer:
      "We would require you to have working devices to carry out the hands-on training and labs we have prepared for you.",
  },
  {
    question: "Should I Know How to Code to Learn Ethical Hacking?",
    answer:
      "You do not need to know how to code to learn ethical hacking, but it would give you an edge in learning and practical tasks you might face. Knowledge of programming languages such as Python and JavaScript would be a plus.",
  },
  {
    question: "What Roles Can I Get After Studying Ethical Hacking?",
    answer:
      "Studying ethical hacking prepares you for roles such as Ethical Hacker, Penetration Tester, Incident Responder, Cyber Security Analyst, and many others. To learn more, check out or guide on Cybersecurity Career Paths.",
  },
];
