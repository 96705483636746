// import { ReactComponent as ExpiredIcon } from "../../assets/icons/expired-icon.svg";
// import { ReactComponent as CloseIcon } from "../../assets/icons/close-circle.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SubmitPromptIcon } from "../../assets/icons/submit-prompt-icon.svg";
// import Loading from "../Loading/Loading.component";
// import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";

import "./submitPrompt.css";

const SubmitPrompt = ({
  promptText,
  promptButtonTextOne,
  promptButtonTexttwo,
  markQuestions,
  functionOne,
  functionTwo,
  icon,
}) => {
  // const { setAuthDetails } = useAuthContext();
  const navigate = useNavigate();

  // const completeQuizSubmission = () => {
  //   markQuestions();
  //   toggleSubmitPrompt();
  // };

  return (
    // <div className="payment__options--div">
    <div className="submitPrompt__container">
      <div className="submitPrompt__content">
        {/* <span className="close__expiry--span">
            <CloseIcon
              style={{ cursor: "pointer", marginBottom: "3rem" }}
              onClick={() => {
                setAuthDetails("");
                localStorage.clear();
                navigate("/");
              }}
            />
          </span> */}
        <SubmitPromptIcon className="submit__prompt-icon" />
        <p className="submit__prompt-text">{promptText}</p>
        <div className="submitPrompt__CTAs">
          <button className="submit__quiz-CTA" onClick={() => functionOne()}>
            {promptButtonTextOne}
          </button>
          {promptButtonTexttwo ? (
            <button className="try__again-CTA" onClick={() => functionTwo()}>
              {promptButtonTexttwo}
            </button>
          ) : null}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SubmitPrompt;
