import { useState } from "react";
import { ReactComponent as CyberFundamentalsIcon } from "../../assets/icons/security-fundamentals-icon.svg";
import cardIconOne from "../../assets/icons/skill-card-icon.svg";
import cardIconTwo from "../../assets/icons/skill-card-icon-2.svg";
import cardIconThree from "../../assets/icons/skill-card-icon-3.svg";
import cardIconFour from "../../assets/icons/skill-card-icon-4.svg";
import cardIconFive from "../../assets/icons/skill-card-icon-5.svg";
import cardIconSix from "../../assets/icons/skill-card-icon-6.svg";
import { ReactComponent as XIcon } from "../../assets/icons/mobile-menu-x.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list-icon.svg";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import "./skillsPath.css";
import MobileNav from "../MobileNav/MobileNav";

const SkillsPath = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <MetaDecorator
        title="CyKea - Skills Path"
        description="Cykea's skills page"
      />

      <NavBar
        openMobileNav={openMobileNav}
        setOpenMobileNav={setOpenMobileNav}
      />
      <div className="skills__path-container">
        {openMobileNav ? (
          <MobileNav
            openMobileNav={openMobileNav}
            setOpenMobileNav={setOpenMobileNav}
          />
        ) : null}
        <h2 className="skills__path-heading">Skills Path</h2>
        <div className="main__skill-container">
          <CyberFundamentalsIcon className="main__skill-icon" />
          <div className="main__skill-text--box">
            <h2 className="main__skill-title">Cyber Security Fundamentals</h2>
            <p className="course__outline-heading">Course Outline</p>
            <span className="lists__span">
              <ul className="main__skill-path--list first__list">
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Intro to cyber security
                  concepts
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Real security stories
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Mitigations & Control
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Security assessments &
                  testing
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Identity & access
                </li>
              </ul>

              <ul>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Ethical & legal
                  considerations
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Cybersecurity career paths
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Exams & certifications
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Intro to cyber security
                  concepts
                </li>
                <li className="main__skill-item">
                  <ListIcon className="list__icon" /> Getting your first job
                </li>
              </ul>
            </span>

            <span className="skills__path-CTA--span">
              <button
                className="skills__path-start--CTA"
                onClick={() => navigate("/register")}
              >
                Get started for Free!
              </button>
            </span>
          </div>
        </div>
        <div className="other__skills-container">
          <div className="skill__card">
            <div className="skill__details">
              <div
                className="skill__image-box"
                style={{
                  backgroundImage: `url(${cardIconOne})`,
                }}
              />
              <div className="skill__text-details--box">
                <p className="skill__title-text">GRC Professional</p>
                <div className="skill__availability-tag">coming soon</div>
              </div>
            </div>
          </div>
          <div className="skill__card">
            <div className="skill__details">
              <div
                className="skill__image-box"
                style={{
                  backgroundImage: `url(${cardIconTwo})`,
                }}
              />
              <div className="skill__text-details--box">
                <p className="skill__title-text">
                  Offensive Secuirty: Become an Ethical Hacker
                </p>
                <div className="skill__availability-tag">coming soon</div>
              </div>
            </div>
          </div>
          <div className="skill__card">
            <div className="skill__details">
              <div
                className="skill__image-box"
                style={{
                  backgroundImage: `url(${cardIconThree})`,
                }}
              />
              <div className="skill__text-details--box">
                <p className="skill__title-text">Cloud Security Professional</p>
                <div className="skill__availability-tag">coming soon</div>
              </div>
            </div>
          </div>
          <div className="skill__card">
            <div className="skill__details">
              <div
                className="skill__image-box"
                style={{
                  backgroundImage: `url(${cardIconFour})`,
                }}
              />
              <div className="skill__text-details--box">
                <p className="skill__title-text">
                  Cyber Threat Intelligence Skill
                </p>
                <div className="skill__availability-tag">coming soon</div>
              </div>
            </div>
          </div>
          <div className="skill__card">
            <div className="skill__details">
              <div
                className="skill__image-box"
                style={{
                  backgroundImage: `url(${cardIconFive})`,
                }}
              />
              <div className="skill__text-details--box">
                <p className="skill__title-text">Malware Analysis</p>
                <div className="skill__availability-tag">coming soon</div>
              </div>
            </div>
          </div>
          <div className="skill__card">
            <div className="skill__details">
              <div
                className="skill__image-box"
                style={{
                  backgroundImage: `url(${cardIconSix})`,
                }}
              />
              <div className="skill__text-details--box">
                <p className="skill__title-text">
                  Cyber Human Factor Professional
                </p>
                <div className="skill__availability-tag">coming soon</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SkillsPath;
