import { useState, useEffect, useMemo } from "react";
import { useAuthUserDataContext } from "../../contexts/AuthUserDataContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { api } from "../../index";
import "./profileDataForm.css";
import Loader from "../Loader/Loader";

const PWD_REGEX = /^.{8,24}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const UPDATEPASSWORDURL = "/api/v1/user/resetpassword";
const UPDATEDETAILSURL = "/updateUserDetails/:id";

const ProfileDataForm = () => {
  const [type, setType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const { authUserData } = useAuthUserDataContext();

  useEffect(() => {
    setLoading(true);
    setFirstName(authUserData.user?.firstName);
    setLastName(authUserData.user?.lastName);
    setPhoneNumber(authUserData.user?.phone);
    setLoading(false);
  }, [authUserData]);

  useEffect(() => {
    const result = PWD_REGEX.test(newPassword);
    setValidPwd(result);
    const match = newPassword === matchPwd;
    setValidMatch(match);
  }, [newPassword, matchPwd]);

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const {
    formState: { errors },
  } = useForm();

  const upDatePassword = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await api.post(UPDATEPASSWORDURL, {
        password: newPassword,
        id: authUserData?.user.id,
      });
      setSuccess(true);
      localStorage.setItem("token", response.data?.token);
      toast.success("Password Updated Successfully!");
      setOldPassword("");
      setNewPassword("");
      setMatchPwd("");
      setLoading(false);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Account Already Exists");
      } else {
        setErrMsg("Password Reset Failed");
      }

      setLoading(false);
    }
  };

  const upDateDetails = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await api.put(
        `/api/v1/user/updateUserDetails/${authUserData?.user.id}`,
        {
          firstName,
          lastName,
          phone: phoneNumber,
        }
      );
      setSuccess(true);
      setLoading(false);
      toast.success("Data Updated Successfully!");
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 400) {
        setErrMsg("Account Already Exists");
      } else {
        setErrMsg("Password Reset Failed");
      }

      setLoading(false);
    }
  };

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <p className="profile__page--heading">User Details</p>
      <form className="user__details--form-container" onSubmit={upDateDetails}>
        <span className="user__details--input">
          <label htmlFor="email" className="userDetails--label">
            Email Address
          </label>
          <input
            type="text"
            value={authUserData.user?.email}
            className="user__data"
            disabled
          />
        </span>

        <span className="user__details--input">
          <label htmlFor="firstName" className="userDetails--label">
            First Name
          </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="user__data"
            required
          />
        </span>

        <span className="user__details--input">
          <label htmlFor="lastName" className="userDetails--label">
            Last Name
          </label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="user__data"
            required
          />
        </span>

        <button
          className="save__user--data-CTA"
          disabled={!firstName || !lastName}
        >
          Save Changes
        </button>
      </form>
      <p className="profile__page--heading" style={{ marginTop: "4rem" }}>
        Change Password
      </p>
      <form
        className="user__details--password-container"
        onSubmit={upDatePassword}
      >
        <span className="user__details--input">
          <label htmlFor="oldPassword" className="userDetails--label">
            Enter Old Password
          </label>

          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className={errors.password ? "password--input" : "user__data"}
            required
          />

          {errors.password && (
            <p
              style={{
                color: "#F95959",
                marginTop: "1rem",
              }}
            >
              Required Field
            </p>
          )}
        </span>
        <span className="user__details--input">
          <label htmlFor="newPassword" className="userDetails--label">
            Enter New Password
          </label>
          <div className="login__password-and-icons">
            <input
              type={type}
              className="user__data"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <p id="pwdnote" className={!validPwd ? "instruction" : "offScreen"}>
              8 to 24 characters.
              {/* <br />
              Must include uppercase and lowercase letters, a number and a
              special character.
              <br />
              Allowed special characters:{" "}
              <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span>{" "}
              <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span>{" "}
              <span aria-label="percent">%</span> */}
            </p>
            {type === "password" ? (
              <EyeLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            ) : (
              <EyeOffLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            )}
          </div>
        </span>
        <span className="user__details--input">
          <label htmlFor="retypePassword" className="userDetails--label">
            Re-type New Password
          </label>
          <div className="login__password-and-icons">
            <input
              type={type}
              value={matchPwd}
              onChange={(e) => setMatchPwd(e.target.value)}
              className="user__data"
            />
            <p
              id="confirmnote"
              className={!validMatch ? "instruction" : "offScreen"}
            >
              {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
              Must match the first password input field.
            </p>
            {type === "password" ? (
              <EyeLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            ) : (
              <EyeOffLogo
                className="profile__eye__icon"
                onClick={togglePasswordType}
              />
            )}
          </div>
        </span>

        <button
          className="save__user--data-CTA"
          disabled={!validPwd || !validMatch ? true : false}
        >
          Change Password
        </button>
      </form>
    </>
  );
};

export default ProfileDataForm;
