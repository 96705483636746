import {
  setShowFailed,
  setShowQuiz,
  setShowVideoPlayer,
} from "../../redux/course/courseActions";
import { useDispatch } from "react-redux";
import "./failed.css";

const Failed = ({ score }) => {
  const dispatch = useDispatch();

  const retryQuiz = () => {
    dispatch(setShowFailed(false));
    dispatch(setShowQuiz(true));
  };
  const goBackToCourse = () => {
    dispatch(setShowFailed(false));
    dispatch(setShowVideoPlayer(true));
    // getSelectedVidUrl(course.modules[0].videos[0].url, 0);
    // setTimeout(() => {
    //   localStorage.setItem("moduleDetails", JSON.stringify(course.modules[0]));
    // }, 3000);
  };

  return (
    <div className="videoPlayer__container--two">
      <div
        className="passed__quiz-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div className="passed__component-content">
          <p className="passed__quiz-text">
            Uh oh! You didn't pass.
            <br />
            Your score
          </p>
          <p className="quiz__score" style={{ color: "#CC2442" }}>
            {score}%
          </p>
          <span className="passed__container-CTA--box">
            <button
              className="retry__quiz-CTA"
              onClick={() => {
                goBackToCourse();
              }}
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                width: "fit-content",
              }}
            >
              Back to course
            </button>
            <button
              className="continue__from-quiz--CTA"
              onClick={() => {
                retryQuiz();
              }}
            >
              Try again
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Failed;
