import { practicalQuestionActionTypes } from "./practicalTaskActionType";

export const toggleAllTasksDone = (status) => ({
  type: practicalQuestionActionTypes.TOGGLE_ALL_TASKS_DONE,
  payload: status,
});

export const toggleTaskDone = (status) => ({
  type: practicalQuestionActionTypes.TOGGLE_TASK_DONETASK_DONE,
  payload: status,
});

export const storePracticalQuestions = (data) => ({
  type: practicalQuestionActionTypes.STORE_PRACTICAL_QUESTIONS,
  payload: data,
});
