import { notificationActionTypes } from "./notificationActionTypes";

const INITIAL_STATE = {
  toggleAlert: false,
  showSuccess: false,
  showError: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case notificationActionTypes.TOGGLE_ALERT:
      return {
        ...state,
        showAlert: action.payload,
      };

    case notificationActionTypes.SHOW_SUCCESS:
      return {
        ...state,
        showSuccess: action.payload,
      };
    case notificationActionTypes.SHOW_ERROR:
      return {
        ...state,
        showError: action.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
