export const videoActionTypes = {
  SET_VIDEO_DURATION: "SET_VIDEO_DURATION",
  SET_CURRENT_VIDEO: "SET_CURRENT_VIDEO",
  SET_VIDEO_URL: "SET_VIDEO_URL",
  SET_SUB_URL: "SET_SUB_URL",
  UPDATE_VIDEO_WATCH_STATUS: "UPDATE_VIDEO_WATCH_STATUS",
  SET_PLAYER_LOADING: "SET_PLAYER_LOADING",
  SET_NEXT_VIDEO_INDEX: "SET_NEXT_VIDEO_INDEX",
  INCREASE_VIDEO_INDEX: "INCREASE_VIDEO_INDEX",
  DECREASE_VIDEO_INDEX: "DECREASE_VIDEO_INDEX",
  UPDATE_COMPLETED_VIDEOS: "UPDATE_COMPLETED_VIDEOS",
};
