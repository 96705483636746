import { useEffect, useRef, useState } from "react";
import { ReactComponent as Barge } from "../../assets/icons/cy-cert-barge.svg";
import { ReactComponent as CeoSignature } from "../../assets/icons/ceo-signature.svg";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import { api } from "../..";
import { useParams, Link } from "react-router-dom";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import "./shareCert.css";

const ShareCert = () => {
  const [certificate, setCertificate] = useState();
  const [issuedDate, setIssuedDate] = useState();
  const { id } = useParams();

  useEffect(() => {
    api.get(`/api/v1/certifcate/getcert/${id}`).then((res) => {
      setCertificate(res.data.certificate);
      convertDate(res.data.certificate.createdAt);
    });
  }, []);

  const convertDate = (date) => {
    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const issued = date;

    const d = new Date(issued.substr(0, 10));
    let month = months[d.getMonth()];
    const year = d.getFullYear();
    const day = d.getDate();
    const issuedDate = `${day}${nth(day)} of ${month}, ${year}`;
    setIssuedDate(issuedDate);
  };

  return (
    <div className="share__cert-main--page">
      <MetaDecorator
        title="Shared Cykea Certificate"
        description="Shared on Cykea by a student"
      />
      <Link to="/" aria-label="Cykea's Logo">
        <CykeaLogo className="cykea__landing-logo" />
      </Link>

      {certificate && (
        <div className="shared__cert-container">
          <div className="passed__container shared__cert-container">
            <span className="passed__content">
              <div className="cert__content--container shared__cert--content-container">
                <div className="cert__desc-and-barge">
                  <span className="desc-and-sign">
                    <span className="desc-and-name--span">
                      <p className="cert_of_completion">
                        Certification Of Completion
                      </p>
                      <p className="holders__name">
                        {certificate.name.toUpperCase()}
                        {/* Abdullah Adam */}
                      </p>
                      <p className="certificate__description">
                        has successfully completed the {certificate.courseName}{" "}
                        course.
                      </p>
                    </span>
                    <div className="barge">
                      <span className="sign__and__date">
                        <span className="sign__and__name">
                          <CeoSignature className="signature" />

                          <p className="cert__Ceo">CEO, Cykea</p>
                        </span>
                        <span className="cert__date--span">
                          <span className="date__ofissuance--span">
                            <p className="cert__date">{issuedDate}</p>
                          </span>
                          <p className="cert__date--text">Date</p>
                        </span>
                      </span>
                      <span className="barge__and__cert-no--span">
                        <Barge className="cert__barge-icon" />
                        <p className="cert__number">
                          {certificate.certificateNumber}
                        </p>
                      </span>
                    </div>
                  </span>
                </div>
              </div>

              {/* <span className="share__and__continue--CTA">
              <span className="download__cert-and-share--CTAs">
                <DownloadIcon
                  style={{ cursor: "pointer" }}
                  className="download__cert--CTA"
                  onClick={() => {
                    navigate("/panel/download-certificate");
                  }}
                />

                <span className="share__cert--CTA">
                  {showShareOptions ? (
                    <span className="share__pop--up">
                      <p className="share__heading">Share</p>

                      <div className="social__share--icons">
                        <TwitterShareButton
                          url={`https://3684-105-113-18-76.ngrok-free.app`}
                          title="Check my Cykea certificate!"
                          via="I just completed Fezzant's Email Security series! Checkout out my shiny new certificate. Cool, innit?"
                          hashtags={[
                            "cykeacertified",
                            "emailsecurityexpertcomingthrough",
                          ]}
                          description="I just completed Cykea's Email Security series! Checkout out my shiny new certificate. Cool, innit?"
                        >
                          <span className="logo_link--span">
                            <TwitterShareIcon borderRadius={3} size={22} />{" "}
                            <p className="share__icon--text">Twitter</p>
                          </span>
                        </TwitterShareButton>

                        <button
                          onClick={handleLinkedInAuth}
                          className="linkedin__share-button"
                        >
                          <LinkedinShareIcon borderRadius={3} size={22} />
                          <p className="linkedin__share-button--text">
                            Linkedin
                          </p>
                        </button>
                        <CopyToClipboard
                          text="https://staging.cykea.com/shared-cert"
                          onCopy={() => alert("Copied")}
                        >
                          <span
                            className="logo_link--span"
                            style={{ cursor: "pointer" }}
                          >
                            <LinkIcon />{" "}
                            <p className="share__icon--text">Copy Link</p>
                          </span>
                        </CopyToClipboard>
                      </div>
                    </span>
                  ) : null}
                  <ShareIcon
                    style={{ cursor: "pointer" }}
                    className="share__cert-CTA--icon"
                    onClick={() => {
                      setShowShareOptions(!showShareOptions);
                    }}
                  />
                </span>
              </span>
              <button
                className="continue__CTA"
                onClick={() => navigate("/panel/courses")}
              >
                Continue{" "}
              </button>
            </span> */}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareCert;
