import { useSearchParams } from "react-router-dom";
import courseShareThumbnail from "../../assets/images/course-share-thumbnail.png";
import certshareThumbnail from "../../assets/images/cert-share-thumbnail.png";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { api } from "../..";
import { useState } from "react";
import "./shareToLinkedin.css";

const ShareOnLinkedin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const code = searchParams.get("code");

  const shareType = searchParams.get("state");

  const courseId = localStorage.getItem("courseId");

  const navigate = useNavigate();

  const courseShareProps = {
    text: `I’m currently learning about Cybersecurity Fundamentals on CyKea! Join me at https://www.cykea.com`,
    thumbnail: `uploadsToLinkedin/courses/email security.png`,
  };

  const certShareProps = {
    text: `I've just earned a certificate for completing the Cybersecurity Fundamentals course on Cykea! join me at https://www.cykea.com`,
    thumbnail: `uploadsToLinkedin/certificates/email security.png`,
  };

  const handleRedirect = () => {
    setLoading(false);
    toast.success("Post shared successfully!");
    setTimeout(
      () => navigate(`/panel/courses/single-course/${courseId}`),
      [2500]
    );
  };

  const shareToLinkedin = () => {
    api
      .post("/api/v1/certifcate/linkedin-share", {
        postText:
          shareType === "course" ? courseShareProps.text : certShareProps.text,
      })
      .then((res) => {
        handleRedirect();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occured. Please retry");
        setLoading(false);
      });
  };

  const getLinkedinToken = () => {
    setLoading(true);
    api
      .post(`/api/v1/certifcate/linkedin-callback/${code}`, {
        thumbnail:
          shareType === "course"
            ? courseShareProps.thumbnail
            : certShareProps.thumbnail,
      })
      .then(() => shareToLinkedin())
      .catch((error) => {
        console.log(error);
        toast.error("An error occured. Please retry");
        setLoading(false);
      });
  };

  return (
    <div className="share__to-linkedin">
      {loading ? (
        <div className="loader__container" style={{ position: "fixed" }}>
          <FadeLoader color="purple" loading={loading} size={15} />
        </div>
      ) : null}
      <Link to="/" aria-label="Cykea's Logo">
        <CykeaLogo className="cykea__landing-logo" />
      </Link>
      <div className="share__to-linkedin--container">
        <h1 className="share__to-linkedin--heading">Post Preview</h1>
        <div className="share__to-linkedin--content">
          <p className="share__to-linkedin--text">
            {shareType === "course"
              ? courseShareProps.text
              : certShareProps.text}
          </p>
          <img
            className="share__thumbnail"
            src={
              shareType === "course" ? courseShareThumbnail : certshareThumbnail
            }
            alt="certificate thumbnail"
          />
        </div>
        <div className="share__to-linkedin--CTAs">
          <button
            onClick={getLinkedinToken}
            className="share__to-linkedin--CTA"
          >
            Share on LinkedIn
          </button>
          <button
            onClick={() => navigate(`/panel/courses/single-course/${courseId}`)}
            className="back__to-course--CTA"
          >
            Back to course
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareOnLinkedin;
