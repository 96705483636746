import { ReactComponent as IgniteIcon } from "../../assets/icons/cykea-I-svg.svg";
import lightIcon from "../../assets/icons/ethical-hacking-notice-light.png";
import { ReactComponent as NewIcon } from "../../assets/icons/ethical-btn-new.svg";
import { useNavigate } from "react-router-dom";
import headerLadyImageTwo from "../../assets/images/cy-header-lady-two.png";
import headerGuyImageTwo from "../../assets/images/cy-header-guy-two.png";
import useAnalyticsEventTracker from "../../useAnalyticsEventsTracker";
import GA4React from "ga-4-react";
import "./header.css";

const Header = () => {
  const gaEventTracker = useAnalyticsEventTracker("Header");

  const navigate = useNavigate();

  const ga4react = new GA4React("G-P7VBWEMSQ2");
  ga4react.initialize();

  return (
    <div className="header__container">
      <div className="header__content-container">
        <div className="header__text-container">
          <div
            className="ethical__hacking-CTA"
            onClick={() => navigate("/ethical-hacking")}
          >
            <img src={lightIcon} alt="siren-icon" className="ethical__siren" />
            <div className="ethical__hacking-text--box">
              <h1 className="ethical__hacking-main--text">
                Cykea Ethical Hacking Academy
              </h1>
              <p className="ethical__hacking-sub--text">
                Limited slots available, click to register
              </p>
            </div>
          </div>
          <h1 className="header__main--text">
            <span className="ignite__icon-span">
              <IgniteIcon className="ignite__icon" />I
            </span>
            gnite Your
            <br /> Cyber Security
            <br />
            Career!
          </h1>

          <p className="header__sub-text">
            We’re on a mission to make Cybersecurity accessible to everyone,
            everywhere.
          </p>
          <span className="header__CTAs-span">
            <button
              className="enroll__now-CTA"
              onClick={() => {
                navigate("/register");
                // gaEventTracker("Enroll Now");
                ga4react.event({
                  category: "Navbar",
                  action: "Get Started CTA",
                  label: "Test label", // optional
                });
              }}
            >
              Start for FREE!
            </button>
            <button
              className="header__ethical-hacking--CTA"
              onClick={() => navigate("/ethical-hacking")}
            >
              <NewIcon className="ethical__hacking-new--icon" />
              Ethical Hacking Academy
            </button>
          </span>
        </div>
      </div>
      <img src={headerGuyImageTwo} alt="" className="header__guy-img" />
      <img src={headerLadyImageTwo} alt="" className="header__lady-img" />
    </div>
  );
};

export default Header;
