import { useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import QuizQuestionComponent from "../QuizQuestion/QuizQuestionComponent";
import PracticalQuestion from "../PracticalQuestion/PracticalQuestion";
import Passed from "../Passed/Passed";
import Certificate from "../Certificat/Certificat";
import Failed from "../Failed/Failed";
import { useDispatch, useSelector } from "react-redux";
import PassedQuiz from "../PassedQuiz/PassedQuiz";
import {
  increaseVideoIndex,
  setCurrentVideo,
  setNextVideoIndex,
  setVideoUrl,
} from "../../redux/video/videoActions";
import {
  setShowPassed,
  setShowFailed,
  setShowVideoPlayer,
  setShowPracticalQustion,
  setShowPassedPracticalQ,
  setShowQuiz,
  setShowOverlay,
  storeCertificate,
  setShowCertificate,
  storeCourse,
} from "../../redux/course/courseActions";
import { setModule } from "../../redux/module/moduleActions";
import { api } from "../..";
import VideoPlayerTwo from "../VideoPlayerTwo/VideoPlayerTwo";
import AccordionContainer from "../AccordionContainer/AccordionContainer";
import CourseOverview from "../CourseOverview/CourseOverview";
import CourseDetailBanner from "../CourseDetailBanner/CourseDetailBanner";
import "./singleCourse.css";
import Loader from "../Loader/Loader";

const SingleCourse = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentQuestionNum, setCurrentQuestionNum] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [answers, setAnswers] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [showResult, setShowResult] = useState(false);
  // const [certificate, setCertificate] = useState();
  const [showQuestion, setShowQuestion] = useState(false);
  const [score, setScore] = useState();
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [showSubmitPropmt, setShowSubmitPrompt] = useState(false);
  // const [showCertificate, setShowCertificate] = useState(false);
  const [course, setCourse] = useState();
  const [paidcourses, setPaidCourse] = useState();
  const [videosState, setVideosState] = useState(false);
  const [moduleToLocalStorage, setModuleToLocalStorage] = useState(false);

  const courseId = localStorage.getItem("courseId");

  const showPassed = useSelector((state) => state.course.showPassed);

  const showFailed = useSelector((state) => state.course.showFailed);

  const certificate = useSelector((state) => state.course.certificate);

  const showVideoPlayer = useSelector((state) => state.course.showVideoPlayer);

  const showFreeBanner = useSelector((state) => state.course.showFreeBanner);

  const showPracticalQustion = useSelector(
    (state) => state.course.showPracticalQuestion
  );

  const showPassedPracticalQ = useSelector(
    (state) => state.course.showPassedPracticalQuestion
  );

  const showQuiz = useSelector((state) => state.course.showQuiz);

  const showCertificate = useSelector((state) => state.course.showCertificate);

  const dispatch = useDispatch();

  // const courseId = useParams();

  // const courseId = { id: "afd23328-6982-4203-9e36-cdd02e7f966a" };
  // const courseId = { id: "affae8be-4208-43f0-b291-17875ef0bfd5" };

  const checkQuizElegibility = (id) => {
    api
      .get(`/api/v1/practicalQuestion/passedmodule/${id}`)
      .then((res) => console.log())
      .catch((error) => console.log(error));
  };

  const getQuizQuestions = useCallback(() => {
    api
      .get(`/api/v1/question/getquestions/${courseId}`)
      .then((res) => {
        setQuizQuestion(res.data.questions);
      })
      .catch((err) => console.log(err));
  }, [courseId]);

  useEffect(() => {
    api.get(`/api/v1/certifcate/getExistingCert/${courseId}`).then((res) => {
      dispatch(storeCertificate(res.data.certificate));
    });
  }, []);

  useEffect(() => {
    api
      .get(`/api/v1/course/${courseId}`)
      .then((res) => {
        setCourse(res.data?.course);
        dispatch(storeCourse(res.data?.course));
        checkQuizElegibility(res.data?.course?.modules[0].id);
        // localStorage.setItem("courseId", courseId);
        if (course) {
          getQuizQuestions();
        }

        setModuleToLocalStorage(true);
        localStorage.setItem(
          "moduleDetails",
          JSON.stringify(res.data.course.modules[0])
        );
        dispatch(setModule(res.data.course.modules[0]));
        dispatch(setVideoUrl(res.data.course.modules[0].videos[0].url));
        dispatch(setCurrentVideo(res.data.course.modules[0].videos[0]));
        dispatch(setNextVideoIndex(0));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [showQuiz]);

  const playNextVideo = () => {
    dispatch(setShowOverlay(false));
  };

  const onVideoEnd = () => {
    dispatch(increaseVideoIndex());
    dispatch(setShowOverlay(true));
    // setRerender(!reRender);
  };

  ////////Logic for learning checks and videos

  const toggleShowVideoPlayer = () => {
    dispatch(setShowVideoPlayer(!showVideoPlayer));
  };

  const toggleShowQuestion = () => {
    setShowQuiz(!showQuiz);
    dispatch(setShowVideoPlayer(true));
  };

  const selectAnswer = (questionId, value) => {
    const newAnswer = {
      questionId: currentQuestionId ? currentQuestionId : questionId,
      answer: value,
    };
    setSelectedAnswer(newAnswer);
  };

  const recordAnswer = (questionId, currentQuestionNum) => {
    const existingAnswer = answers.map((answer) =>
      answer.questionId === questionId ? true : false
    );

    if (existingAnswer[currentQuestionNum] && selectedAnswer !== "") {
      setAnswers((prevState) =>
        prevState.map((answer) =>
          answer.questionId === questionId
            ? { ...answer, answer: selectedAnswer.answer }
            : answer
        )
      );
      setSelectedAnswer("");
    } else {
      if (selectedAnswer !== "") {
        const newAnswer = {
          questionId: currentQuestionId ? currentQuestionId : questionId,
          answer: selectedAnswer.answer,
        };

        setAnswers([...answers, newAnswer]);
      }
      setSelectedAnswer("");
    }
  };

  const markQuestions = () => {
    // dispatch(setPlayerLoading(true));
    dispatch(setShowQuiz(false));
    api
      .post(`/api/v1/question/markQuestions/${courseId}`, {
        answer: answers,
      })
      .then((res) => {
        setScore(res.data.score);
        // setCertificate(res.data);
        if (!certificate) {
          dispatch(storeCertificate(res.data.certificate));
        }
        if (res.data.score < 70) {
          dispatch(setShowFailed(true));
          setLoading(false);
        } else {
          dispatch(setShowPassed(true));
          setLoading(false);
        }

        setShowResult(!showResult);
      })
      .catch((err) => {
        setShowResult(!showResult);
        console.log(err);
      });
  };

  const toggleSubmitPrompt = () => {
    setShowSubmitPrompt(!showSubmitPropmt);
  };

  const showPassOrFail = () => {
    setShowResult(!showResult);
    setShowQuestion(!showQuestion);
  };

  const questionsLength = quizQuestion.length - 1;

  const completeQuizSubmission = () => {
    markQuestions();
    toggleSubmitPrompt();
  };

  return (
    <>
      {!showFreeBanner && loading ? <Loader /> : null}
      {showFreeBanner ? <CourseDetailBanner /> : null}
      <div
        className={
          certificate
            ? "single__course-container certificate__available-style"
            : "single__course-container"
        }
      >
        <span className="single__course-heading--span"></span>
        <span className="single__course-heading--mobile">
          <span
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => navigate("/panel/courses")}
          >
            {/* <LeftIcon style={{ marginRight: "1.2rem" }} />
            <p className="single__course-heading--text">Back</p> */}
          </span>
        </span>
        <div className="single__course-content--container">
          <div className="single__course-video-and-overview--container">
            {!showVideoPlayer &&
              showQuiz &&
              quizQuestion.map((question, index) => {
                if (index === currentQuestionNum) {
                  return (
                    <QuizQuestionComponent
                      toggleShowVideoPlayer={toggleShowVideoPlayer}
                      options={question.options}
                      question={question.question}
                      currentQuestionNum={currentQuestionNum}
                      setCurrentQuestionNum={setCurrentQuestionNum}
                      questionsLength={questionsLength}
                      completeQuizSubmission={completeQuizSubmission}
                      showSubmitPropmt={showSubmitPropmt}
                      selectedAnswer={selectedAnswer}
                      selectAnswer={selectAnswer}
                      answers={answers}
                      questionId={question.id}
                      recordAnswer={recordAnswer}
                      markQuestions={markQuestions}
                      setSelectedAnswer={setSelectedAnswer}
                      toggleShowQuestion={toggleShowQuestion}
                      showPassOrFail={showPassOrFail}
                      questions={quizQuestion}
                      setCurrentQuestionId={setCurrentQuestionId}
                      toggleSubmitPrompt={toggleSubmitPrompt}
                    />
                  );
                } else {
                  return;
                }
              })}

            {!showVideoPlayer && showPassed ? (
              <PassedQuiz
                showCertificate={showCertificate}
                setShowCertificate={setShowCertificate}
                score={score}
              />
            ) : null}
            {!showVideoPlayer && showFailed ? (
              <Failed
                setShowVideoPlayer={setShowVideoPlayer}
                score={score}
                course={course}
              />
            ) : null}

            {showPassedPracticalQ && !showVideoPlayer ? <Passed /> : null}

            {!showVideoPlayer && showCertificate ? (
              <Certificate certificate={certificate} />
            ) : null}

            {course && showVideoPlayer ? (
              <VideoPlayerTwo
                themeName="forest"
                playNextVideo={playNextVideo}
                onVideoEnd={onVideoEnd}
                course={course}
              />
            ) : null}

            {!showVideoPlayer && course && showPracticalQustion ? (
              <PracticalQuestion />
            ) : null}
            <CourseOverview course={course} />
          </div>
          <AccordionContainer />
        </div>
      </div>
    </>
  );
};

export default SingleCourse;
