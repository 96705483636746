import { useState, useEffect, useMemo } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-mark-icon.svg";
import { ReactComponent as GreyedCheckIcon } from "../../assets/icons/greyed-checkmark-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setDuration, setVideoUrl } from "../../redux/video/videoActions";
import {
  setShowPassedPracticalQ,
  setShowPracticalQustion,
  setShowVideoPlayer,
  setShowQuiz,
  setShowPassed,
  setShowCertificate,
  setShowFailed,
  switchPracticalQuestions,
} from "../../redux/course/courseActions";
import { setModule } from "../../redux/module/moduleActions";

const PracticalTaskItem = ({ state, module, moduleIndex }) => {
  const dispatch = useDispatch();
  const switchQuestions = useSelector((state) => state.course.switchQuestions);
  const tasksState = useSelector(
    (state) => state.practicalQuestion.allTasksDone
  );

  const startPracticalTask = () => {
    dispatch(setShowVideoPlayer(false));
    dispatch(setShowPracticalQustion(true));
    dispatch(setShowPassedPracticalQ(""));
    dispatch(setShowQuiz(false));
    dispatch(setShowPassed(false));
    dispatch(setShowFailed(false));
    dispatch(setShowCertificate(false));
    dispatch(setModule(module));
    dispatch(switchPracticalQuestions(!switchQuestions));
    localStorage.setItem("moduleDetails", JSON.stringify(module));
    localStorage.setItem("moduleIndex", moduleIndex);
  };

  return (
    <div
      className="accordion__child-container"
      style={{ cursor: "pointer" }}
      onClick={() => startPracticalTask()}
    >
      <span className="content__check-and-text">
        {state || tasksState ? (
          <CheckIcon style={{ marginRight: "1rem" }} />
        ) : (
          <GreyedCheckIcon style={{ marginRight: "1rem" }} />
        )}

        <span className="accordion__child-text">
          Practical Task
          <span className="duration-and-start--CTA">
            <button className="start__practical-questions--CTA">Start</button>
          </span>
        </span>
      </span>
    </div>
  );
};

export default PracticalTaskItem;
