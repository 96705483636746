import { useState, useEffect, useMemo } from "react";
import PracticalQuestionAccordion from "../PracticalQuestionAccordion/PracticalQuestionAccordion";
import { useModuleContext } from "../../contexts/ModuleContext";
import { api } from "../..";
import { v4 as uuidv4 } from "uuid";
import { FadeLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerLoading } from "../../redux/video/videoActions";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-iconTwo.svg";
import AlertComponent from "../AlertComponent/AlertComponent";
import {
  setShowPracticalQustion,
  setShowVideoPlayer,
  setShowQuiz,
  setShowPassedPracticalQ,
  setPassedPracticalQuestion,
} from "../../redux/course/courseActions";

import NotificationAlert from "../NotificationAlert/NotificationAlert";
import { setShowError } from "../../redux/Notification/notificationActions";
import "./practicalQuestion.css";
import Loader from "../Loader/Loader";
import {
  storePracticalQuestions,
  toggleAllTasksDone,
} from "../../redux/practicalTask/practicalTaskActions";

const PracticalQuestion = () => {
  const module = useSelector((state) => state.module.module);

  const course = useSelector((state) => state.course.course);

  const showError = useSelector((state) => state.notification.showError);

  const switchQuestions = useSelector((state) => state.course.switchQuestions);

  const practicalQuestions = useSelector(
    (state) => state.practicalQuestion.practicalQuestions
  );

  const allTasksDone = useSelector(
    (state) => state.practicalQuestion.allTasksDone
  );

  const [loading, setLoading] = useState(true);
  // const [questions, setQuestions] = useState([]);
  const [checkTasksCompletion, setCheckTasksCompletion] = useState(false);
  const [tasksDone, setTasksDone] = useState(module.practicalTaskUserState);
  const [quizAvailable, setQuizAvailable] = useState(false);
  const [checkTaskCompleted, setCheckTaskCompleted] = useState(false);

  const { moduleDetails, setModuleDetails } = useModuleContext();

  let moduleIndex = JSON.parse(localStorage.getItem("moduleIndex"));

  const currentModule = JSON.parse(localStorage.getItem("moduleDetails"));

  const dispatch = useDispatch();

  const id = currentModule ? currentModule.id : course?.modules[0].id;

  const getPracticalQuestions = () => {
    setLoading(true);
    dispatch(setPlayerLoading(true));
    api
      .get(`/api/v1/practicalQuestion/${id}`)
      .then((res) => {
        // setQuestions(res.data.questions);
        setTasksDone(res.data?.userPassed);
        dispatch(toggleAllTasksDone(res.data?.userPassed));
        dispatch(storePracticalQuestions(res.data.questions));
        dispatch(setPlayerLoading(false));
        // setLoading(false);
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.log(error);
        dispatch(setPlayerLoading(false));
        setLoading(false);
      });
  };
  // const checkAllTasksDone = () => {
  //   api
  //     .get(`/api/v1/practicalQuestion/passedAllModuleTasks/${id}`)
  //     .then((res) => {
  //       setTasksDone(res.data.pass);
  //       // dispatch(setPassedPracticalQuestion(res.data.pass));
  //     })
  //     .catch((err) => console.log(err));
  // };
  const checkQuizAvailability = () => {
    api
      .get(`/api/v1/quiz/quizavailablity/${course?.id}`)
      .then((res) => {
        setQuizAvailable(res.data.result);
      })
      .catch((err) => console.log(err));
  };

  const checkQuizElegibility = () => {
    api
      .get(`/api/v1/practicalQuestion/passedmodule/${id}`)
      .then((res) => console.log())
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (course) {
      getPracticalQuestions();
      checkQuizAvailability();
    }
  }, [allTasksDone, switchQuestions]);

  // useEffect(() => {
  //   checkAllTasksDone();
  // }, [allTasksDone]);

  const completeModule = () => {
    if (moduleIndex === course.modules.length - 1 && quizAvailable) {
      dispatch(setShowPracticalQustion(false));
      dispatch(setShowQuiz(true));
      dispatch(setShowVideoPlayer(false));
    } else {
      localStorage.setItem("moduleIndex", moduleIndex + 1);
      setModuleDetails(course.modules[moduleIndex + 1]);
      dispatch(setShowPassedPracticalQ(true));
      dispatch(setShowPracticalQustion(false));
    }
    // setTimeout(() => {
    checkQuizElegibility();
    // setCheckTaskCompleted(!checkTaskCompleted);
    dispatch(toggleAllTasksDone(!allTasksDone));

    // }, 1000);
  };

  const toggleShowQuestion = () => {
    dispatch(setShowPracticalQustion(false));
    dispatch(setShowVideoPlayer(true));
  };

  const closeNotification = () => {
    dispatch(setShowError(false));
  };

  return (
    <div className="notification__position-div">
      {loading ? <Loader loaderText="Loading Tasks..." content={true} /> : null}
      {showError && (
        <NotificationAlert
          text="Uh oh! Incorrect answer. Try again"
          closeNotification={closeNotification}
        />
      )}
      <div className="question__container">
        <span className="practical__question-heading-and-close--CTA">
          <p className="practical__question-heading">Practical Task</p>
          <CloseIcon
            onClick={toggleShowQuestion}
            style={{ cursor: "pointer" }}
            className="close__quiz-icon"
          />
        </span>
        <div className="practical__questions-container">
          {practicalQuestions &&
            practicalQuestions.map((question, index) => {
              return (
                <PracticalQuestionAccordion
                  key={question.id}
                  question={question}
                  state={question.userPassed}
                  index={index}
                  checkTasksCompletion={checkTasksCompletion}
                  setCheckTasksCompletion={setCheckTasksCompletion}
                  checkTaskCompleted={checkTaskCompleted}
                  setCheckTaskCompleted={setCheckTaskCompleted}
                  moduleId={id}
                  // setRerender={setRerender}
                  // reRender={reRender}
                />
              );
            })}
        </div>
        <span className="continue__CTA-span">
          <button
            className="continue__practQuestion-CTA"
            onClick={() => {
              completeModule();
            }}
            disabled={tasksDone ? false : true}
          >
            Continue
          </button>
        </span>
      </div>
    </div>
  );
};

export default PracticalQuestion;
