import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DetailsBar from "../../DetailsBar/DetailsBar";
import "./dashboard.css";

const Dashboard = ({
  adminSideideBarLinks,
  logOutPrompt,
  setLogOutPropmt,
  navOpened,
  setNavOpened,
}) => {
  const location = useLocation();

  return (
    <div className="dashboard__container">
      <DetailsBar
        pathname={location.pathname}
        adminSideideBarLinks={adminSideideBarLinks}
        logOutPrompt={logOutPrompt}
        setLogOutPropmt={setLogOutPropmt}
        navOpened={navOpened}
        setNavOpened={setNavOpened}
      />
      <Outlet />
    </div>
  );
};

export default Dashboard;
