import React, { useState, useContext, createContext } from "react";

const AuthUserDataContext = createContext();

export const useAuthUserDataContext = () => {
  return useContext(AuthUserDataContext);
};

export const AuthUserDataProvider = ({ children }) => {
  const [authUserData, setAuthUserData] = useState({});

  return (
    <AuthUserDataContext.Provider value={{ authUserData, setAuthUserData }}>
      {children}
    </AuthUserDataContext.Provider>
  );
};
