import { type } from "@testing-library/user-event/dist/type";
import { moduleActionTypes } from "./moduleActionTypes";

export const setModule = (module) => ({
  type: moduleActionTypes.SET_MODULE,
  payload: module,
});

export const toggleModuleAccordion = (status) => ({
  type: moduleActionTypes.TOGGLE_MODULE_ACCORDION,
  payload: status,
});
