import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import "./hubspot.css";

const HubspotContactForm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    const listener = () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          //   region: region,
          region: "eu1",
          portalId: "26029836",
          formId: "62b0dfec-dcaf-48f3-b6f6-0c24fb718a97",
          target: "#hubspotForm",
        });
      }
    };
    script.addEventListener("load", listener);

    return () => {
      script.removeEventListener("load", listener);
    };
  }, []);

  return (
    <div className="hb__form-container">
      <span className="prev__page--CTA">
        <button className="prev__CTA" onClick={() => navigate(-1)}>
          <LeftIcon style={{ fill: "#181a49" }} /> back to home
        </button>
      </span>
      <div id="hubspotForm" className="hb__form"></div>
    </div>
  );
};

export default HubspotContactForm;
