import Accordion from "../Accordion/Accordion";
import { ReactComponent as XIcon } from "../../assets/icons/x-icon.svg";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as CourseToggleIcon } from "../../assets/icons/course-toggle-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowSideCourseContent } from "../../redux/course/courseActions";

const AccordionContainer = ({}) => {
  // const [showCourseContent, setShowCourseContent] = useState(true);
  const dispatch = useDispatch();
  const showCourseContent = useSelector(
    (state) => state.course.showSideContent
  );

  const course = useSelector((state) => state.course.course);

  return (
    <>
      {showCourseContent ? (
        <div className="single__course-content--div ">
          <span className="course__content-heading-and-closeCTA">
            {" "}
            <p className="course__content-heading">Course Content</p>
            <XIcon
              onClick={() =>
                dispatch(setShowSideCourseContent(!showCourseContent))
              }
              style={{ cursor: "pointer" }}
            />
          </span>
          <div className="course__content-accordion--container">
            <div className="content__accordion-container">
              {course?.modules?.map((module, index) => (
                <Accordion key={uuidv4()} module={module} moduleIndex={index} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <span className="open__contents--CTA">
          <CourseToggleIcon
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch(setShowSideCourseContent(!showCourseContent))
            }
            className="hide__course-content--switch"
          />
        </span>
      )}

      {showCourseContent ? (
        <div className="course__content-accordion--container hide__mobile-view">
          <div className="content__accordion-container">
            {course?.modules.map((module, index) => (
              <Accordion key={uuidv4()} module={module} moduleIndex={index} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AccordionContainer;
