import { useState } from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import useAnalyticsEventTracker from "../../useAnalyticsEventsTracker";
import MobileNav from "../MobileNav/MobileNav";
import "./ethicalHackingPage.css";
import Hero from "./Hero/Hero";
import SectionOne from "./SectionOne/SectionOne";
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from "./SectionThree/SectionThree";
import FAQ from "./FAQ/FAQ";
import Pricing from "./Pricing/Pricing";

const EthicalHackingPage = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker("FAQ");

  return (
    <>
      <MetaDecorator
        title="Cykea - Ethical Hacking Academy"
        description="Learn Ethical Hacking Through Our Expert-Led Academy"
      />
      <NavBar
        whiteBg="yes"
        pricing="yes"
        openMobileNav={openMobileNav}
        setOpenMobileNav={setOpenMobileNav}
      />

      <div className="ethical__hacking-container">
        {openMobileNav ? (
          <MobileNav
            openMobileNav={openMobileNav}
            setOpenMobileNav={setOpenMobileNav}
          />
        ) : null}
      </div>
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Pricing />
      <FAQ />
      <Footer />
    </>
  );
};

export default EthicalHackingPage;
