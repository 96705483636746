import { useMemo, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import { ReactComponent as ProfileIconOne } from "../../assets/icons/cy-profile-icon.svg";
import { ReactComponent as ProfileIconTwo } from "../../assets/icons/cy-profile-icon-two.svg";
import { ReactComponent as SettingIconOne } from "../../assets/icons/cy-setting-icon-one.svg";
import { ReactComponent as SettingIconTwo } from "../../assets/icons/cy-setting-icon-two.svg";
import { ReactComponent as LearningIconOne } from "../../assets/icons/cy-learning-icon.svg";
import { ReactComponent as LearningIconTwo } from "../../assets/icons/courses-icon-two.svg";
import { api } from "../..";
import { useAuthUserDataContext } from "../../contexts/AuthUserDataContext";
import Dashboard from "./Dashboard/Dashboard";
import "./panel.css";

const Panel = () => {
  const [logOutPrompt, setLogOutPropmt] = useState(false);
  const [navOverlayOpened, setNavOverlayOpened] = useState(false);
  const [navOpened, setNavOpened] = useState(false);

  const sideBarLinks = [
    {
      linkId: Date.now(),
      urlName: "Learning",
      urlLink:
        "/panel/courses/single-course/affae8be-4208-43f0-b291-17875ef0bfd5",
      subUrl: "/single-course",
      urlIcon: <LearningIconOne />,
      urlIconTwo: <LearningIconTwo />,
    },
    {
      linkId: Date.now(),
      urlName: "Profile",
      urlLink: `/panel/profile`,
      urlIcon: <ProfileIconOne />,
      urlIconTwo: <ProfileIconTwo />,
    },
    // {
    //   linkId: Date.now(),
    //   urlName: "Settings",
    //   urlLink: `/panel/settings`,
    //   urlIcon: <SettingIconOne />,
    //   urlIconTwo: <SettingIconTwo />,
    // },
  ];

  const token = localStorage.getItem("token");

  const { authUserData, setAuthUserData } = useAuthUserDataContext();

  useEffect(() => {
    api
      .get("/api/v1/user/loggedInUser")
      .then((res) => {
        setAuthUserData(res.data);
        localStorage.setItem("userEmail", res.data.user.email);
        localStorage.setItem("firstName", res.data.user.firstName);
      })
      .catch((error) => console.log(error));
  }, [token, setAuthUserData]);

  return (
    <div className="panel__container">
      {navOpened ? <div className="mobile__nav-overlay" /> : null}
      <SideBar
        sideBarLinks={sideBarLinks}
        setNavOverlayOpened={setNavOverlayOpened}
        navOpened={navOpened}
        setNavOpened={setNavOpened}
        logOutPrompt={logOutPrompt}
        setLogOutPropmt={setLogOutPropmt}
      />
      <Dashboard
        sideBarLinks={sideBarLinks}
        navOpened={navOpened}
        setNavOpened={setNavOpened}
        logOutPrompt={logOutPrompt}
        setLogOutPropmt={setLogOutPropmt}
      />
    </div>
  );
};

export default Panel;
