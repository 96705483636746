import { useState, useMemo, useEffect } from "react";
import { ReactComponent as UpArrowIcon } from "../../assets/icons/opened-arrow-two.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/down-arrow-two.svg";
import SinglePracticalQuestion from "../SinglePracticalQuestion/SinglePracticalQuestion";
import { api } from "../..";
import { v4 as uuidv4 } from "uuid";
import "./practicalQuestionAccordion.css";
import { useSelector } from "react-redux";

const PracticalQuestionAccordion = ({
  question,
  index,
  // checkTasksCompletion,
  // setCheckTasksCompletion,
  // checkTaskCompleted,
  // setCheckTaskCompleted,
  state,
}) => {
  const moduleId = useSelector((state) => state.module.module.id);

  const [openAccordion, setOpenAccordion] = useState(false);
  // const [passed, setPassed] = useState(state);
  const [taskCompleted, setTaskCompleted] = useState(false);

  // useEffect(() => {
  //   api
  //     .post(`/api/v1/practicalQuestion/passedtask`, {
  //       moduleId,
  //       taskId: question.id,
  //     })
  //     .then((res) => {
  //       setPassed(res.data.passed);
  //     })
  //     .catch((error) => console.log(error));
  // }, [taskCompleted]);

  return (
    <>
      <div
        className={
          state
            ? "accordion__parent-div practical__question-box--done"
            : "accordion__parent-div practical__question-box"
        }
        style={{ height: "4.2rem", marginBottom: "2rem" }}
        onClick={() => setOpenAccordion(!openAccordion)}
      >
        <span className="content__title-and-arrowCTA">
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "fit-content",
            }}
          >
            <p
              className="content__title-two"
              style={{
                marginRight: "2.5rem",
              }}
            >
              {question.title}
            </p>
            {/* {state ? <p className="content__completed-tag">Completed</p> : null} */}
          </span>
          {openAccordion ? (
            <DownArrowIcon className="prac__question-arrow" />
          ) : (
            <UpArrowIcon className="prac__question-arrow" />
          )}
        </span>
      </div>

      {openAccordion ? (
        <div className="pracQuestion-accordion__children-container">
          <p className="task__description-text">{question.description}</p>
          <p className="question__instruction">Answer the questions below</p>
          <div className="practical__questions-box">
            {question &&
              question.practicalQuestions.map((practicalQuestion) => {
                return (
                  <SinglePracticalQuestion
                    key={practicalQuestion.id}
                    practicalQuestion={practicalQuestion}
                    // checkTasksCompletion={checkTasksCompletion}
                    // setCheckTasksCompletion={setCheckTasksCompletion}
                    // checkTaskCompleted={checkTaskCompleted}
                    // setCheckTaskCompleted={setCheckTaskCompleted}
                    state={practicalQuestion.userPassed}
                    taskCompleted={taskCompleted}
                    setTaskCompleted={setTaskCompleted}
                  />
                );
              })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PracticalQuestionAccordion;
