import { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as AnswerCheck } from "../../assets/icons/correct-answer-check.svg";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowSuccess,
  setShowError,
} from "../../redux/Notification/notificationActions";
import { api } from "../..";
import { toggleAllTasksDone } from "../../redux/practicalTask/practicalTaskActions";

const SinglePracticalQuestion = ({
  practicalQuestion,
  // checkTasksCompletion,
  // setCheckTasksCompletion,
  // checkTaskCompleted,
  // setCheckTaskCompleted,
  state,
  taskCompleted,
  setTaskCompleted,
}) => {
  const [answer, setAnswer] = useState("");
  const [suceess, setSuccess] = useState(false);
  const [complete, setComplete] = useState(false);
  const [passed, setPassed] = useState(false);
  const [markingQuestion, setMarkingQuestion] = useState(false);

  const dispatch = useDispatch();

  const module = useSelector((state) => state.module.module);

  const allTasksDone = useSelector(
    (state) => state.practicalQuestion.allTasksDone
  );

  // const customId = "custom-id-yes";

  useEffect(() => {
    api
      .get(`/api/v1/practicalQuestion/passedquestion/${practicalQuestion.id}`)
      .then((res) => {
        setPassed(res.data.passed);
      })
      .catch((error) => console.log(error));
  }, [markingQuestion]);

  const toggleSuccessNotification = () => {
    dispatch(setShowSuccess(true));
    setTimeout(() => {
      dispatch(setShowSuccess(false));
    }, 2000);
  };
  const toggleErrorNotification = () => {
    dispatch(setShowError(true));
    setTimeout(() => {
      dispatch(setShowError(false));
    }, 2500);
  };

  const checkQuizElegibility = () => {
    api
      .post("/api/v1/course//checkQuizEligibility", {
        moduleId: module.id,
      })
      .then((res) => console.log())
      .catch((error) => console.log(error));
  };

  function removeLeadingAndTrailingSpaces(inputString) {
    return inputString.replace(/^\s+|\s+$/g, "");
  }

  const markQuestion = () => {
    const formatedAnswer = removeLeadingAndTrailingSpaces(answer);

    setMarkingQuestion(!markingQuestion);
    api
      .post("api/v1/practicalQuestion/markQuestions", {
        questionId: practicalQuestion.id,
        answer: formatedAnswer,
      })
      .then((res) => {
        res.data.message === "wrong answer"
          ? toggleErrorNotification()
          : console.log();
        // res.data.message !== "wrong answer"
        //   ? toggleSuccessNotification()
        //   : toggleErrorNotification();
      })
      .then(() => {
        // setCheckTasksCompletion(!checkTasksCompletion);
        // setCheckTaskCompleted(!checkTaskCompleted);
        dispatch(toggleAllTasksDone(!allTasksDone));
        // setTaskCompleted(!taskCompleted);
      })

      .catch((err) => console.log(err));
  };

  return (
    <div className="practical__question-div">
      <p className="practical__question">{practicalQuestion.question}</p>
      <span className="answer__input-and-complete--CTA">
        <input
          type="text"
          className="prac__question-answer--input"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder={
            passed && practicalQuestion.answerStructure
              ? practicalQuestion.answerStructure
              : !passed && !practicalQuestion.answerStructure
              ? "No answer needed"
              : passed && !practicalQuestion.answerStructure
              ? "No answer needed"
              : practicalQuestion.answerStructure
          }
          // style={{
          //   pointerEvents: practicalQuestion.answerStructure ? "auto" : "none",
          // }}
          disabled={practicalQuestion.answerStructure ? false : true}
        />
        {practicalQuestion.answerStructure ? (
          !state ? (
            <button
              className="pracQuestion__complete-CTA"
              onClick={() => markQuestion()}
            >
              Submit
            </button>
          ) : (
            <div className="correct__answer-and-icon">
              <p className="correct__answer-text">Correct answer</p>{" "}
              <AnswerCheck />
            </div>
          )
        ) : state ? (
          <span className="correct__answer-and-icon">
            <p className="correct__answer-text">Question done</p>{" "}
            <AnswerCheck />
          </span>
        ) : (
          <button
            className="pracQuestion__complete-CTA"
            onClick={() => {
              markQuestion();
            }}
          >
            Complete
          </button>
        )}
      </span>
    </div>
  );
};

export default SinglePracticalQuestion;
