import heroImage from "../../../assets/images/hero-img.png";
import { useNavigate } from "react-router-dom";
import lightIcon from "../../../assets/icons/ethical-hacking-notice-light.png";
import "./hero.css";

const Hero = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="hero__section-container">
      <div className="hero__content-box">
        <div className="hero__text-box">
          <h1 className="hero__main-text">
            Learn Ethical Hacking Through Our Expert-Led Course
          </h1>
          <p className="hero__sub-text">
            Are you ready to take your cybersecurity career to the next level?
            Would you like to gain hands-on experience in Ethical Hacking? Cykea
            is here to help you meet your goals. Cohort starts{" "}
            <b>21st of September</b>
          </p>
          <div className="hero__CTAs">
            <button
              className="hero__enroll-CTA"
              onClick={() => scrollToSection("sectionOne")}
            >
              Learn More
            </button>
            <span className="hero__start-date--span--desktop">
              {" "}
              <img
                src={lightIcon}
                alt="siren-icon"
                className="ethical__siren"
              />
              <p className="hero__start-date">
                Enrolment Deadline: 15th September, 2024
              </p>
            </span>
          </div>
        </div>
        <div className="hero__image-box">
          <img src={heroImage} alt="hero-img" className="hero__img" />
        </div>
        <span className="hero__start-date--span">
          <img src={lightIcon} alt="siren-icon" className="ethical__siren" />
          <p className="hero__start-date">
            Enrolment Deadline: 15th September, 2024
          </p>
        </span>
      </div>
    </div>
  );
};

export default Hero;
