// import { ReactComponent as CloseIcon } from "../../assets/icons/close-circleTwo.svg";
// import { useCertificateContext } from "../../contexts/CertificateContext";
// import { useModuleContext } from "../../contexts/ModuleContext";
import { useEffect } from "react";
import { useModuleIndexContext } from "../../contexts/ModuleContext";
import { useModuleContext } from "../../contexts/ModuleContext";
import {
  setShowPassed,
  setShowQuiz,
  setShowCertificate,
} from "../../redux/course/courseActions";
import { useDispatch } from "react-redux";
import "./passedQuiz.css";

const PassedQuiz = ({
  // setShowPassed,
  // setShowPracticalQustion,
  // setShowCertificate,
  score,
}) => {
  // const { moduleIndex, setModuleIndex } = useModuleIndexContext();
  const { moduleDetails, setModuleDetails } = useModuleContext();

  const dispatch = useDispatch();

  const displayCertificate = () => {
    dispatch(setShowPassed(false));
    dispatch(setShowCertificate(true));
  };
  //   const continueToPractQ = () => {
  //     setShowPassed(false);
  //     setShowPracticalQustion(true);
  //   };

  const retryQuiz = () => {
    dispatch(setShowPassed(false));
    dispatch(setShowQuiz(true));
  };

  return (
    <div className="videoPlayer__container--two">
      <div
        className="passed__quiz-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          position: "inherit",
          textAlign: "center",
        }}
      >
        <div className="passed__component-content">
          <p className="passed__quiz-text">
            Congrats! You passed!
            <br />
            You scored
          </p>
          <p className="quiz__score">{score}%</p>
          <span className="passed__container-CTA--box">
            <button className="retry__quiz-CTA" onClick={() => retryQuiz()}>
              Retry
            </button>
            <button
              className="continue__from-quiz--CTA"
              onClick={() => displayCertificate()}
            >
              Continue
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PassedQuiz;
