import { useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { ReactComponent as MailLogo } from "../../assets/icons/mail-icon.svg";
import { useForm } from "react-hook-form";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
// import { ReactComponent as NewCykeaLogoBlack } from "../../assets/icons/cykea-new-logo.svg";
import { ReactComponent as NewCykeaLogoBlack } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { ReactComponent as NewCykeaBetaLogoBlack } from "../../assets/icons/cykea-beta-logo.svg";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import NavBar from "../NavBar/NavBar";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import loginImage from "../../assets/images/new-login-img.png";
import useAnalyticsEventTracker from "../../useAnalyticsEventsTracker";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import { usePaystackPayment } from "react-paystack";
import { ReactComponent as XIcon } from "../../assets/icons/mobile-menu-x.svg";
// import mixpanel from "mixpanel-browser";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { api } from "../..";
import "./login.css";
import Loader from "../Loader/Loader";

// mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
//   debug: true,
// });

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [password, setPassword] = useState("");
  const [pwdFocus, setPwdFocus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const courseId = localStorage.getItem("courseId");
  const coursePrice = localStorage.getItem("coursePrice");

  const publicKey = process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY;

  const componentProps = {
    email,
    publicKey,
    amount: coursePrice * 100,
    metadata: {
      customerId: courseId,
      first_name: email,
      user_id: email,
    },
  };

  const gaEventTracker = useAnalyticsEventTracker("FAQ");

  const from =
    location.state?.from?.pathname ||
    `/panel/courses/single-course/${courseId}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const makePayment = usePaystackPayment(componentProps);

  const onSuccess = () => {
    alert("Payment Successfull!");
    navigate("/panel/courses");
  };

  const onClose = () => {
    alert("Are you sure you want to leave your cyberspace vulnerable?");
  };

  const initiatePayment = (courseId, id, firstName) => {
    api
      .post("/api/v1/payment/payforcourse", { courseId })
      .then((res) => {
        localStorage.removeItem("courseId");
        componentProps.metadata.first_name = firstName;
        componentProps.metadata.user_id = id;

        makePayment(onSuccess, onClose);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 400) {
          localStorage.clear();
        }
        setErrorMessage(err.response.data.message);
      });
  };

  ////////////////// STRIPE IMPLEMENTATION

  // const initiatePayment = (courseId, token) => {
  //   api
  //     .post(
  //       "/api/v1/payment/payforcourse",
  //       { courseId },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     )
  //     .then((res) => {
  //       console.log({ res });
  //       // window.open(`${res.data.session}`);
  //       localStorage.removeItem("courseId");
  //       window.location.href = `${res.data.session}`;
  //     })
  //     .catch((err) => console.log(err));

  //   console.log({ courseId });
  //   console.log({ token });
  // };

  const getLoggedInUser = () => {
    api
      .get("/api/v1/user/loggedInUser", {})
      .then((res) => {
        setTimeout(() => {
          initiatePayment(
            localStorage.getItem("courseId"),
            res.data.user.id,
            res.data.user.email,
            res.data.user.firstName
          );
        }, 1000);
      })
      .catch((error) => console.log(error));
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    // setEmail(data.email);

    api
      .post("/api/v1/user/login", {
        email,
        password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("userIsLoggedIn", true);
        setSuccess(true);
        navigate(from);
        setLoading(true);
        // setTimeout(() => {
        //   if (courseId === null || courseId === undefined) {
        //     setLoading(false);
        //   } else {
        //     setLoading(false);
        //     // getLoggedInUser(res.data?.token);
        //   }
        // }, 2000);
      })
      .catch((error) => {
        if (!error?.response) {
          setErrorMessage(
            "Unable to connect. Please Check Your Internet Connection."
          );
        } else if (error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage(error.response.data.message);
        }
      });
    setLoading(false);
    gaEventTracker("Login");
  };

  return (
    <>
      <MetaDecorator title="Cykea - Login" description="Welcome to Cykea" />

      {/* <NavBar
        openMobileNav={openMobileNav}
        setOpenMobileNav={setOpenMobileNav}
      /> */}
      {loading ? <Loader loaderText="Loading..." /> : null}
      {showForgotPassword ? (
        <ForgotPassword
          showForgotPassword={showForgotPassword}
          setShowForgotPassword={setShowForgotPassword}
        />
      ) : null}
      <div className="login__container">
        {openMobileNav ? (
          <div className="mobile__nav-content--container">
            <span className="close__icon-span">
              <XIcon
                style={{ cursor: "pointer", marginBottom: "4rem" }}
                onClick={() => setOpenMobileNav(!openMobileNav)}
              />
            </span>
            <span className="mobile__nav-link--container">
              <Link to="/faq">
                <p className="mobile__nav-link--text">FAQs</p>
              </Link>
              <Link to="/login">
                <p className="mobile__nav-link--text">Log In</p>
              </Link>
              <Link to="/pricing">
                <p className="mobile__nav-link--text">Get Started</p>
              </Link>
            </span>
          </div>
        ) : null}
        <div className="login__form--container">
          {window.location.href.startsWith("http://beta.cykea.com/") ? (
            <Link to="/" aria-label="Cykea's Logo">
              <NewCykeaBetaLogoBlack />
            </Link>
          ) : (
            <Link to="/" aria-label="Cykea's Logo">
              <NewCykeaLogoBlack className="login__cykea--logo" />
            </Link>
          )}
          <div className="form-and-text__container">
            <span className="login__form--texts">
              <h2 className="login__formheading">Log in</h2>

              {errorMessage ? (
                <p
                  style={{
                    color: "#F95959",
                    marginBottom: "1rem",
                  }}
                  className="error__message"
                >
                  {errorMessage}
                </p>
              ) : (
                ""
              )}
              {/* <p className="login__form-sub--text">
                Enter your Email and Password
              </p> */}
            </span>
            <form
              action=""
              className="login__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <label htmlFor="Email" className="login__email--label">
                Email Address
              </label>
              <div className="login__email-and-icon">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                  // {...register("email", { required: true })}
                />
                {errors.email && (
                  <p
                    style={{
                      color: "#CC2442",
                      marginTop: "1rem",
                    }}
                  >
                    Email is required
                  </p>
                )}
                {/* <MailLogo className="mail__icon" /> */}
              </div>
              <label htmlFor="password" className="login__password--label">
                Password
              </label>
              <div className="login__password-and-icons">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  className={
                    errors.password
                      ? "password--input"
                      : "login__password--input"
                  }
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  // {...register("password", { required: true })}
                />
                {errors.password && (
                  <p
                    style={{
                      color: "#CC2442",
                      marginTop: "1rem",
                    }}
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                  >
                    Password is required
                  </p>
                )}
                {!showPassword ? (
                  <EyeLogo
                    className="login__eye-icon"
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <EyeOffLogo
                    className="login__eye-icon"
                    onClick={() => setShowPassword(false)}
                  />
                )}
                {/* <LockLogo className="lock__icon" /> */}
              </div>

              <p
                className="forgot__passwordone"
                onClick={() => setShowForgotPassword(!showForgotPassword)}
              >
                Forgot Your Password?
              </p>

              <button className="login__submit--CTA">Log In</button>
            </form>
            <p className="login__dont-have--account">
              Don't have an account?{" "}
              <span
                style={{
                  marginLeft: ".3rem",
                  fontWeight: "600",
                }}
              >
                <a href="/register" className="sign__up-link">
                  Sign Up
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="login__image--container">
          {/* <div className="login__image--box"></div> */}
          <img
            src={loginImage}
            alt="loginimage"
            className="login__image--box"
          />
          {/* <h1 className="login__main--text">
            Ignite Your Cyber Security
            <br />
            Career!
          </h1>
          <p className="login__sub--text">
            Login to start protecting your organization
            <br /> from cyberattacks.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Login;
