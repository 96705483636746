import { courseActionTypes } from "./courseActionTypes";

const INITIAL_STATE = {
  course: null,
  courseId: null,
  certificate: null,
  showCourseOverview: false,
  showCourseContent: true,
  showSideContent: true,
  showCourseTranscript: false,
  showPassed: false,
  showVideoPlayer: true,
  showPassedPracticalQuestion: false,
  showPracticalQuestion: false,
  showQuiz: false,
  showFailed: false,
  showOverlay: false,
  showFreeBanner: false,
  showCertificate: false,
  passedPracticalQuestion: false,
  switchQuestions: false,
};

const courseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case courseActionTypes.STORE_COURSE:
      return {
        ...state,
        course: action.payload,
      };

    case courseActionTypes.STORE__COURSE__ID:
      return {
        ...state,
        courseId: action.payload,
      };

    case courseActionTypes.STORE_CERTIFICATE:
      return {
        ...state,
        certificate: action.payload,
      };

    case courseActionTypes.SHOW_COURSE_OVERVIEW:
      return {
        ...state,
        showCourseOverview: action.payload,
      };

    case courseActionTypes.SHOW_COURSE_CONTENT:
      return {
        ...state,
        showCourseContent: action.payload,
      };

    case courseActionTypes.SHOW_SIDE_COURSE_CONTENT:
      return {
        ...state,
        showSideContent: action.payload,
      };

    case courseActionTypes.SHOW_COURSE_TRANSCRIPT:
      return {
        ...state,
        showCourseTranscript: action.payload,
      };
    case courseActionTypes.SHOW_PASSED:
      return {
        ...state,
        showPassed: action.payload,
      };
    case courseActionTypes.SHOW_FAILED:
      return {
        ...state,
        showFailed: action.payload,
      };
    case courseActionTypes.SHOW_PASSED_PRACTICAL_QUESTION:
      return {
        ...state,
        showPassedPracticalQuestion: action.payload,
      };
    case courseActionTypes.SHOW_PRACTICAL_QUESTION:
      return {
        ...state,
        showPracticalQuestion: action.payload,
      };
    case courseActionTypes.SHOW_VIDEO_PLAYER:
      return {
        ...state,
        showVideoPlayer: action.payload,
      };
    case courseActionTypes.SHOW_QUIZ:
      return {
        ...state,
        showQuiz: action.payload,
      };
    case courseActionTypes.SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: action.payload,
      };

    case courseActionTypes.SHOW_CERTIFICATE:
      return {
        ...state,
        showCertificate: action.payload,
      };

    case courseActionTypes.PASSED_PRACTICAL_QUESTION:
      return {
        ...state,
        passedPracticalQuestion: action.payload,
      };

    case courseActionTypes.SHOW_FREE_BANNER:
      return {
        ...state,
        showFreeBanner: action.payload,
      };
    case courseActionTypes.SWITCH_PRACTICAL_QUESTION:
      return {
        ...state,
        switchQuestions: action.payload,
      };

    case courseActionTypes.UPDATE_VIDEO_USER_STATE:
      return {
        ...state,
        course: {
          ...state.course,
          modules: state.course.modules.map((module) => {
            if (module.id === action.payload.moduleId) {
              return {
                ...module,
                videos: module.videos.map((video) => {
                  if (video.id === action.payload.videoId) {
                    return {
                      ...video,
                      userState: action.payload.newUserState,
                    };
                  }
                  return video;
                }),
              };
            }
            return module;
          }),
        },
      };

    default:
      return state;
  }
};

export default courseReducer;
