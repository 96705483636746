import { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { ReactComponent as SlackLogo } from "../../assets/icons/slack-logo.svg";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-logo.svg";
import axios from "axios";
import "./joinSlack.css";

const JoinSlack = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");
  const navigate = useNavigate();

  const verifyToken = () => {
    setLoading(true);
    axios
      .post("/api/v1/user/verifytoken", { token: token })
      .then((res) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <>
      <span className="cykea__logo-span--slack-com">
        <CykeaLogo style={{ margin: "3rem" }} />
      </span>
      <div className="join__slack-community--container">
        {loading ? (
          <div className="loader__container" style={{ position: "fixed" }}>
            <FadeLoader color="purple" loading={loading} size={15} />
          </div>
        ) : null}
        {success ? (
          <span className="verified__token-span">
            <SlackLogo />
            <p className="Join__slack-community--text">
              Click the button below to join our Discord community!
            </p>
            <button
              className="join__slack-community--CTA"
              onClick={() => navigate("https://discord.gg/TtZWjKFp9y")}
            >
              Join
            </button>
          </span>
        ) : (
          <span className="verified__token-span">
            <SlackLogo />
            <p className="Join__slack-community--text">
              We couldn't verify your access
            </p>
            <button
              className="join__slack-community--CTA"
              onClick={() => navigate("/")}
            >
              Back to homepage
            </button>
          </span>
        )}
      </div>
    </>
  );
};

export default JoinSlack;
