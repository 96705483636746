import { useEffect, useState } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/x-iconTwo.svg";
import { ReactComponent as XIconTwo } from "../../assets/icons/x-icon.svg";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { ReactComponent as NewCykeaBetaLogoBlack } from "../../assets/icons/cykea-beta-logo.svg";
import { ReactComponent as OurMissionIcon } from "../../assets/icons/our-mission-icon.svg";
import { ReactComponent as OurMissionActiveIcon } from "../../assets/icons/our-mission-active.svg";
import { ReactComponent as NewCykeaBetaLogoSmall } from "../../assets/icons/cykea-beta-logo.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/cy-peace-icon.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/cy-power-icon.svg";
import {
  setShowPracticalQustion,
  setShowQuiz,
  setShowVideoPlayer,
} from "../../redux/course/courseActions";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./sideBar.css";
import JoinCommunity from "../JoinCommunity/JoinCommunity";

const SideBar = ({
  sideBarLinks,
  logOutPrompt,
  setLogOutPropmt,
  navOpened,
  setNavOpened,
}) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const pathName = location.pathname;

  let id = 30;

  const clicker = () => {
    setNavOpened(!navOpened);
  };

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userIsLoggedIn");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("firstName");
    localStorage.removeItem("moduleDetails");

    navigate("/");
    dispatch(setShowPracticalQustion(false));
    dispatch(setShowQuiz(false));
    dispatch(setShowVideoPlayer(true));
  };

  const activateOurMission = () => {
    navigate("/panel/our-mission");
  };

  return (
    <div className="sidebar__container">
      <span className="dashboard__fezzant--logo-span hidden">
        {window.location.href.startsWith("http://beta.cykea.com/") ? (
          <NewCykeaBetaLogoBlack />
        ) : (
          <CykeaLogo
            className="dashboard__sideBar-logo"
            onClick={() => navigate("/")}
          />
        )}
      </span>
      <span
        className="auth__company--logo hidden"
        style={{
          border:
            pathName === "/panel/our-mission"
              ? "1px solid rgba(154, 204, 251, 0.72)"
              : "",
        }}
        onClick={() => activateOurMission()}
      >
        Our Mission <OurMissionIcon className="our__mission-icon" />
      </span>
      <div className="sidebar__content--container hidden">
        <div className="sidebar__inner--content">
          <div className="side__nav--links">
            {sideBarLinks?.map((link) => {
              return (
                <NavLink
                  to={`${link.urlLink}${link.subLink ? link.subLink : ""}`}
                  className={({ isActive }) =>
                    isActive ? "active" : "link__and__icon"
                  }
                  key={id++}
                  onClick={() => clicker()}
                >
                  <span className="colored">
                    {link.urlIcon} <p className="link">{link.urlName}</p>
                  </span>

                  <span className="notColored">
                    {link.urlIconTwo} <p className="link">{link.urlName}</p>{" "}
                  </span>
                </NavLink>
              );
            })}
          </div>
        </div>

        <JoinCommunity marginBottom="1.3rem" />

        <span
          className=" logout__CTA"
          onClick={() => {
            setLogOutPropmt(!logOutPrompt);
          }}
        >
          <PowerIcon className="power__icon" />
          <p className="sidebar__logout-text">Log out</p>
          {logOutPrompt ? (
            <div className="logout__prompt--container">
              <p className="logout__prompt--text">Are you sure?</p>
              <p className="logout__prompt-sub--text">
                We'll miss you.
                <br />
                Make sure to check back soon.
              </p>
              <LogoutIcon className="logout__peace-icon" />
              <span className="logout__prompts--CTAs">
                <button className="yes__logout" onClick={logOut}>
                  Yes
                </button>
                <button
                  className="cancel__logout"
                  onClick={() => setLogOutPropmt(!logOutPrompt)}
                >
                  Cancel
                </button>
              </span>
            </div>
          ) : (
            ""
          )}
        </span>
      </div>
      <div className={navOpened ? "mobile__nav" : "mobile__nav-closed"}>
        <span className="mobile__nav-logo-and-close--span">
          {window.location.href.startsWith("http://beta.cykea.com/") ? (
            <NewCykeaBetaLogoSmall />
          ) : (
            <CykeaLogo
              className="mobile__sideBar-logo"
              onClick={() => navigate("/")}
            />
          )}
          <XIconTwo
            style={{ cursor: "pointer" }}
            onClick={() => setNavOpened(false)}
          />
        </span>
        <span className="nav-links">
          <ul className="links">
            {sideBarLinks.map((link) => {
              return (
                <NavLink
                  to={`${link.urlLink}`}
                  className={({ isActive }) =>
                    isActive ? "active" : "link__and__icon"
                  }
                  key={id++}
                >
                  <span className="colored">
                    <span>{link.urlIcon}</span>{" "}
                    <li className="link">{link.urlName}</li>
                  </span>

                  <span className="notColored">
                    {link.urlIconTwo} <li className="link">{link.urlName}</li>{" "}
                  </span>
                </NavLink>
              );
            })}
          </ul>
          <JoinCommunity marginBottom="0rem" />

          <span
            className=" logout__CTA"
            onClick={() => {
              setLogOutPropmt(!logOutPrompt);
            }}
          >
            <PowerIcon className="power__icon" />
            <p className="sidebar__logout-text">Log out</p>
            {logOutPrompt ? (
              <div className="logout__prompt--container">
                <p className="logout__prompt--text">Are you sure?</p>
                <p className="logout__prompt-sub--text">
                  We'll miss you.
                  <br />
                  Make sure to check back soon.
                </p>
                <LogoutIcon className="logout__peace-icon" />
                <span className="logout__prompts--CTAs">
                  <button className="yes__logout" onClick={logOut}>
                    Yes
                  </button>
                  <button
                    className="cancel__logout"
                    onClick={() => setLogOutPropmt(!logOutPrompt)}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            ) : (
              ""
            )}
          </span>
        </span>
      </div>
    </div>
  );
};

export default SideBar;
