import { useState, useEffect } from "react";
import Header from "../Header/Header";
import NavBar from "../NavBar/NavBar";
import SectionOne from "../SectionOne/SectionOne";
import { Link } from "react-router-dom";
import { ReactComponent as XIcon } from "../../assets/icons/mobile-menu-x.svg";
import SectionTwo from "../SectionTwo/SectionTwo";
import Sponsors from "../Sponsors/Sponsors";
import Footer from "../Footer/Footer";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import { useDispatch } from "react-redux";
import { setShowVideoPlayer } from "../../redux/course/courseActions";
import axios from "axios";
import "./landingPage.css";
import SectionThree from "../SectionThree/SectionThree";
import SectionFour from "../SectionFour/SectionFour";
import MobileNav from "../MobileNav/MobileNav";
import FloatingAccess from "../FloatingAccess/FloatingAccess";

const LandingPage = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // localStorage.removeItem("coursePrice");
    // localStorage.removeItem("courseId");
    dispatch(setShowVideoPlayer(true));
  }, []);

  return (
    <>
      <MetaDecorator
        title="CyKea - Free Cybersecurity Practical Training"
        description="Cykea's landing page"
      />
      <NavBar
        openMobileNav={openMobileNav}
        setOpenMobileNav={setOpenMobileNav}
        color="#fff"
      />
      <div className="landing__page-container">
        {openMobileNav ? (
          <MobileNav
            openMobileNav={openMobileNav}
            setOpenMobileNav={setOpenMobileNav}
          />
        ) : null}
        <FloatingAccess />
        <Header />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        {/* <Sponsors /> */}
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
