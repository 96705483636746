import { notificationActionTypes } from "./notificationActionTypes";

export const toggleNotfication = (status) => ({
  type: notificationActionTypes.TOGGLE_ALERT,
  payload: status,
});

export const setShowSuccess = (status) => ({
  type: notificationActionTypes.SHOW_SUCCESS,
  payload: status,
});

export const setShowError = (status) => ({
  type: notificationActionTypes.SHOW_ERROR,
  payload: status,
});
