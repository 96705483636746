import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as XIcon } from "../../assets/icons/mobile-menu-x.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/cy-contact-menu-down-arrow.svg";
import { ReactComponent as UpArrow } from "../../assets/icons/cy-contact-menu-up-arrow.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/footer-twitter-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram-footer-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin-footer-icon.svg";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import "./mobileNav.css";

const MobileNav = ({ openMobileNav, setOpenMobileNav }) => {
  const [openContactMenu, setOpenContactMenu] = useState(false);
  return (
    <div className="mobile__nav-content--container">
      <div className="close__icon-span">
        <CykeaLogo className="cykea__landing-logo" />
        <XIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenMobileNav(!openMobileNav)}
        />
      </div>
      <span className="mobile__nav-link--container">
        <Link to="/skills-path">
          <p className="mobile__nav-link--text">Skills Path</p>
        </Link>
        <Link to="/resources">
          <p className="mobile__nav-link--text">Resources</p>
        </Link>
        <Link to="/faq">
          <p className="mobile__nav-link--text">FAQs</p>
        </Link>
        <Link to="/login">
          <p className="mobile__nav-link--text">Login</p>
        </Link>
        <span
          className="contact__us-span"
          onClick={() => setOpenContactMenu(!openContactMenu)}
        >
          <p className="mobile__nav-link--text last__link">Contact Us</p>
          {openContactMenu ? <UpArrow /> : <DownArrow />}
        </span>
        {openContactMenu ? (
          <span className="contact__channels-span">
            <p className="contact__us-link">hello@cykea.com</p>
            <p className="contact__us-link">+44 774 668 9130</p>

            <span className="footer__social-icons">
              <a
                href="https://twitter.com/cykea_academy?s=21&t=zcjpC55PxvXqgafRZVMN1A"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit cykea's twitter page"
              >
                <TwitterIcon className="contact__social-icon" />
              </a>
              <a
                href="https://instagram.com/cykea_academy?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
                aria-label="Visit cykea's instagram page"
              >
                <InstagramIcon className="contact__social-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/cykea"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit cykea's linkedin page"
              >
                <LinkedinIcon className="contact__social-icon" />
              </a>
            </span>
          </span>
        ) : null}
      </span>
    </div>
  );
};

export default MobileNav;
