import { useState } from "react";
import { ReactComponent as XIcon } from "../../assets/icons/join-com-x.svg";
import joinCommBg from "../../assets/images/join-comm-bg.svg";
import "./joinCommunity.css";

const JoinCommunity = ({ marginBottom }) => {
  const [joinComm, setJoinComm] = useState(true);

  return (
    <>
      {joinComm ? (
        <>
          <span className="close__join-com--dialog">
            <p
              className="close__com-dialog--text"
              onClick={() => setJoinComm(false)}
            >
              Close
            </p>
          </span>
          <div
            className="join__community-container"
            style={{ marginBottom: `${marginBottom}` }}
          >
            <span className="join__community-and-close">
              <p className="join__community-text">Learners Community</p>
              {/* <XIcon
              onClick={() => setJoinComm(false)}
              style={{ cursor: "pointer" }}
            /> */}
            </span>
            <p className="join__community-text--two">
              Join our learners community for more tricks and tips.
            </p>
            <div
              className="join__community-bg"
              style={{
                backgroundImage: `url(${joinCommBg})`,
              }}
            >
              <a
                href="https://discord.gg/TtZWjKFp9y"
                target="#_blank"
                className="join__comm-link"
              >
                <button className="join__community-CTA">Join Community</button>
              </a>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default JoinCommunity;
