import "./sectionThree.css";
import stickyNote from "../../assets/images/cy-sticky-note.svg";
import steps from "../../assets/images/cy-steps.svg";

const SectionThree = () => {
  return (
    <div className="section__three-container">
      <div className="section__three-box--one">
        <div className="section__three-text--box">
          <p className="tag__text">SKILLS</p>
          <h2 className="section__three-main--text">Get Hands-on</h2>
          <p className="section__three-sub--text">
            In all skill paths, you will not only learn from an experienced
            professional, but you will carry out tasks based on industry
            experience.
          </p>
        </div>
        <img
          src={stickyNote}
          alt="stickyNote_Illustration"
          className="section__three-img sectionThree__img-one"
        />
      </div>
      <div className="section__three-box--two">
        <div className="section__three-text--box">
          <p className="tag__text">GROWTH</p>
          <h2 className="section__three-main--text">Noob to Ninja</h2>
          <p className="section__three-sub--text">
            In all skill paths, you will not only learn from an experienced
            professional, but you will carry out tasks based on industry
            experience.
          </p>
        </div>
        <img
          src={steps}
          alt="stairs__illustration"
          className="section__three-img sectionThree__img-two"
        />
      </div>
    </div>
  );
};

export default SectionThree;
