import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ModuleProvider } from "./contexts/ModuleContext";
import { AuthUserDataProvider } from "./contexts/AuthUserDataContext";
import { CourseIdProvider } from "./contexts/CourseIdContext";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistGate } from "redux-persist/integration/react";
// import { store, persistor } from "./redux/store";
import store from "./redux/store";
import { Provider } from "react-redux";
import { Accessibility } from "accessibility/dist/main";
import "./index.css";

const queryClient = new QueryClient();

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  // baseURL: "https://cykeaprod.cykea.com/",
  baseURL: BASE_URL,
  // baseURL: "http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

window.addEventListener(
  "load",
  function () {
    new Accessibility();
  },
  false
);

// axios.defaults.baseURL = "https://backside-stage.cykea.com";
// // axios.defaults.baseURL = BASE_URL;

// axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthUserDataProvider>
        <ModuleProvider>
          <CourseIdProvider>
            <ScrollToTop />
            <Provider store={store}>
              {/* <PersistGate loading={null} persistor={persistor}> */}
              <QueryClientProvider client={queryClient}>
                <App />
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              </QueryClientProvider>
              {/* </PersistGate> */}
            </Provider>
          </CourseIdProvider>
        </ModuleProvider>
      </AuthUserDataProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
