import { ReactComponent as CykeaWhiteLogo } from "../../assets/icons/cykea-white-logo.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/features-down-arrow.svg";
import { ReactComponent as ScrollIcon } from "../../assets/icons/scroll-icon.svg";
import { ReactComponent as GradCapIcon } from "../../assets/icons/grad-cap-icon.svg";
import { ReactComponent as BriefcaseIcon } from "../../assets/icons/briefcase-icon.svg";
import { ReactComponent as BackgroundElement } from "../../assets/icons/background-element.svg";
import { ReactComponent as BackgroundElementTwo } from "../../assets/icons/background-element-two.svg";
import { ReactComponent as Badge } from "../../assets/icons/sectionOne-exp-badge.svg";
import { ReactComponent as Cert } from "../../assets/icons/sectionOne-cert-badge.svg";
import backGroundShape from "../../assets/images/section-one-new-box-bg.svg";
import "./sectionOne.css";

const SectionOne = () => {
  return (
    <div className="section__one-main--container">
      {/* <span className="space__cover-line--span">
        <div className="space__cover-line" />
      </span> */}
      <div className="sectionOne__container">
        <div className="section__one-box s2__box-one">
          <div className="bg__border-box">
            <div className="section__one-box-bg">
              <Badge className="box__one-badge--icon" />
            </div>
          </div>
          <div className="text__border-box">
            <div className="text__container">
              <h2 className="box__one-heading">Gain Experience</h2>
              <p className="box__one-sub--text">
                In all skill paths, you will not only learn from an experienced
                professional, but you will carry out tasks based on industry
                experience.
              </p>
            </div>
          </div>
        </div>
        <div className="section__one-box s2__box-two">
          <div className="bg__border-box">
            <div className="section__one-box-bg">
              <Cert className="box__one-cer--icon" />
            </div>
          </div>
          <div className="text__border-box">
            <div className="text__container">
              <h2 className="box__one-heading second__heading">
                Career-focused
              </h2>
              <p className="box__one-sub--text">
                You will not only learn from experts. You will gain skills and
                experience based on current industry demand.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
