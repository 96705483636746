import { ReactComponent as CheckIcon } from "../../assets/icons/check-mark-icon.svg";
import { ReactComponent as GreyedCheckIcon } from "../../assets/icons/greyed-checkmark-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setNextVideoIndex,
  setCurrentVideo,
} from "../../redux/video/videoActions";
import {
  setShowPassed,
  setShowVideoPlayer,
  setShowPassedPracticalQ,
  setShowPracticalQustion,
  setShowQuiz,
  setShowFailed,
  setShowOverlay,
  setShowCertificate,
} from "../../redux/course/courseActions";
import { setModule } from "../../redux/module/moduleActions";
import "./videoContent.css";

const VideoContent = ({ video, index, module, moduleIndex }) => {
  const dispatch = useDispatch();

  const currentVideo = useSelector((state) => state.video.currentVideo);

  const moduleDone = () => {
    dispatch(setShowPassed(false));
    dispatch(setShowPassedPracticalQ(false));
    dispatch(setShowPracticalQustion(false));
    dispatch(setShowQuiz(false));
    dispatch(setShowFailed(false));
    dispatch(setShowCertificate(false));
    dispatch(setShowVideoPlayer(true));
  };

  return (
    <div
      className="accordion__child-container"
      onClick={() => {
        if (video) {
          dispatch(setNextVideoIndex(index));
        }
        moduleDone();
        dispatch(setCurrentVideo(video));
        dispatch(setShowOverlay(false));
        dispatch(setModule(module));
        localStorage.setItem("moduleDetails", JSON.stringify(module));
        localStorage.setItem("moduleIndex", moduleIndex);
      }}
      style={{
        cursor: "pointer",
        background: video?.id === currentVideo?.id ? "#F6F8FF" : "",
      }}
    >
      <span className="content__check-and-text video__content-check-and-text">
        {video.userState === "completed" ? (
          <CheckIcon style={{ marginRight: "1rem" }} />
        ) : (
          <GreyedCheckIcon style={{ marginRight: "1rem" }} />
        )}

        <span
          className="accordion__child-text video__player-duration-and-status"
          style={{
            fontWeight: video?.id === currentVideo?.id ? "600" : "500",
          }}
        >
          {video ? video?.title : "Practical Task"}
          <span className="duration-and-start--CTA">
            <p className="content-duration">{video?.duration}mins</p>
          </span>
        </span>
      </span>
    </div>
  );
};

export default VideoContent;
