import { useEffect, useRef, useState, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Barge } from "../../assets/icons/cy-cert-barge.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/cykea-share-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/cykea-download-icon.svg";
import { ReactComponent as CeoSignature } from "../../assets/icons/ceo-signature.svg";
import { ReactComponent as LinkedinShareIcon } from "../../assets/icons/linkedin-share-icon.svg";
import { ReactComponent as TwitterShareIcon } from "../../assets/icons/twitter-share-icon.svg";
import { useDispatch } from "react-redux";
import { ReactComponent as LinkIcon } from "../../assets/icons/Link-icon.svg";
import {
  setShowCertificate,
  setShowVideoPlayer,
} from "../../redux/course/courseActions";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { LinkedinIcon, TwitterIcon } from "react-share";
import { useSelector } from "react-redux";
import axios from "axios";
// import { RWebShare } from "react-web-share";
// import Pdf from "react-to-pdf";

import "./certificat.css";

// mixpanel.init("f3574152559b2e61aa7f78dc5a2dee86", {
//   debug: true,
// });

const Certificate = () => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const certificate = useSelector((state) => state.course.certificate);

  const ref = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const printMe = () => {
    var printContents = document.getElementById("printDiv").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const issued = certificate.createdAt;

  const d = new Date(issued.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const issuedDate = `${day}${nth(day)} of ${month}, ${year}`;
  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirectUri = "https://cykea.com/share-to-linkedin";

  const handleLinkedInAuth = () => {
    // Redirect the user to the LinkedIn's authentication page
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=certificate&scope=r_liteprofile%20w_member_social`;
  };

  const courseId = { id: "affae8be-4208-43f0-b291-17875ef0bfd5" };

  return (
    <>
      <div
        className="videoPlayer__container--two viewport__height"
        style={{ overflow: "visible !important" }}
      >
        <div className="passed__container">
          <span className="passed__content">
            <h1 className="passed__heading">
              <span
                style={{
                  fontWeight: "700",
                  marginRight: ".5rem",
                  color: "#171531",
                }}
              >
                Congratulations!
              </span>
              You have completed the course and you’ve been awarded a
              certificate!
            </h1>

            <div className="cert__content--container" ref={ref} id="printDiv">
              <div className="cert__desc-and-barge">
                <span className="desc-and-sign">
                  <span className="desc-and-name--span">
                    <p className="cert_of_completion">
                      Certification Of Completion
                    </p>
                    <p className="holders__name">
                      {certificate.name.toUpperCase()}
                    </p>
                    <p className="certificate__description">
                      has successfully completed the {certificate.courseName}{" "}
                      course.
                    </p>
                  </span>
                  <div className="barge">
                    <span className="sign__and__date">
                      <span className="sign__and__name">
                        <CeoSignature className="signature" />

                        <p className="cert__Ceo">CEO, Cykea</p>
                      </span>
                      <span className="cert__date--span">
                        <span className="date__ofissuance--span">
                          <p className="cert__date">{issuedDate}</p>
                        </span>
                        <p className="cert__date--text">Date</p>
                      </span>
                    </span>
                    <span className="barge__and__cert-no--span">
                      <Barge className="cert__barge-icon" />
                      <p className="cert__number">
                        {certificate.certificateNumber}
                      </p>
                    </span>
                  </div>
                </span>
              </div>
            </div>

            <span className="share__and__continue--CTA">
              <span className="download__cert-and-share--CTAs">
                <DownloadIcon
                  style={{ cursor: "pointer" }}
                  className="download__cert--CTA"
                  onClick={() => {
                    navigate("/panel/download-certificate");
                  }}
                />

                <span className="share__cert--CTA">
                  {showShareOptions ? (
                    <span className="share__pop--up">
                      <p className="share__heading">Share</p>

                      <div className="social__share--icons">
                        <TwitterShareButton
                          url={`Join me at https://www.cykea.com`}
                          title={`I've just earned a certificate for completing the ${certificate.courseName} course on @cykea_academy. Check out my certificate here: https://cykea.com/shared-cert/${certificate.id}`}
                          // via="I just completed Fezzant's Email Security series! Checkout out my shiny new certificate. Cool, innit?"
                          hashtags={[
                            "cykea",
                            "cybersecurity",
                            "infosec",
                            "cyberlearner",
                          ]}
                          description={`I've just earned a certificate for completing the ${certificate.courseName} course on Cykea. Join me at https://www.cykea.com`}
                        >
                          <span className="logo_link--span">
                            <TwitterShareIcon borderRadius={3} size={22} />{" "}
                            <p className="share__icon--text">Twitter</p>
                          </span>
                        </TwitterShareButton>

                        <button
                          onClick={handleLinkedInAuth}
                          className="linkedin__share-button"
                        >
                          <LinkedinShareIcon borderRadius={3} size={22} />
                          <p className="linkedin__share-button--text">
                            Linkedin
                          </p>
                        </button>
                        <CopyToClipboard
                          text={`https://cykea.com/shared-cert/${certificate.id}`}
                          onCopy={() => alert("Copied")}
                        >
                          <span
                            className="logo_link--span"
                            style={{ cursor: "pointer" }}
                          >
                            <LinkIcon />{" "}
                            <p className="share__icon--text">Copy Link</p>
                          </span>
                        </CopyToClipboard>
                      </div>
                    </span>
                  ) : null}
                  <ShareIcon
                    style={{ cursor: "pointer" }}
                    className="share__cert-CTA--icon"
                    onClick={() => {
                      setShowShareOptions(!showShareOptions);
                    }}
                  />
                </span>
              </span>
              <button
                className="continue__CTA"
                onClick={() => {
                  dispatch(setShowCertificate(false));
                  dispatch(setShowVideoPlayer(true));
                }}
              >
                Continue{" "}
              </button>
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Certificate;
