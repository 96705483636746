import boxImageOne from "../../../assets/images/box-img-one.png";
import boxImageTwo from "../../../assets/images/box-img-two.png";
import { useNavigate } from "react-router-dom";
import "./sectionOne.css";

const SectionOne = () => {
  const navigate = useNavigate();

  return (
    <div className="ethical__section-one--container" id="sectionOne">
      <div className="ethical__section-content">
        <div className="ethical__section-one-boxes">
          <div className="ethical__section-one-box">
            <img src={boxImageOne} alt="books" className="ethical__box-image" />
            <p className="ethical__box-text">
              Our Comprehensive curriculum covers the fundamentals of ethical
              hacking, methodologies, and best practices.
            </p>
          </div>
          <div className="ethical__section-one-box ethical__box-two">
            <img src={boxImageTwo} alt="books" className="ethical__box-image" />

            <p className="ethical__box-text">
              We equip you with the Practical skills to excel as an offensive
              security professional and earn Industry-recognized certifications.
            </p>
          </div>
        </div>
        <div className="ethical__section-one-big--box">
          <p className="ethical__big-box--text">
            Not sure if it is the right course for you?

          </p>
          <span className="contact__info-span">
            <p className="contact__text">Contact Us:</p>
            <p className="contact__us-email">cykea@fezzant.com</p>
          </span>
          {/* <button
            className="ethical__section-two--CTA"
            onClick={() => navigate("/ethical-hacking-enrollment")}
          >
            Contact Us
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
