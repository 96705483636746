export const courseActionTypes = {
  STORE_COURSE: "STORE_COURSE",
  STORE__COURSE__ID: "STORE__COURSE__ID",
  SHOW_COURSE_OVERVIEW: "SHOW_COURSE_OVERVIEW",
  SHOW_COURSE_CONTENT: "SHOW_COURSE_CONTENT",
  SHOW_SIDE_COURSE_CONTENT: "SHOW_SIDE_COURSE_CONTENT",
  SHOW_COURSE_TRANSCRIPT: "SHOW_COURSE_TRANSCRIPT",
  SHOW_PASSED: "SHOW_PASSED",
  SHOW_FAILED: "SHOW_FAILED",
  SHOW_QUIZ: "SHOW_QUIZ",
  SHOW_PASSED_PRACTICAL_QUESTION: "SHOW_PASSED_PRACTICAL_QUESTION",
  SHOW_PRACTICAL_QUESTION: "SHOW_PRACTICAL_QUESTION",
  SHOW_OVERLAY: "SHOW_OVERLAY",
  SHOW_VIDEO_PLAYER: "SHOW_VIDEO_PLAYER",
  SHOW_CERTIFICATE: "SHOW_CERTIFICATE",
  PASSED_PRACTICAL_QUESTION: "PASSED_PRACTICAL_QUESTION",
  STORE_CERTIFICATE: "STORE_CERTIFICATE",
  SHOW_FREE_BANNER: "SHOW_FREE_BANNER",
  UPDATE_VIDEO_USER_STATE: "UPDATE_VIDEO_USER_STATE",
  SWITCH_PRACTICAL_QUESTION: "SWITCH_PRACTICAL_QUESTION",
};
