import { useState } from "react";
import FaqAccordion from "../FAQAccordion/FaqAccordion";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import useAnalyticsEventTracker from "../../useAnalyticsEventsTracker";
import { Link } from "react-router-dom";
import { ReactComponent as XIcon } from "../../assets/icons/mobile-menu-x.svg";
import { faqsContent } from "../../faqsContent";
import { v4 as uuidv4 } from "uuid";

import "./faq.css";
import MobileNav from "../MobileNav/MobileNav";

const Faq = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker("FAQ");

  return (
    <>
      <MetaDecorator
        title="Cykea - FAQs"
        description="You can find answers to Frequently asked questions about Cykea on this page"
      />
      <NavBar
        whiteBg="yes"
        pricing="yes"
        openMobileNav={openMobileNav}
        setOpenMobileNav={setOpenMobileNav}
      />

      <div className="faqs__container">
        {openMobileNav ? (
          <MobileNav
            openMobileNav={openMobileNav}
            setOpenMobileNav={setOpenMobileNav}
          />
        ) : null}
        <p className="faqs__heading">FAQs</p>
        <div className="faqs__content-container">
          {faqsContent.map((content) => (
            <FaqAccordion
              key={uuidv4()}
              question={content.question}
              answer={content.answer}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
