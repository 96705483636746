import { useState } from "react";
import cardIconFive from "../../assets/icons/skill-card-icon-5.svg";
import { Link } from "react-router-dom";
import { ReactComponent as XIcon } from "../../assets/icons/mobile-menu-x.svg";
import { ReactComponent as CardIcon } from "../../assets/icons/rs-card-icon-one.svg";
import { ReactComponent as CardIconTwo } from "../../assets/icons/rs-card-icon-two.svg";
import { ReactComponent as CardIconThree } from "../../assets/icons/rs-card-icon-three.svg";
import { ReactComponent as CardIconFour } from "../../assets/icons/rs-card-icon-four.svg";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import "./resources.css";
import MobileNav from "../MobileNav/MobileNav";

const Resources = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);

  return (
    <>
      <MetaDecorator
        title="CyKea - Resources"
        description="Cykea's resources page"
      />
      <NavBar
        openMobileNav={openMobileNav}
        setOpenMobileNav={setOpenMobileNav}
      />
      <div className="resources-container">
        {openMobileNav ? (
          <MobileNav
            openMobileNav={openMobileNav}
            setOpenMobileNav={setOpenMobileNav}
          />
        ) : null}
        <h1 className="resources-heading">Resources</h1>
        <div className="resources-box--container">
          <a href="https://staging.cykea.com/resources" target="#_blank">
            <div className="resources-box ">
              <div
                className="resources-image--half s__box-1"
                // style={{ backgroundImage: `url(${cardIconFive})` }}
              >
                <CardIcon className="resources__card-icon" />
              </div>
              <div className="resources-text--half">
                <span className="resources-text-and-coming--span">
                  <p className="resources-date">24th May, 2022</p>
                  {/* <span className="resources-coming--soon">Coming Soon</span> */}
                </span>
                <h2 className="resources-card--main-text">
                  How to switch career into cybersecurity
                </h2>
              </div>
            </div>
          </a>
          <a href="https://staging.cykea.com/resources" target="#_blank">
            <div className="resources-box">
              <div className="resources-image--half s__box-2">
                <CardIconTwo className="resources__card-icon" />
              </div>
              <div className="resources-text--half">
                <span className="resources-text-and-coming--span">
                  <p className="resources-date">24th May, 2022</p>
                </span>
                <h2 className="resources-card--main-text">
                  The most important skill in cybersecurity
                </h2>
              </div>
            </div>
          </a>
          <a href="https://staging.cykea.com/resources" target="#_blank">
            <div className="resources-box ">
              <div className="resources-image--half s__box-3">
                <CardIconThree className="resources__card-icon" />
              </div>
              <div className="resources-text--half">
                <span className="resources-text-and-coming--span">
                  {/* <p className="resources-date">24th May, 2022</p> */}
                  <span className="resources-coming--soon">Coming Soon</span>
                </span>
                <h2 className="resources-card--main-text">
                  How to optimise your cybersecurity learning process
                </h2>
              </div>
            </div>
          </a>
          <a href="https://staging.cykea.com/resources" target="#_blank">
            <div className="resources-box ">
              <div className="resources-image--half s__box-4">
                <CardIconFour className="resources__card-icon" />
              </div>
              <div className="resources-text--half">
                <span className="resources-text-and-coming--span">
                  {/* <p className="resources-date">24th May, 2022</p> */}
                  <span className="resources-coming--soon">Coming Soon</span>
                </span>
                <h2 className="resources-card--main-text">
                  5 great podcasts for cybersecurity beginners
                </h2>
              </div>
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Resources;
