import { useEffect, useRef, useState, useMemo } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-circle.svg";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cykea-cert-logo.svg";
import { ReactComponent as Barge } from "../../assets/icons/cy-cert-barge.svg";
import { ReactComponent as CykeaWebAddress } from "../../assets/icons/cykea-web-address.svg";
// import { ReactComponent as CertBarge } from "../../assets/icons/cert-barge.svg";
// import { useCertificateContext } from "../../contexts/CertificateContext";
import { ReactComponent as ShareIcon } from "../../assets/icons/cykea-share-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/cykea-download-icon.svg";
import { ReactComponent as CeoSignature } from "../../assets/icons/ceo-signature.svg";
import { ReactComponent as CeoSign } from "../../assets/icons/ceo-sign.svg";
// import mixpanel from "mixpanel-browser";
import axios from "axios";
import { ReactComponent as LinkIcon } from "../../assets/icons/Link-icon.svg";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import { LinkedinIcon, TwitterIcon } from "react-share";
import { useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import { useReactToPrint } from "react-to-print";

const DownloadableCert = () => {
  const certificate = useSelector((state) => state.course.certificate);

  const ref = useRef();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const config = useMemo(() => {
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }, [token]);

  const courseId = "affae8be-4208-43f0-b291-17875ef0bfd5";

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => navigate(`/panel/courses/single-course/${courseId}`),
    // removeAfterPrint: true,
    documentTitle: `My Cykea Certificate`,
  });

  useEffect(() => {
    handlePrint();
  }, []);

  const printMe = () => {
    var printContents = document.getElementById("printDiv").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const issued = certificate.createdAt;

  const d = new Date(issued.substr(0, 10));
  let month = months[d.getMonth()];
  const year = d.getFullYear();
  const day = d.getDate();
  const issuedDate = `${day}${nth(day)} of ${month}, ${year}`;

  return (
    <>
      <MetaTags>
        <title>Download - Certificate</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      <div className="shared__cert-container" ref={componentRef}>
        <div className="passed__container">
          <div
            className="cert__content--container"
            // ref={ref}
            ref={componentRef}
            id="printDiv"
          >
            <div className="cert__desc-and-barge">
              <span className="desc-and-sign">
                <span className="desc-and-name--span">
                  <p className="cert_of_completion">
                    Certification Of Completion
                  </p>
                  <p className="holders__name">
                    {certificate.name.toUpperCase()}
                    {/* ABDULLAH ADAM */}
                  </p>
                  <p
                    className="downloadable__certificate-description"
                    // style={{ color: "#9accfb" }}
                  >
                    has successfully completed the {certificate.courseName}{" "}
                    course.
                  </p>
                </span>
                <div className="barge">
                  <span className="sign__and__date">
                    <span className="sign__and__name">
                      <CeoSignature className="signature" />

                      <p className="cert__Ceo">CEO, Cykea</p>
                    </span>
                    <span className="cert__date--span">
                      <span className="date__ofissuance--span">
                        <p className="cert__date">{issuedDate}</p>
                      </span>
                      <p className="cert__date--text">Date</p>
                    </span>
                  </span>
                  <span className="barge__and__cert-no--span">
                    <Barge className="cert__barge-icon" />
                    <p className="cert__number">
                      {certificate.certificateNumber}
                    </p>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadableCert;
