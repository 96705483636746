import { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Panel from "./components/Panel/Panel";
import Courses from "./components/Courses/Courses";
import Profile from "./components/Profile/Profile";
import SingleCourse from "./components/SingleCourse/SingleCourse";
import { ToastContainer, toast } from "react-toastify";
import ShareCert from "./components/ShareCert/ShareCert";
import DownloadableCert from "./components/DownloadableCert/DownloadableCert";
import WaitList from "./components/Waitlist/Waitlist";
import HubspotContactForm from "./components/HubspotContactForm/HubspotContactForm";
import CompletePWordReset from "./components/CompletePWordReset/CompletePWordReset";
import LandingPage from "./components/LandingPage/LandingPage";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Pricing from "./components/Pricing/Pricing";
import Faq from "./components/FAQ/Faq";
import JoinSlack from "./components/JoinSlack/JoinSlack";
// import ReactGA from "react-ga";
import GA4React from "ga-4-react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import axios from "axios";
import NotFound from "./components/NotFound/NotFound";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import SkillsPath from "./components/SkillsPath/SkillsPath";
import Resources from "./components/ResourcesPage/Resources";
import cykeaImage from "./assets/images/cybarik.png";
import { useQuery } from "@tanstack/react-query";
import { api } from ".";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import OurMission from "./components/OurMission/OurMission";

import ShareOnLinkedin from "./components/LinkedinShare/ShareOnLinkedin";

import MetaDecorator from "./components/MetaDecorator/MetaDecorator";
import Countdown from "./components/CountDown/CountDown";
import EthicalHackingPage from "./components/EthicalHackingPage/EthicalHackingPage";
import EnrollForm from "./components/EthicalHackingPage/EnrollForm/EnrollForm";

function App() {
  const [courses, setCourses] = useState();
  const [currency, setCurrency] = useState();
  // const [courseId, setCourseId] = useState();
  const tawkMessengerRef = useRef();

  const location = useLocation();

  // try {
  //   setTimeout((_) => {
  //     const ga4react = new GA4React("G-P7VBWEMSQ2");
  //     ga4react.initialize().catch((err) => console.error(err));
  //   }, 4000);
  // } catch (err) {
  //   console.error(err);
  // }

  // const courseId = { id: "afd23328-6982-4203-9e36-cdd02e7f966a" };
  // const courseId = { id: "affae8be-4208-43f0-b291-17875ef0bfd5" };

  const getCourses = () => {
    api
      .get("/api/v1/course/all/allcourses")
      .then((res) => {
        const courseId = res.data.courses[0].id;
        localStorage.setItem("courseId", `${courseId}`);
      })
      .catch((error) => console.error(error));
  };

  ///////// Adding arial-label to tawk widget for accessibility
  useEffect(() => {
    const widgetButton = document.querySelector(".tawk-min-chat-icon");

    if (widgetButton) {
      widgetButton.setAttribute("aria-label", "Chat with us");
    }
    getCourses();
  }, []);

  return (
    <>
      <MetaDecorator
        title="Cykea"
        description="CyKea, the career-focused online cybersecurity academy to help you gain the right skills that the industry needssss."
        url="https://cykea.com"
        imageUrl="https://i.postimg.cc/XvFD1cSS/cykea-05-1-1.png"
      />

      <div className="App">
        <ToastContainer pauseOnHover />
        <Routes location={location} key={location.key}>
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/" element={<Countdown />} /> */}
          <Route path="/wait-list-form" element={<HubspotContactForm />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/not-found" element={<NotFound />} />
          {/* <Route
            path="/pricing"
            element={<Pricing courses={data} userCurrency={currency} />}
          /> */}
          <Route path="/faq" element={<Faq />} />
          <Route path="/skills-path" element={<SkillsPath />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/ethical-hacking-enrollment" element={<EnrollForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/ethical-hacking" element={<EthicalHackingPage />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/panel" element={<Panel />}>
              <Route path="/panel/courses" element={<Courses />} />
              <Route
                path="/panel/courses/single-course/:id"
                element={<SingleCourse />}
              />
              <Route path="/panel/profile" element={<Profile />} />
              <Route path="/panel/our-mission" element={<OurMission />} />
              <Route
                path="/panel/download-certificate"
                element={<DownloadableCert />}
              />
            </Route>
          </Route>
          {/* <Route path="/" element={<WaitList />} /> */}
          <Route path="/login" element={<Login />} />
          {/* <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/panel/courses" /> : <Login />}
        /> */}
          <Route path="/reset-password" element={<CompletePWordReset />} />
          <Route path="/register" element={<Register />} />
          <Route path="/shared-cert/:id" element={<ShareCert />} />
          <Route path="/join-discord-community" element={<JoinSlack />} />
          <Route path="/share-to-linkedin" element={<ShareOnLinkedin />} />
        </Routes>
        <TawkMessengerReact
          propertyId="631beaf754f06e12d893d02e"
          widgetId="1gcigrlu9"
          ref={tawkMessengerRef}
          arial-label="chat widget"
          // customStyle={customStyle}
          // customStyle={{ zIndex: "1 !important" }}
        />
      </div>
    </>
  );
}

export default App;
