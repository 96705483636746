import { useState } from "react";
import { ReactComponent as CykeaLogo } from "../../../assets/icons/cy-mobile-menu-logo.svg";
import { ReactComponent as UkIcon } from "../../../assets/icons/uk-icon.svg";
import { ReactComponent as NigeriaIcon } from "../../../assets/icons/nigeria-icon.svg";
import { ReactComponent as GhanaIcon } from "../../../assets/icons/ghana-icon.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/globe-icon.svg";
import { ReactComponent as Down } from "../../../assets/icons/dropdown-icon.svg";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";
import "./enrollForm.css";
import { api } from "../../..";

const hubspotKey = process.env.REACT_APP_HUBSPOT_KEY;

const EnrollForm = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [location, setLocation] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPayNow, setIsPayNow] = useState(false);

  const updateLocation = (selectedLocation) => {
    setLocation(selectedLocation);
    setOpenDropdown(false);
  };

  const sendPaymentLink = () => {
    api
      .post("/api/v1/ethicalhacking/completePaymentMail", {
        name: fullName,
        email,
      })
      .then((res) => {
        setFullName("");
        setEmail("");
        setPhoneNumber("");
        setLocation("");
      })
      .catch((error) => console.error(error));
  };

  const handleAfterSubmit = () => {
    if (isPayNow) {
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setLocation("");
      setTimeout(() => {
        window.location.href = "https://buy.stripe.com/aEU2c35WB9KX5kA9AC";
      }, 3000);
    } else {
      sendPaymentLink();
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://api.hsforms.com/submissions/v3/integration/submit/144629980/3b52fa0d-1d58-4c61-bb31-40d9cdea304c",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hubspotKey}`,
          },
          fields: [
            {
              name: "full_name",
              value: fullName,
            },
            {
              name: "email",
              value: email,
            },
            {
              name: "phone",
              value: phoneNumber,
            },
            {
              name: "location",
              value: location,
            },
          ],
          context: {
            pageUri: "https://cykea.com/ethical-hacking-enrollment",
            pageName: "Ethical hacking enrollment page",
          },
        }
      )
      .then((res) => {
        toast("Registration Successful!");
        handleAfterSubmit();
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="ethical__enroll-form--container">
      <div className="enroll__form-content">
        <div className="enroll__form-container">
          <span className="enroll__form-logo--span">
            <Link to="/" aria-label="Cykea's Logo">
              <CykeaLogo className="cykea__landing-logo" />
            </Link>
          </span>
          <p className="enroll__form-notice">Notice</p>
          <p className="enroll__form-notice--text">
            All information collected here will be used for the purpose of this
            training.
          </p>
          <form
            action="submit"
            className="ethical__enroll-form"
            onSubmit={handleSubmit}
          >
            <label
              htmlFor="
            fullName"
              className="enroll__input-label"
            >
              Full Name
            </label>
            <input
              type="text"
              className="enroll__form-input"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <label
              htmlFor="
            email"
              className="enroll__input-label"
            >
              Email
            </label>
            <input
              type="email"
              className="enroll__form-input"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label
              htmlFor="
            phoneNumber"
              className="enroll__input-label"
            >
              Phone Number
            </label>
            <input
              type="text"
              className="enroll__form-input"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <label
              htmlFor="
            location"
              className="enroll__input-label"
            >
              Location
            </label>
            <div className="ethical__form__dropdown__container">
              <div className="ethical__location-input--container">
                <input
                  type="select"
                  className="enroll__form-input"
                  defaultValue={location}
                  id="location"
                  readOnly
                  required
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenDropdown(!openDropdown)}
                />
                <Down className="down__icon" />
              </div>
              {openDropdown ? (
                <div className="ethical__form-dropdown">
                  <span
                    className="down__down-item"
                    onClick={() => {
                      updateLocation("United Kingdom");
                    }}
                  >
                    <UkIcon className="dropdown-icon" />
                    <p className="dropdown-location">United Kingdom</p>
                  </span>
                  <span
                    className="down__down-item"
                    onClick={() => {
                      updateLocation("Nigeria");
                    }}
                  >
                    <NigeriaIcon className="dropdown-icon" />
                    <p className="dropdown-location">Nigeria</p>
                  </span>
                  <span
                    className="down__down-item"
                    onClick={() => {
                      updateLocation("Ghana");
                    }}
                  >
                    <GhanaIcon className="dropdown-icon" />
                    <p className="dropdown-location">Ghana</p>
                  </span>
                  <span
                    className="down__down-item"
                    onClick={() => {
                      updateLocation("Rest of the world");
                    }}
                  >
                    <GlobeIcon className="dropdown-icon" />
                    <p className="dropdown-location">Rest of the world</p>
                  </span>
                </div>
              ) : null}
            </div>
            <p className="enroll__form-disclaimer--text">
              By submitting this form, you agree that we may use your
              information to contact you.
            </p>

            <p className="enroll__form-disclaimer--text">
              <b>
                Note: Payment reserves your spot on the cohort. If you have any
                issues with payment, contact us on cykea@fezzant.com
              </b>
            </p>

            <button
              className="enroll__Pay-CTA pay__now-CTA"
              onClick={() => setIsPayNow(true)}
            >
              Pay Now
            </button>

            <button
              className="enroll__Pay-CTA pay__later-CTA"
              onClick={() => setIsPayNow(false)}
            >
              Pay Later
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnrollForm;
