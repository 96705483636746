import { videoActionTypes } from "./videoActionTypes";

export const setCurrentVideo = (video) => ({
  type: videoActionTypes.SET_CURRENT_VIDEO,
  payload: video,
});

export const setVideoUrl = (videoUrl) => ({
  type: videoActionTypes.SET_VIDEO_URL,
  payload: videoUrl,
});

export const setDuration = (videoDuration) => ({
  type: videoActionTypes.SET_VIDEO_DURATION,
  payload: videoDuration,
});

export const setNextVideoIndex = (index) => ({
  type: videoActionTypes.SET_NEXT_VIDEO_INDEX,
  payload: index,
});

export const setPlayerLoading = (status) => ({
  type: videoActionTypes.SET_PLAYER_LOADING,
  payload: status,
});
export const updateVideoWatchStatus = (status) => ({
  type: videoActionTypes.UPDATE_VIDEO_WATCH_STATUS,
  payload: status,
});

export const increaseVideoIndex = () => ({
  type: videoActionTypes.INCREASE_VIDEO_INDEX,
});

export const decreaseVideoIndex = () => ({
  type: videoActionTypes.DECREASE_VIDEO_INDEX,
});

export const setSubUrl = (subUrl) => ({
  type: videoActionTypes.SET_SUB_URL,
  payload: subUrl,
});
