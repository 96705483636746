import { useState } from "react";
import { ReactComponent as NewIcon } from "../../assets/icons/floating-access-new-icon.svg";
import { useNavigate } from "react-router-dom";
import "./floatingAccess.css";

const FloatingAccess = () => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const changeBackground = () => {
    if (window.scrollY >= 500) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div
      className="floating__access__container"
      style={{ display: show ? "flex" : "none" }}
    >
      <NewIcon className="floating__access__new__icon" />
      <p className="floating__access__main__text">
        Cykea Ethical Hacking Academy
      </p>
      <p className="floating__access__sub__text">
        Limited slots available, <strong>click to register</strong>
      </p>
      <button
        className="enroll__now__CTA"
        onClick={() => navigate("/ethical-hacking")}
      >
        Enroll Now!
      </button>
    </div>
  );
};

export default FloatingAccess;
