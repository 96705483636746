import { ReactComponent as ListIcon } from "../../../assets/icons/ethical-list-icon.svg";
import "./sectionTwo.css";

const SectionTwo = () => {
  return (
    <div className="ethical__section-two--container">
      <div className="ethical__section-two--content">
        <div className="section__two-main--box">
          <div className="section__two-title--box">
            <p className="course__overview-main--text">Course Overview</p>
            <p className="course__overview-sub--text">
              The Academy offers a rigorous{" "}
              <strong>18-week training program</strong> covering the following
              modules: 
            </p>
          </div>
          <div className="section__two-course--overview--box">
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 1</p>
              <p className="course__period-content">
                Introduction to Ethical Hacking Concepts – Penetration Testing,
                Methodology, Laws, and Professional Ethics. 
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 2</p>
              <p className="course__period-content">
                Reconnaisance - Information Gathering, Enumeration, OSINT
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 3-5</p>
              <p className="course__period-content">
                Software and Web Application Exploitation 
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 6-8</p>
              <p className="course__period-content">
                Network and Infrastructure Exploitation - Linux 
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 9</p>
              <p className="course__period-content">
                Phishing and Social Engineering
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 10-12</p>
              <p className="course__period-content">
                Network and Infrastructure Exploitation - Linux 
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 13-14</p>
              <p className="course__period-content">
                Emerging Technologies: Cloud and AI Hacking 
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 15-16</p>
              <p className="course__period-content">
                Communication, Compliance and Frameworks 
              </p>
            </div>
            <div className="section__two-course--content--detail">
              <p className="course__time-period">Week 17-18</p>
              <p className="course__period-content">
                Career Development 
              </p>
            </div>
          </div>
          <div className="course__sprint-content--box">
            <p className="course__spring-heading">
              Each of our 1-3 week sprints includes:
            </p>
            <span className="listed__sprint-item">
              <ListIcon className="ethical__list-icon" />
              <p className="sprint__content-text">
                Practical labs to be completed by students under strict
                timelines. 
              </p>
            </span>
            <span className="listed__sprint-item">
              <ListIcon className="ethical__list-icon" />
              <p className="sprint__content-text">
                Individual reports and presentations made by each student for labs completed. 
              </p>
            </span>
            <span className="listed__sprint-item">
              <ListIcon className="ethical__list-icon" />
              <p className="sprint__content-text">
                Continuous career development (CV, LinkedIn, etc.)  
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
