import { useAuthUserDataContext } from "../../contexts/AuthUserDataContext";
import { useState, useEffect, useMemo } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import Course from "../Course/Course";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setNextVideoIndex } from "../../redux/video/videoActions";
import { api } from "../..";
import "./courses.css";
import {
  setShowCertificate,
  setShowPassed,
  setShowPassedPracticalQ,
  setShowPracticalQustion,
  setShowQuiz,
  setShowVideoPlayer,
} from "../../redux/course/courseActions";

const fetchPaidCourses = async () => {
  // const res = await api.get("/api/v1/course/user/paidcourse");
  const res = await api.get("/api/v1/course/all/allcourses");

  return res;
};

const Courses = () => {
  const { status, data } = useQuery({
    queryKey: ["paidCourses"],
    queryFn: () => fetchPaidCourses(),
  });

  const [loading, setLoading] = useState(false);

  const { authUserData } = useAuthUserDataContext();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("moduleDetails");
    localStorage.removeItem("moduleIndex");
    dispatch(setNextVideoIndex(0));
    dispatch(setShowCertificate(false));
    dispatch(setShowVideoPlayer(true));
    dispatch(setShowPracticalQustion(false));
    dispatch(setShowPassed(false));
    dispatch(setShowPassedPracticalQ(false));
    dispatch(setShowQuiz(false));
  }, []);

  return (
    <div className="series__list--container">
      {status === "loading" ? (
        <div className="loader__container" style={{ position: "fixed" }}>
          <FadeLoader color="purple" loading={loading} size={15} />
        </div>
      ) : null}
      <span className="seriesList__heading--span">
        <h2 className="seriesList__heading">
          Let's Start Learning, {authUserData.user?.firstName}
        </h2>
      </span>
      <div className="series__list--content">
        {data &&
          data.data.courses?.map((course, index) => {
            return (
              <Course
                key={uuidv4()}
                name={course.name}
                image={course.thumbnail}
                courseId={course.id}
                points={course.totalPoints}
                content={course.Contents}
                duration={course.duration}
                status={course.status}
                percentageCompleted={course.percentage}
                index={index}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Courses;
