import { useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { ReactComponent as ResetSuccessIcon } from "../../assets/icons/password-reset-icon.svg";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";
import { useState } from "react";
import { api } from "../..";
import "./completePWordReset.css";

const PWD_REGEX = /^.{8,24}$/;

const CompletePWordReset = () => {
  const [pwd, setPwd] = useState("");
  const [userId, setUserId] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pWordResetSuccess, setPWordResetSuccess] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  const navigate = useNavigate();

  const verifyToken = () => {
    setLoading(true);
    api
      .post("/api/v1/user/verifytoken", { token })
      .then((res) => {
        setUserId(res.data.user?.id);
        setTokenVerified(true);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSuccess(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  const resetPassword = (e) => {
    setLoading(true);
    e.preventDefault();

    api
      .post("/api/v1/user/resetpassword", { id: userId, password: pwd })
      .then((res) => {
        setLoading(false);
        setPwd("");
        setMatchPwd("");
        setPWordResetSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="loader__container" style={{}}>
          <FadeLoader color="purple" loading={loading} size={15} />
        </div>
      )}

      {tokenVerified ? (
        <div className="reset__pWord-container">
          <Link to="/" aria-label="Cykea's Logo">
            <CykeaLogo className="cykea__landing-logo" />
          </Link>
          <div className="reset__pWord-form--container">
            {pWordResetSuccess ? (
              <div className="password__reset-success">
                <p className="reset__success-text">Password Reset Successful</p>
                <ResetSuccessIcon className="password__reset-icon" />
                <button
                  className="login__after-reset--CTA"
                  onClick={() => navigate("/login")}
                >
                  Log in
                </button>
              </div>
            ) : (
              <form
                className="reset__pWord-form"
                onSubmit={(e) => resetPassword(e)}
              >
                <span className="forgot__password-and-close">
                  <span className="forgot__password-heading-and-close--CTA">
                    <p className="forgot__pword">Reset Password</p>
                  </span>
                  <CloseIcon
                    className="close__forgot-pword"
                    onClick={() => navigate("/login")}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <label
                  htmlFor="newPassword"
                  className="reset__pword-input--label"
                >
                  New Password
                </label>
                <input
                  type="text"
                  id="password"
                  //   value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  className="reset__pWord-new--pWord"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                />
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !validPwd ? "instruction" : "offScreen"
                  }
                >
                  {/* 8 to 24 characters.
                    <br /> */}
                  Must be at least 8 characters long.
                </p>
                <label
                  htmlFor="newPassword"
                  className="reset__pword-input--label"
                >
                  Confirm Password
                </label>
                <input
                  type="text"
                  className="reset__pWord-new--pWord"
                  value={matchPwd}
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  onChange={(e) => setMatchPwd(e.target.value)}
                />
                <p
                  id="confirmnote"
                  className={!validMatch ? "instruction" : "offScreen"}
                >
                  Must match the first password input field.
                </p>
                <button
                  className="reset__pWord-CTA"
                  disabled={!validPwd || !validMatch ? true : false}
                >
                  Reset Password
                </button>
              </form>
            )}
          </div>
        </div>
      ) : (
        <span className="verified__token-span">
          <p className="Join__slack-community--text">
            We couldn't verify your access
          </p>
          <button
            className="join__slack-community--CTA"
            onClick={() => navigate("/login")}
          >
            Back to Login
          </button>
        </span>
      )}
    </>
  );
};

export default CompletePWordReset;
