import { useState } from "react";
import { ReactComponent as CloseNotification } from "../../assets/icons/close-notification-icon.svg";
import "./notificationAlert.css";

const NotificationAlert = ({ text, closeNotification }) => {
  return (
    <div className="notification__alert-container">
      {/* <div className="notification__content"> */}
      <span className="notification__text-span">
        {text}
        <CloseNotification
          className="close__notification-icon"
          onClick={closeNotification}
        />
      </span>
      {/* </div> */}
    </div>
  );
};

export default NotificationAlert;
