import { ReactComponent as LinkedinIcon } from "../../assets/icons/cy-instructor-linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/cy-instructor-twitter.svg";
import { ReactComponent as TwitterShareIcon } from "../../assets/icons/twitter-share-icon.svg";
import { ReactComponent as InstagramShareIcon } from "../../assets/icons/ig-share-icon.svg";
import { ReactComponent as LinkedinShareIcon } from "../../assets/icons/linkedin-share-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as LinkShareIcon } from "../../assets/icons/link-share-icon.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/Link-icon.svg";
// import fezzantImg from "../../assets/images/fezzant-img.png";
import { useNavigate } from "react-router-dom";
import fezzantImg from "../../assets/images/ceo-pic.jpeg";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import {
  setShowCourseOverview,
  setShowCourseContent,
  setShowCourseTranscript,
} from "../../redux/course/courseActions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const CourseOverview = ({ course }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const currentVideo = useSelector((state) => state.video.currentVideo);

  const certificate = useSelector((state) => state.course.certificate);

  const showCourseContent = useSelector(
    (state) => state.course.showCourseContent
  );
  const showCourseOverview = useSelector(
    (state) => state.course.showCourseOverview
  );

  const showCourseTranscript = useSelector(
    (state) => state.course.showCourseTranscript
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toggleCourseOverview = () => {
    dispatch(setShowCourseOverview(true));
    // if (showCourseContent) {
    dispatch(setShowCourseContent(false));
    dispatch(setShowCourseTranscript(false));
    // }
  };
  const toggleCourseContent = () => {
    dispatch(setShowCourseContent(true));
    // if (showCourseOverview) {
    dispatch(setShowCourseOverview(false));
    dispatch(setShowCourseTranscript(false));
    // }
  };

  const toggleCourseTranscript = () => {
    dispatch(setShowCourseTranscript(true));
    dispatch(setShowCourseContent(false));
    dispatch(setShowCourseOverview(false));
  };

  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirectUri = "https://cykea.com/share-to-linkedin";

  const handleLinkedInAuth = () => {
    // Redirect the user to the LinkedIn's authentication page
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=course&scope=r_liteprofile%20w_member_social`;
  };

  return (
    <div>
      <div className="course__overview">
        <span className="course__overview-heading-and-dropDown--icon">
          <p className="single__course-course--title">{course?.name}</p>
          <span className="course__details-CTAs">
            <button
              className={
                showCourseOverview
                  ? "course__content-active--tab"
                  : "course__content-inactive--tab"
              }
              onClick={toggleCourseOverview}
              style={{ marginLeft: "0rem" }}
            >
              Overview
            </button>
            <button
              className={
                showCourseContent
                  ? "course__content-CTA--mobile-active"
                  : "course__content-CTA--mobile-inactive"
              }
              onClick={toggleCourseContent}
            >
              Content
            </button>
            <button
              className={
                showCourseTranscript
                  ? "course__content-active--tab"
                  : "course__content-inactive--tab"
              }
              onClick={toggleCourseTranscript}
            >
              Transcript
            </button>
            {certificate ? (
              <button
                className="download__available-cert--CTA"
                onClick={() => {
                  navigate("/panel/download-certificate");
                }}
              >
                Certificate
              </button>
            ) : null}
            <span className="share__options-and-CTA">
              <button
                className="course__detail-CTA course__share-CTA"
                onClick={() => setShowShareOptions(!showShareOptions)}
              >
                Share
              </button>
              {showShareOptions ? (
                <span className="share__pop--up course__share-options--span">
                  <p className="share__heading">Share</p>

                  <div className="social__share--icons">
                    <TwitterShareButton
                      url={`https://www.cykea.com`}
                      title={`I’m currently learning about ${course?.name} on @cykea_academy! Join me at https://www.cykea.com`}
                      // via={`I’m currently learning about ${course?.name} on CyKea! Join me at www.cykea.com `}
                      hashtags={[
                        "cykea",
                        "cybersecurity",
                        "infosec",
                        "cyberlearner",
                      ]}
                      description={`I’m currently learning about ${course?.name} on CyKea! Join me at www.cykea.com `}
                    >
                      <span className="logo_link--span">
                        <TwitterShareIcon borderRadius={3} size={22} />{" "}
                        <p className="share__icon--text">Twitter</p>
                      </span>
                    </TwitterShareButton>

                    <button
                      onClick={handleLinkedInAuth}
                      className="linkedin__share-button"
                    >
                      <LinkedinShareIcon borderRadius={3} size={22} />
                      <p className="linkedin__share-button--text">Linkedin</p>
                    </button>
                    <CopyToClipboard
                      text={`https://www.cykea.com`}
                      onCopy={() => alert("Copied!")}
                    >
                      <span
                        className="logo_link--span"
                        style={{ cursor: "pointer" }}
                      >
                        <LinkIcon />{" "}
                        <p className="share__icon--text">Copy Link</p>
                      </span>
                    </CopyToClipboard>
                  </div>
                </span>
              ) : null}
            </span>
          </span>
        </span>
        {showCourseOverview ? (
          <div className="course__text-details--container">
            <p className="desc">Description</p>
            <p className="desc__text">{course?.description}</p>
            <p className="instructor">Instructor</p>
            <span className="instructor__details">
              <img
                src={fezzantImg}
                alt="instructor-img"
                className="instructor__img"
              />
              <span>
                {/* <p className="instructor__name">{course?.instructor}</p>{" "} */}
                <p className="instructor__name">Aliyu G Yisa</p>{" "}
                <a
                  href="https://www.linkedin.com/in/aligorithm/"
                  target="blank"
                >
                  {/* <a
                  href="https://www.linkedin.com/company/fezzant/"
                  target="blank"
                > */}
                  <LinkedinIcon className="instructor__linkedin" />
                </a>
                <a href="https://twitter.com/_aligorithm" target="blank">
                  <TwitterIcon />
                </a>
              </span>
              <p className="instrctr__title">CEO Fezzant</p>
            </span>
          </div>
        ) : null}
        {showCourseTranscript ? (
          <div className="course__text-details--container">
            <p className="desc">Video Title: {currentVideo?.title}</p>
            <p className="desc__text" style={{ border: "none" }}>
              {currentVideo?.transcript}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CourseOverview;
