import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/mobile-menu-icon.svg";
import MobileNav from "../MobileNav/MobileNav";
import axios from "axios";
import { api } from "../..";
// import Loading from "../Loading/Loading.component";
// import { ReactComponent as XIcon } from "../../assets/icons/x-svg.svg";

import "./forgotPassword.css";

const ForgotPassword = ({ showForgotPassword, setShowForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [mssg, setMssg] = useState("");

  const sendPWordResetMails = () => {
    api
      .post("/api/v1/user/forgotpassword", {
        email,
      })
      .then((res) => {
        setSuccess(true);
        setEmail("");
        setMssg(
          "Password reset email sent successfully! Check your email for further steps."
        );
      })
      .catch((error) => {
        setMssg(error.response?.data.message);
        setSuccess(false);
      });
  };

  return (
    <>
      <div className="payment__options--div">
        <div
          className="expired__sub--overlay"
          style={{ width: "100%", position: "fixed" }}
        >
          {openMobileNav ? (
            <MobileNav
              openMobileNav={openMobileNav}
              setOpenMobileNav={setOpenMobileNav}
            />
          ) : null}
          <div
            className="expired__sub--container expired--two"
            style={{ width: "51rem", alignItems: "flex-start" }}
          >
            <span className="forgot__pword-cykeaLogo-and-back">
              <CykeaLogo className="cykea__landing-logo" />
              <MenuIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenMobileNav(!openMobileNav)}
                className="mobile__menu-CTA"
              />
            </span>
            <span className="forgot__password-and-close">
              <span className="forgot__password-heading-and-close--CTA">
                <p className="forgot__pword">Forgot Password</p>
              </span>
              <CloseIcon
                className="close__forgot-pword"
                onClick={() => setShowForgotPassword(!showForgotPassword)}
                style={{ cursor: "pointer" }}
              />
            </span>
            {/* <p className="reset__pword-sub--text">
            Enter your email address to reset your password
          </p> */}

            <label
              htmlFor="forgot__pword-email"
              className="forgot__pWord-label"
            >
              Email Address
            </label>
            <input
              type="email"
              value={email}
              className="forgot__pword-email"
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginBottom: success ? "2rem" : "3.5rem" }}
            />
            <p
              className="success__message"
              style={{ color: success ? "#03a200" : "#FF5858" }}
            >
              {mssg}
            </p>
            <button
              className="send__reset-email--CTA"
              disabled={email ? false : true}
              onClick={sendPWordResetMails}
            >
              Send Reset Email
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
