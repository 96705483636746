import { ReactComponent as CykeaPurpleLogo } from "../../assets/icons/cykea-purple-logo.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/footer-twitter-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram-footer-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin-footer-icon.svg";
import { ReactComponent as CybarikIcon } from "../../assets/icons/cybrk-logo.svg";
import { ReactComponent as CykeaNewLogoPurple } from "../../assets/icons/cykea-new-logo-purple.svg";
import { ReactComponent as CykeaBetaLogoPurple } from "../../assets/icons/cykea-beta-purple-logo.svg";
import { ReactComponent as ByFezzant } from "../../assets/icons/by-fezzant.svg";
import footerBg from "../../assets/images/footer-bg.png";
import { useNavigate } from "react-router-dom";

import "./footer.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer__main-container">
      <div className="footer__content-container">
        <div className="motto-and-logo--container">
          <p className="footer__text">
            Take your Cybersecurity
            <br />
            Career to the Skies!
          </p>
          <span className="partnering__text-and-logo--span">
            {/* <p className="partnering">Partnering</p> */}
            {/* <CybarikIcon /> */}
            {/* <img
              src={require("../../assets/images/cybarik.png")}
              alt="cybarik-logo"
            /> */}
          </span>

          {/* {window.location.href.startsWith("https://beta.cykea.com/") ? (
            <CykeaBetaLogoPurple />
          ) : (
            <CykeaNewLogoPurple className="cykea__landing-logo" />
          )} */}
          <p
            className="terms__of__use-policy"
            onClick={() => navigate("/privacy-policy")}
          >
            Cykea’s Terms of use & Privacy policy
          </p>
        </div>
        <div className="footer__links-container">
          <span className="contact__channels-and-links">
            {/* <span className="company__links-span">
              <p className="company__heading">Company</p>
              <Link to="/faq">
                <p className="footer__link">FAQs</p>
              </Link>
              <Link to="/terms-of-use">
                <p className="footer__link">Terms of service</p>
              </Link>
              <Link to="/privacy-policy">
                <p className="footer__link">Privacy Policy</p>
              </Link>
            </span> */}
            <span className="contact__channels-span">
              <p className="contact__channels-heading">Keep in touch</p>
              <p className="footer__link">hello@cykea.com</p>
              <p className="footer__link">+44 774 668 9130</p>

              <span className="footer__social-icons">
                <a
                  href="https://twitter.com/cykea_academy?s=21&t=zcjpC55PxvXqgafRZVMN1A"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit cykea's twitter page"
                >
                  <TwitterIcon className="footer__social-icon" />
                </a>
                <a
                  href="https://instagram.com/cykea_academy?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit cykea's instagram page"
                >
                  <InstagramIcon className="footer__social-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/cykea"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit cykea's linkedin page"
                >
                  <LinkedinIcon className="footer__social-icon" />
                </a>
              </span>
            </span>
            <span className="office__address">
              <p className="address__heading">Office</p>
              <p className="footer__link footer__address-text">
                International House, 61 Mosley Street,
                <br /> Manchester, United Kingdom.
              </p>
              <a
                href="https://www.fezzant.com"
                target="_blank"
                rel="noreferrer"
              >
                <ByFezzant className="footer__logo" />
              </a>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
