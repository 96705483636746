import { useState, useRef, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import regImg from "../../assets/images/new-reg-img.png";
import regBgImage from "../../assets/images/reg-bg-img.png";
import { ReactComponent as EyeLogo } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeOffLogo } from "../../assets/icons/eye-off.svg";
import { ReactComponent as NewCykeaBetaLogoBlack } from "../../assets/icons/cykea-beta-logo.svg";
import { ReactComponent as NewCykeaLogoBlack } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { usePaystackPayment } from "react-paystack";
import { ReactComponent as LockLogo } from "../../assets/icons/lock-icon.svg";
import { useNavigate, Link } from "react-router-dom";
import { api } from "../..";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setShowFreeBanner } from "../../redux/course/courseActions";
import "./register.css";
import Loader from "../Loader/Loader";

const PWD_REGEX = /^.{8,24}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGUSERURL = "api/v1/user/createuser";

const Register = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");

  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [userId, setUserId] = useState("");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const errRef = useRef();

  const token = localStorage.getItem("token");
  const courseId = localStorage.getItem("courseId");
  const coursePrice = localStorage.getItem("coursePrice");

  const dispatch = useDispatch();

  const publicKey = process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY;

  const componentProps = {
    email,
    publicKey,
    amount: coursePrice * 100,
    metadata: {
      customer_id: courseId,
      first_name: firstName,
      user_id: userId,
    },
  };

  const navigate = useNavigate();

  const theId = useSelector((state) => state.course.courseId);

  const togglePasswordType = () => {
    setShowPassword(!showPassword);
    setType(showPassword ? "text" : "password");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const loginAfterReg = () => {
    api
      .post("/api/v1/user/login", {
        email: email,
        password: pwd,
      })
      .then((res) => {
        localStorage.setItem("token", res.data?.token);
        getLoggedInUser(res.data?.token);
        localStorage.setItem("userIsLoggedIn", true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setShowPassword("");
        dispatch(setShowFreeBanner(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLoggedInUser = () => {
    api
      .get("/api/v1/user/loggedInUser", {})
      .then((res) => {
        setUserId(res.data?.user.id);
        localStorage.setItem("userEmail", res.data.user.email);
        localStorage.setItem("firstName", res.data.user.firstName);
        localStorage.setItem("userId", res.data.user.id);
      })
      .catch((error) => console.log(error));
  };

  const makePayment = usePaystackPayment(componentProps);

  // const onSuccess = () => {
  //   alert("Payment Successfull!");
  //   console.log(new Date().toLocaleTimeString());
  //   navigate("/panel/courses");
  // };

  // const onClose = () => {
  //   alert("Are you sure you want to leave your cyberspace vulnerable?");
  // };

  const initiatePayment = (token) => {
    axios
      .post(
        "/api/v1/payment/payforcourse",
        { courseId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        // window.open(`${res.data.session}`);
        localStorage.removeItem("courseId");
        // componentProps.metadata.user_id = localStorage.getItem("userId");
        // makePayment(onSuccess, onClose);
        // window.location.href = `${res.data.session}`;
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
      });
  };

  ///////////////// STRIPE PAYMENT PROCESS

  // const initiatePayment = (token) => {
  //   axios
  //     .post(
  //       "/api/v1/payment/payforcourse",
  //       { courseId },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     )
  //     .then((res) => {
  //       console.log({ res });
  //       // window.open(`${res.data.session}`);
  //       localStorage.removeItem("courseId");
  //       window.location.href = `${res.data.session}`;
  //     })
  //     .catch((err) => console.log(err));

  //   console.log({ token });
  // };

  const onSubmit = async (data, e) => {
    setLoading(true);
    e.preventDefault();

    api
      .post(REGUSERURL, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: pwd,
      })
      .then((res) => {
        setSuccess(true);
        toast.success("Account Created Successfully!");
        loginAfterReg();
        // mixpanel.track("CREATE-ACCOUNT-SUCCESS");

        setTimeout(() => {
          // initiatePayment(res.data.token);
          navigate(`/panel/courses/single-course/${courseId}`);
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        if (!error?.response) {
          setErrMsg("No Server Response");
          toast.error(error.response?.data.message);
        } else if (error.response?.status === 400) {
          setErrMsg("Account Already Exists.");
          toast.error(error.response?.data.message);
        } else {
          setErrMsg("Registration Failed");
          toast.error(error.response?.data.message);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);

  return (
    <>
      {loading ? <Loader loaderText="Loading..." /> : null}

      <div className="createCompLogin__content--container">
        <div className="login__form--container">
          <NewCykeaLogoBlack className="login__cykea--logo" />

          <div className="form-and-text__container">
            <span
              className="login__form--texts"
              onClick={() => (localStorage.token ? navigate("/panel") : "")}
            >
              <h2 className="login__formheading">Sign Up</h2>

              <p
                className={errorMessage ? "errorMessage" : "offScreen"}
                aria-live="assertive"
              >
                {errorMessage}
              </p>
            </span>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="createCompLogin__form"
            >
              <label htmlFor="firstName" className="login__email--label">
                First Name
              </label>
              <div className="createCompLogin__email-and-icon">
                <input
                  type="text"
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                  id="firstName"
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  // {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <p
                    style={{
                      color: "#CC2442",
                      marginTop: "1rem",
                    }}
                  >
                    First name is required
                  </p>
                )}
              </div>
              <label htmlFor="LastName" className="login__email--label">
                Last Name
              </label>
              <div className="createCompLogin__email-and-icon">
                <input
                  type="text"
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                  id="lastName"
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                  aria-describedby="uidnote"
                  // {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <p
                    style={{
                      color: "#CC2442",
                      marginTop: "1rem",
                    }}
                  >
                    Last name is required
                  </p>
                )}
              </div>
              <label htmlFor="email" className="login__email--label">
                Email Address
              </label>
              <div className="createCompLogin__email-and-icon">
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  aria-describedby="uidnote"
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                  // {...register("email", { required: true })}
                />
                {errors.email && (
                  <p
                    style={{
                      color: "#CC2442",
                      marginTop: "1rem",
                    }}
                  >
                    Email is required
                  </p>
                )}
              </div>

              <label htmlFor="password" className="login__email--label">
                Password
              </label>
              <div className="createCompLogin__password-and-icons">
                <input
                  type={type}
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  autoComplete="false"
                  required
                  aria-invalid={validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                />
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !validPwd ? "instruction" : "offScreen"
                  }
                >
                  {/* 8 to 24 characters.
                    <br /> */}
                  Must be alphanumeric and at least 8 characters long.
                </p>

                {type === "password" ? (
                  <EyeLogo
                    className="login__eye-icon"
                    onClick={togglePasswordType}
                  />
                ) : (
                  <EyeOffLogo
                    className="login__eye-icon"
                    onClick={togglePasswordType}
                  />
                )}
              </div>
              <label htmlFor="confirm_pwd" className="login__email--label">
                Confirm Password
              </label>
              <div className="createCompLogin__password-and-icons">
                <input
                  type={type}
                  id="confirm_pwd"
                  value={matchPwd}
                  onChange={(e) => setMatchPwd(e.target.value)}
                  autoComplete="false"
                  required
                  aria-invalid={validMatch ? "false" : "true"}
                  aria-describedby="confirmNote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  className={
                    errors.email ? "email--input" : "login__email--input"
                  }
                />
                <p
                  id="confirmnote"
                  className={!validMatch ? "instruction" : "offScreen"}
                >
                  Must match the first password input field.
                </p>

                {type === "password" ? (
                  <EyeLogo
                    className="login__eye-icon"
                    onClick={togglePasswordType}
                  />
                ) : (
                  <EyeOffLogo
                    className="login__eye-icon"
                    onClick={togglePasswordType}
                  />
                )}
              </div>

              <button
                disabled={!validPwd || !validMatch ? true : false}
                className="login__submit--CTA"
              >
                Sign Up
              </button>
              <p className="login__dont-have--account">
                Already have an account?{" "}
                <span
                  style={{
                    color: "#655d8a",
                    marginLeft: ".3rem",
                    fontWeight: "600",
                  }}
                >
                  <a href="/login" className="sign__up-link">
                    Login
                  </a>
                </span>
              </p>
            </form>
          </div>
        </div>
        <div
          className="login__image--container"
          style={{ backgroundImage: `url(${regBgImage})` }}
        >
          <img src={regImg} alt="loginimage" className="login__image--box" />
        </div>
      </div>
    </>
  );
};

export default Register;
