import { ReactComponent as FreeBenefitIcon } from "../../assets/icons/free-benefit-icon.svg";
import { useDispatch } from "react-redux";
import { setShowFreeBanner } from "../../redux/course/courseActions";
import "./courseDetailBanner.css";

const CourseDetailBanner = () => {
  const dispatch = useDispatch();
  return (
    <div className="course__detail-banner">
      <div className="course__detail-container">
        <div className="free__text-box">
          <p className="free__text">Free</p>
        </div>
        <div className="free__course-benefits">
          <ui>
            <li className="free__course-benefit">
              <FreeBenefitIcon className="free__benefit-icon" />
              <p className="benefit__text">Practical Tasks</p>
            </li>
            <li className="free__course-benefit">
              <FreeBenefitIcon className="free__benefit-icon" />{" "}
              <p className="benefit__text">Quiz</p>
            </li>
            <li className="free__course-benefit">
              <FreeBenefitIcon className="free__benefit-icon" />{" "}
              <p className="benefit__text">Learning check & Assessment</p>
            </li>
            <li className="free__course-benefit">
              <FreeBenefitIcon className="free__benefit-icon" />{" "}
              <p className="benefit__text">Certification</p>
            </li>
            <li className="free__course-benefit">
              <FreeBenefitIcon className="free__benefit-icon" />{" "}
              <p className="benefit__text">Internship Opportunity</p>
            </li>
          </ui>
          <button
            className="enroll__for-free--CTA"
            onClick={() => dispatch(setShowFreeBanner(false))}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailBanner;
