import { courseActionTypes } from "./courseActionTypes";

export const storeCourse = (course) => ({
  type: courseActionTypes.STORE_COURSE,
  payload: course,
});

export const storeCourseId = (courseId) => ({
  type: courseActionTypes.STORE__COURSE__ID,
  payload: courseId,
});

export const setShowCourseOverview = (status) => ({
  type: courseActionTypes.SHOW_COURSE_OVERVIEW,
  payload: status,
});

export const setShowCourseContent = (status) => ({
  type: courseActionTypes.SHOW_COURSE_CONTENT,
  payload: status,
});

export const setShowSideCourseContent = (status) => ({
  type: courseActionTypes.SHOW_SIDE_COURSE_CONTENT,
  payload: status,
});

export const setShowCourseTranscript = (status) => ({
  type: courseActionTypes.SHOW_COURSE_TRANSCRIPT,
  payload: status,
});

export const setShowPassed = (status) => ({
  type: courseActionTypes.SHOW_PASSED,
  payload: status,
});

export const setShowFailed = (status) => ({
  type: courseActionTypes.SHOW_FAILED,
  payload: status,
});

export const setShowPassedPracticalQ = (status) => ({
  type: courseActionTypes.SHOW_PASSED_PRACTICAL_QUESTION,
  payload: status,
});

export const setShowPracticalQustion = (status) => ({
  type: courseActionTypes.SHOW_PRACTICAL_QUESTION,
  payload: status,
});

export const setShowQuiz = (status) => ({
  type: courseActionTypes.SHOW_QUIZ,
  payload: status,
});

export const setShowVideoPlayer = (status) => ({
  type: courseActionTypes.SHOW_VIDEO_PLAYER,
  payload: status,
});

export const setShowOverlay = (status) => ({
  type: courseActionTypes.SHOW_OVERLAY,
  payload: status,
});

export const setPassedPracticalQuestion = (status) => ({
  type: courseActionTypes.PASSED_PRACTICAL_QUESTION,
  payload: status,
});

export const setShowCertificate = (status) => ({
  type: courseActionTypes.SHOW_CERTIFICATE,
  payload: status,
});

export const storeCertificate = (certificate) => ({
  type: courseActionTypes.STORE_CERTIFICATE,
  payload: certificate,
});

export const setShowFreeBanner = (status) => ({
  type: courseActionTypes.SHOW_FREE_BANNER,
  payload: status,
});

export const updateVideoUserState = (moduleId, videoId, newUserState) => {
  return {
    type: courseActionTypes.UPDATE_VIDEO_USER_STATE,
    payload: {
      moduleId,
      videoId,
      newUserState,
    },
  };
};

export const switchPracticalQuestions = (status) => ({
  type: courseActionTypes.SWITCH_PRACTICAL_QUESTION,
  payload: status,
});
