import { videoActionTypes } from "./videoActionTypes";

const INITIAL_STATE = {
  videoUrl: {},
  subUrl: null,
  currentVideo: null,
  videoDuration: null,
  nextVideoIndex: 0,
  completedVideos: 0,
  playerLoading: false,
};

export const videoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case videoActionTypes.SET_CURRENT_VIDEO:
      return {
        ...state,
        currentVideo: action.payload,
      };

    case videoActionTypes.SET_VIDEO_URL:
      return {
        ...state,
        videoUrl: action.payload,
      };
    case videoActionTypes.SET_SUB_URL:
      return {
        ...state,
        subUrl: action.payload,
      };

    case videoActionTypes.SET_VIDEO_DURATION:
      return {
        ...state,
        videoDuration: action.payload,
      };

    case videoActionTypes.SET_NEXT_VIDEO_INDEX:
      return {
        ...state,
        nextVideoIndex: action.payload,
      };

    case videoActionTypes.SET_PLAYER_LOADING:
      return {
        ...state,
        playerLoading: action.payload,
      };
    case videoActionTypes.INCREASE_VIDEO_INDEX:
      return {
        ...state,
        nextVideoIndex: state.nextVideoIndex + 1,
      };
    case videoActionTypes.DECREASE_VIDEO_INDEX:
      return {
        ...state,
        nextVideoIndex: state.nextVideoIndex - 1,
      };

    case videoActionTypes.UPDATE_COMPLETED_VIDEOS:
      return {
        ...state,
        completedVideos: action.payload,
      };

    default:
      return state;
  }
};
