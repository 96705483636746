import { useNavigate } from "react-router-dom";
import sectionFourImg from "../../assets/images/cy-section-four-img.png";
import "./sectionFour.css";

const SectionFour = () => {
  const navigate = useNavigate();

  return (
    <div className="section__four-container">
      <div className="sectionFour__text-container">
        <p className="sectionFour__tag-text">IT TAKES A VILLAGE</p>
        <h2 className="section__four-main--text">
          Join the Cykea Learners Community
        </h2>
        <p className="section__four-sub--text">
          It takes a village to raise a child. It takes a community to raise a
          cybersecurity pro! Join your peers for that extra “ginger”.
        </p>
        <button
          className="sectionFour__CTA tab__CTA"
          onClick={() => navigate("/register")}
        >
          Join Community!
        </button>
      </div>

      <img src={sectionFourImg} alt="community" className="section__four-img" />

      <button
        className="sectionFour__CTA mobile__CTA"
        onClick={() => navigate("/register")}
      >
        Join Community!
      </button>
    </div>
  );
};

export default SectionFour;
