import { ReactComponent as LoaderComponent } from "../../assets/icons/loader-component.svg";
import "./loader.css";

const Loader = ({ loaderText, content }) => {
  return (
    <div className={content ? "content__loader" : "loader"}>
      <div className="loader__circle">
        <div
          className={
            content ? "content__loader-inner--circle" : "loader__inner-circle"
          }
        />
      </div>
      <p
        className={
          content
            ? "content__loader-custom__loader--text"
            : "custom__loader-text"
        }
      >
        {loaderText ? loaderText : "Loading..."}
      </p>
    </div>
  );
};

export default Loader;
