import { ReactComponent as FreeBenefitIcon } from "../../../assets/icons/free-benefit-icon.svg";
import { useNavigate } from "react-router-dom";
import "./pricing.css";

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div className="ethical__hacking-pricing--container">
      <h1 className="ethical__hacking-pricing--heading">Pricing</h1>
      <div className="ethical__hacking-content">
        <div className="ethical__hacking-pricing--box">
          <span className="ethical__hacking-price--text-span">
            <p className="price__figure">£500</p>
          </span>
          <div className="ethical__hacking-price--benefits">
            <span className="ethical__hacking-benefit--span">
              <FreeBenefitIcon className="free__benefit-icon" />
              <p className="ethical__hacking-price--benefit">Live Sessions</p>
            </span>
            <span className="ethical__hacking-benefit--span">
              <FreeBenefitIcon className="free__benefit-icon" />
              <p className="ethical__hacking-price--benefit">
                Practical / Hands-on Labs
              </p>
            </span>
            <span className="ethical__hacking-benefit--span">
              <FreeBenefitIcon className="free__benefit-icon" />
              <p className="ethical__hacking-price--benefit">
                Mentorship from Industry Experts
              </p>
            </span>
            <span className="ethical__hacking-benefit--span">
              <FreeBenefitIcon className="free__benefit-icon" />
              <p className="ethical__hacking-price--benefit">
                Career Development
              </p>
            </span>
            <span className="ethical__hacking-benefit--span">
              <FreeBenefitIcon className="free__benefit-icon" />
              <p className="ethical__hacking-price--benefit">
                Certification Preparation
              </p>
            </span>
          </div>
        </div>
        <span className="ethical__hacking-CTA--span">
          <p className="limited__slot-text">Limited slots available!</p>
          <button
            className="pricing__enrollment-CTA"
            onClick={() => navigate("/ethical-hacking-enrollment")}
          >
            Enroll Now!
          </button>
        </span>
      </div>
    </div>
  );
};

export default Pricing;
