import ProfileDataForm from "../ProfileDataForm/ProfileDataForm";
import { useDispatch } from "react-redux";
import { setShowVideoPlayer } from "../../redux/course/courseActions";
import { useEffect } from "react";
import "./profile.css";
import Loader from "../Loader/Loader";
import { useState } from "react";

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowVideoPlayer(true));
  });

  return (
    <div className="profile__container">
      <ProfileDataForm />
    </div>
  );
};

export default Profile;
