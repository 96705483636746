import FaqAccordion from "../../FAQAccordion/FaqAccordion";
import { ethicalHackingContent } from "../../../faqsContent";
import { v4 as uuidv4 } from "uuid";
import "./faq.css";

const FAQ = () => {
  return (
    <div className="ethical__faq-container">
      <div className="ethical__faq-content">
        <h2 className="ethical__faq-heading">FAQs</h2>
        <div className="ethical__accordion-container">
          {ethicalHackingContent.map((content) => (
            <FaqAccordion
              key={uuidv4()}
              question={content.question}
              answer={content.answer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
