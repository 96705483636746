import { useNavigate } from "react-router-dom";

import "./course.css";

const Course = ({ courseId, name, status }) => {
  const navigate = useNavigate();

  //   const { authUserData } = useAuthUserContext();

  const url = "/panel/courses/single-course/";

  const freeTrial = true;

  return (
    <div
      className="series__container"
      // style={{
      //   backgroundImage: `url(${image})`,
      //   backgroundSize: "cover",
      // }}
      onClick={() => {
        navigate(`${url}${courseId}`);
      }}
    >
      {status === "pending" ? (
        <div className="pending__purchase-overlay">
          <p className="pending__purchase-text">
            We are currently confirming your payment and the course will be
            available to you as soon as it is complete!
          </p>
        </div>
      ) : (
        <p className="course__title">{name}</p>
      )}
    </div>
  );
};

export default Course;
