import { useMemo, useState, memo, useCallback } from "react";
import { ReactComponent as UpArrowIcon } from "../../assets/icons/opened-arrow.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/down-arrow-icon.svg";
import VideoContent from "../VideoContent/VideoContent";
import { v4 as uuidv4 } from "uuid";
import PracticalTaskItem from "../PracticalTaskItem/PracticalTaskItem";
import QuizItem from "../QuizItem/QuizItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModule } from "../../redux/module/moduleActions";
import { api } from "../..";
import "./accordion.css";

const Accordion = memo(({ module, moduleIndex }) => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const [addPracticalQ, setAddPracticalQ] = useState(false);

  const [practicalQuestionDone, setPracticalQuestionDone] = useState(
    module.practicalTaskUserState
  );

  const course = useSelector((state) => state.course.course);
  const dispatch = useDispatch();

  const currentModule = JSON.parse(localStorage.getItem("moduleDetails"));
  const id = currentModule ? currentModule.id : course?.modules[0].id;

  // useEffect(() => {
  //   getcompletedvideos();
  // }, [currentVideo]);

  const getModuleState = module.videos?.filter((video, index) => {
    return video.userState === "completed";
  });

  const checkPracticalQuestionDone = () => {
    api
      .get(`/api/v1/practicalQuestion/passedAllModuleTasks/${id}`)
      .then((res) => {
        setPracticalQuestionDone(res.data.pass);
      })
      .catch((err) => console.log(err));
  };

  // const storeCurrentModule = () => {
  //   dispatch(setModule(module));
  //   localStorage.setItem("moduleDetails", JSON.stringify(module));
  //   localStorage.setItem("moduleIndex", moduleIndex);
  // };

  useEffect(() => {
    if (currentModule?.id !== module?.id) {
      setOpenAccordion(false);
    }
  }, [currentModule?.id, module]);

  useEffect(() => {
    checkPracticalQuestionDone();
  }, [openAccordion]);

  return (
    <>
      <div
        className="content__accordion-parent--div"
        onClick={() => {
          setOpenAccordion(!openAccordion);

          // storeCurrentModule();
        }}
      >
        <span className="content__title-and-arrowCTA">
          <p className="content__title">{module.name}</p>
          {openAccordion ? <UpArrowIcon /> : <DownArrowIcon />}
        </span>
        <span className="module__duration-and-progress--span">
          <p className="course__content-duration">{module.duration} Mins</p>
          <span className="module__progress--span">
            <p className="done__progress">{getModuleState.length}</p>/{" "}
            <p className="overall__content">{module.videos.length}</p>
          </span>
        </span>
      </div>
      {openAccordion ? (
        <div className="accordion__children-div--container">
          {module.videos?.map((video, index) => (
            <VideoContent
              key={uuidv4()}
              index={index}
              video={video}
              module={module}
              moduleIndex={moduleIndex}
            />
          ))}
          <PracticalTaskItem
            key={uuidv4()}
            state={practicalQuestionDone}
            module={module}
            moduleIndex={moduleIndex}
          />
          {moduleIndex === course.modules.length - 1 ? (
            <QuizItem
              key={uuidv4()}
              index={moduleIndex}
              courseId={course.id}
              setAddPracticalQ={setAddPracticalQ}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}, []);
export default Accordion;
