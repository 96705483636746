import { ReactComponent as NotFoundIcon } from "../../assets/icons/404-icon.svg";
import { useNavigate } from "react-router-dom";
import "./notFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="not__found-container">
      <div className="not__found-content">
        <p className="not__found-text">ERROR 404</p>
        <NotFoundIcon />
        <p className="something__wrong">Something Went Wrong!</p>
        <button className="back-to-homepage__CTA" onClick={() => navigate("/")}>
          Take me Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
