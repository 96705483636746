import { ReactComponent as CompleteTaskIcon } from "../../assets/icons/complete-task-icon.svg";
// import { useCertificateContext } from "../../contexts/CertificateContext";
// import { useModuleContext } from "../../contexts/ModuleContext";
import { useEffect } from "react";
import { useModuleIndexContext } from "../../contexts/ModuleContext";
import { useModuleContext } from "../../contexts/ModuleContext";

import "./passed.css";

const Passed = ({ setShowPassed, setShowPracticalQustion, setShowQuiz }) => {
  // const { moduleIndex, setModuleIndex } = useModuleIndexContext();
  const { moduleDetails, setModuleDetails } = useModuleContext();

  // console.log({ moduleIndex });

  const continueToPractQ = () => {
    setShowPassed(false);
    setShowPracticalQustion(true);
  };

  const retryQuiz = () => {
    setShowPassed(false);
    setShowQuiz(true);
  };

  const moduleIndex = JSON.parse(localStorage.getItem("moduleIndex"));

  return (
    <div className="videoPlayer__container--two">
      <div className="passed__practical-question--container">
        {/* <div className="passed__component-content"> */}
        <CompleteTaskIcon className="completed__task-icon" />
        <p className="completed__task-text">Congrats! Module Completed!</p>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Passed;
