import { useState } from "react";
import { ReactComponent as OpenedIcon } from "../../assets/icons/faq-opened-icon.svg";
import { ReactComponent as ClosedIcon } from "../../assets/icons/faq-closed-icon.svg";
import "./faqAccordion.css";

const FaqAccordion = ({ question, answer }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="faqAccordion__container">
      <span
        className="faq__accordion-question--span"
        style={{ cursor: "pointer" }}
        onClick={() => setOpened(!opened)}
      >
        <span className="faq__question-and-list--dot">
          {/* <div className="list__dot" /> */}
          <p
            className={
              opened
                ? "faq__question-text bold__question"
                : "faq__question-text"
            }
          >
            {question}
          </p>
        </span>
        {opened ? (
          <OpenedIcon
            style={{ cursor: "pointer" }}
            className="faqs__accordion-open--icon"
          />
        ) : (
          <ClosedIcon
            // onClick={() => setOpened(!opened)}
            style={{ cursor: "pointer" }}
            className="faqs__accordion-closed--icon"
          />
        )}
      </span>
      {opened ? (
        <div className="faqs__question-answer--container">
          <p className="faqs__question-answer--text">{answer}</p>
        </div>
      ) : null}
    </div>
  );
};
export default FaqAccordion;
