import React, { useState, useContext, createContext } from "react";

const ModuleContext = createContext();
const ModduleIndexContext = createContext();

export const useModuleContext = () => {
  return useContext(ModuleContext);
};

export const useModuleIndexContext = () => {
  return useContext(ModduleIndexContext);
};

export const ModuleProvider = ({ children }) => {
  const [moduleDetails, setModuleDetails] = useState({});
  const [moduleIndex, setModuleIndex] = useState();

  return (
    <ModuleContext.Provider value={{ moduleDetails, setModuleDetails }}>
      <ModduleIndexContext.Provider value={{ moduleIndex, setModuleIndex }}>
        {children}
      </ModduleIndexContext.Provider>
    </ModuleContext.Provider>
  );
};
