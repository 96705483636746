// import { ReactComponent as BellIcon } from "../../assets/icons/bell-icon.svg";
// import { ReactComponent as MenuIcon } from "../../assets/icons/dashboard-menuIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
// import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon2.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/current-icon.svg";
// import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as LogoutIconTwo } from "../../assets/icons/logout-iconTwo.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-iconTwo.svg";
import { ReactComponent as WheelIcon } from "../../assets/icons/cy-access-icon.svg";
import { ReactComponent as AccessibilityIcon } from "../../assets/icons/cy-accessibility-icon.svg";
import { ReactComponent as Badge } from "../../assets/icons/available-cert-badge.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/icons/cy-feedback-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { useAuthUserDataContext } from "../../contexts/AuthUserDataContext";
import { ReactComponent as OurMissionIcon } from "../../assets/icons/our-mission-icon.svg";
import { useState } from "react";
import "./detailsBar.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const DetailsBar = ({
  pathname,
  adminSideideBarLinks,
  logOutPrompt,
  setLogOutPropmt,
  navOpened,
  setNavOpened,
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [openDropdown, setOpenDropDown] = useState(false);

  const navigate = useNavigate();

  const certificate = useSelector((state) => state.course.certificate);

  const { authUserData } = useAuthUserDataContext();

  let pathTitle = "";

  switch (pathname) {
    case "/panel/courses" || "/panel/single-course":
      pathTitle = "Courses";
      break;
    case "/panel/single-course":
      pathTitle = "Courses";
      break;
    case "/panel/profile":
      pathTitle = "Profile";
      break;

    default:
      pathTitle = "";
  }

  const toggleMobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  // For getting username acronym

  const str = `${authUserData.user?.firstName.toUpperCase()} ${authUserData.user?.lastName.toUpperCase()}`;
  const matches = str.match(/\b(\w)/g);
  const acronym = matches.join("");

  ///////////////////// Open Tawk
  const handleChatButtonClick = () => {
    // Open the Tawk.to chat
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  };

  ////////////// Accessibility Panel Custom Trigger
  const accIcon = document.querySelector("._access-icon");

  const handleAccessibilityClick = () => {
    accIcon.dispatchEvent(new Event("click"));
  };

  const activateOurMission = () => {
    navigate("/panel/our-mission");
  };

  return (
    <div className="userDetails__Bar--container">
      {certificate ? (
        <div className="available__certificate-banner">
          <span className="certificate__available-and-badge--span">
            <p className="certificate__available-text">
              Your certificate is ready!
            </p>
            <Badge />
          </span>
          <button
            className="available__cert-banner--CTA"
            onClick={() => {
              navigate("/panel/download-certificate");
            }}
          >
            Download
          </button>
        </div>
      ) : null}
      <span className="userDetails__content--container">
        <MenuIcon
          className="show__menu--CTA"
          onClick={() => setNavOpened(!navOpened)}
        />
        {/* <p className="page__heading hide__mobile--title">{pathTitle}</p> */}
        {!certificate ? (
          <span
            className="auth__company--logo hide-for-desktop"
            onClick={activateOurMission}
            style={{
              border:
                pathname === "/panel/our-mission"
                  ? "1px solid rgba(154, 204, 251, 0.72)"
                  : "",
            }}
          >
            Our Mission <OurMissionIcon className="our__mission-icon" />
          </span>
        ) : null}

        <span className="currentLink-and-title hidden">
          <p className="page__heading">{pathTitle}</p>
        </span>
        <span className="notification-and-userName hide">
          <span className="hide">
            <AccessibilityIcon
              className="accessibility__icon"
              onClick={handleAccessibilityClick}
            />
            {/* <span className="accessibility__icon">
              <WheelIcon />
              <p className="accessibility__text">Accessibility</p>
            </span> */}
            <FeedbackIcon
              className="feedback__icon"
              id="trigger-button"
              onClick={handleChatButtonClick}
            />
            <p
              className="username__abbr"
              onClick={() => navigate("/panel/profile")}
            >
              {acronym}
            </p>

            {/* <p className="username">
              {authUserData?.user?.firstName} {authUserData?.user?.lastName}
            </p> */}

            {/* <DownArrow
              className={
                openDropdown
                  ? "downarrow__in__detailsBar--flipped"
                  : "downarrow__in__detailsBar"
              }
              onClick={() => setOpenDropDown(!openDropdown)}
            /> */}
            {openDropdown ? (
              <span className="logout-and-profile__link-CTAs">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "0.5px solid #F2F5F9",
                    paddingBottom: "2rem",
                  }}
                  onClick={() => {
                    setLogOutPropmt(true);
                  }}
                >
                  <LogoutIconTwo />{" "}
                  <p className="dropdown__prompts--text"> Log Out</p>
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                  onClick={() => {
                    navigate("/panel/profile");
                  }}
                >
                  <UserIcon />{" "}
                  <p className="dropdown__prompts--text">My Profile</p>
                </span>
              </span>
            ) : null}
          </span>
          {pathname === "/panel/profile" ? (
            <span
              className="logout__CTA-and-text"
              onClick={() => {
                setLogOutPropmt(true);
              }}
            >
              <LogoutIcon />
              <p className="logout__text">Log out</p>
            </span>
          ) : (
            ""
          )}
        </span>
      </span>
    </div>
  );
};

export default DetailsBar;
