import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as CykeaLogo } from "../../assets/icons/cy-mobile-menu-logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/mobile-menu-icon.svg";
import useAnalyticsEventTracker from "../../useAnalyticsEventsTracker";
import GA4React from "ga-4-react";

import "./navBar.css";

const NavBar = ({
  openMobileNav,
  setOpenMobileNav,
  whiteBg,
  pricing,
  color,
}) => {
  const [changeNavBg, setChangeNavBg] = useState(false);

  // console.log(window.location.href);

  const gaEventTracker = useAnalyticsEventTracker("NavBar");

  const navigate = useNavigate();

  const changeBackground = () => {
    if (window.scrollY >= 1000) {
      setChangeNavBg(true);
    } else {
      setChangeNavBg(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {localStorage.getItem("userIsLoggedIn") ? (
        <div
          className="mobile__continue-learning"
          style={{ position: changeNavBg ? "fixed" : "" }}
        >
          <button
            className="nav__login-CTA"
            style={{
              marginRight: "0rem",
            }}
            onClick={() => {
              navigate(
                "/panel/courses/single-course/affae8be-4208-43f0-b291-17875ef0bfd5"
              );
              gaEventTracker("Continue Learning");
            }}
          >
            Continue Learning
          </button>
        </div>
      ) : null}
      <div
        className={
          changeNavBg ? "navBar__container active__navbar" : "navBar__container"
        }
        style={{ paddingTop: changeNavBg ? "0" : "" }}
      >
        <Link to="/" aria-label="Cykea's Logo">
          <CykeaLogo className="cykea__landing-logo" />
        </Link>

        <span className="nav__links-span">
          {/* <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "features__link--active" : "white__bg"
          }
        >
          <span className="features-and-arrow">
            <p
              style={{
                color: changeNavBg ? "#949699" : color,
                marginRight: "1.5rem",
              }}
              >
              Features
            </p>
            {changeNavBg || whiteBg ? <GreyDownArrow /> : <DownArrow />}
          </span>
        </NavLink> */}
          <NavLink
            to="/skills-path"
            className={({ isActive }) =>
              isActive ? "features__link--active" : "white__bg"
            }
          >
            <p
              // style={{
              //   color: "#949699",
              // }}
              onClick={() => gaEventTracker("skills-path")}
            >
              Skills Path
            </p>
          </NavLink>
          {/* <NavLink
          to="/pricing"
          className={({ isActive }) =>
            isActive ? "features__link--active" : "white__bg"
          }
        >
          <p
            style={{
              color: "#949699",
            }}
            onClick={() => gaEventTracker("Pricing")}
          >
            Skills Path
          </p>
        </NavLink> */}
          <NavLink
            to="/resources"
            className={({ isActive }) =>
              isActive ? "features__link--active" : "white__bg"
            }
          >
            <p
              // style={{
              //   color: "#888888",
              // }}
              onClick={() => gaEventTracker("FAQs")}
            >
              Resources
            </p>
          </NavLink>
          <NavLink
            to="/faq"
            className={({ isActive }) =>
              isActive ? "features__link--active" : "white__bg"
            }
          >
            <p
              // style={{
              //   color: "#888888",
              // }}
              onClick={() => gaEventTracker("FAQs")}
            >
              FAQs
            </p>
          </NavLink>
        </span>
        <span className="nav__CTAs-span">
          {!localStorage.getItem("userIsLoggedIn") ? (
            <button
              className="nav__login-CTA"
              // style={{
              //   marginRight: "1.5rem",

              //   background: changeNavBg || whiteBg ? "#ffffff" : "",
              //   color: changeNavBg || whiteBg ? "#a734dd" : "",
              //   border:
              //     changeNavBg || whiteBg
              //       ? "0.5px solid #E3E3E3"
              //       : "0.5px solid #ffffff",
              // }}
              onClick={() => {
                navigate("/login");
                // gaEventTracker("login");
              }}
            >
              Log in
            </button>
          ) : (
            <button
              className="nav__login-CTA"
              style={{
                marginRight: "1.5rem",
                // width: "fit-content",
                // padding: "0 2rem",

                // background: changeNavBg || whiteBg ? "#ffffff" : "",
                // color: changeNavBg || whiteBg ? "#a734dd" : "",
                // border:
                //   changeNavBg || whiteBg
                //     ? "0.5px solid #E3E3E3"
                //     : "0.5px solid #ffffff",
              }}
              onClick={() => {
                navigate(
                  "/panel/courses/single-course/affae8be-4208-43f0-b291-17875ef0bfd5"
                );
                gaEventTracker("Continue Learning");
              }}
            >
              Continue Learning
            </button>
          )}
          <button
            className="nav__getStarted-CTA"
            // style={{
            //   background: changeNavBg || whiteBg ? "#A734DD" : "#ffffff",
            //   color: changeNavBg || whiteBg ? "#ffffff" : "",
            // }}
            onClick={() => {
              navigate("/register");
              // gaEventTracker("Get Started");

              GA4React.event({
                category: "Navbar",
                action: "Get Started CTA",
                label: "Test label", // optional
                // value: 99, // optional, must be a number
                // nonInteraction: true, // optional, true/false
                // transport: "xhr", // optional, beacon/xhr/image
              });
            }}
          >
            Get Started
          </button>
        </span>

        <MenuIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenMobileNav(!openMobileNav)}
          className="mobile__menu-CTA"
        />
      </div>
    </>
  );
};

export default NavBar;
