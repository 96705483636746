import { moduleActionTypes } from "./moduleActionTypes";

const INITIAL_STATE = {
  module: null,
  accordionState: false,
};

const moduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case moduleActionTypes.SET_MODULE:
      return {
        ...state,
        module: action.payload,
      };

    case moduleActionTypes.TOGGLE_MODULE_ACCORDION:
      return {
        ...state,
        accordionState: action.payload,
      };

    default:
      return state;
  }
};

export default moduleReducer;
