import { useEffect, useRef, useState } from "react";
import { ReactComponent as VideoOverlayIcon } from "../../assets/icons/video-overlay-icon.svg";
import { ReactComponent as LearningCheckIcon } from "../../assets/icons/learning-check-icon.svg";
import { ReactComponent as PracticalQuestionIcon } from "../../assets/icons/practical-question-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import testVideo from "../../assets/video/video.mp4";
// import enVtt from "../../assets/en.vtt";
import enVtt from "../../assets/85d39f1b-15cf-4a6e-bbae-96a35fb5a5d2_whatiscybersecurity.vtt";
import {
  setVideoUrl,
  setCurrentVideo,
  decreaseVideoIndex,
  setPlayerLoading,
  setSubUrl,
} from "../../redux/video/videoActions";
import {
  setShowOverlay,
  setShowVideoPlayer,
  setShowPracticalQustion,
  updateVideoUserState,
} from "../../redux/course/courseActions";
import FadeLoader from "react-spinners/FadeLoader";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause-icon.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-video-icon.svg";
import { api } from "../..";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// import "@videojs/themes/dist/city/index.css";
// import "@videojs/themes/dist/fantasy/index.css";
import "@videojs/themes/dist/forest/index.css";
import Loader from "../Loader/Loader";
// import "@videojs/themes/dist/sea/index.css";

export const VideoPlayerTwo = (props) => {
  const { themeName, course, playNextVideo, onVideoEnd } = props;

  const moduleDetails = JSON.parse(localStorage.getItem("moduleDetails"));
  let moduleIndex = JSON.parse(localStorage.getItem("moduleIndex"));

  const [videoList, setVideoList] = useState(
    course?.modules[0]?.videos || course.modules[moduleIndex]?.videos
  );

  const [subtitleUrl, setSubtitleUrl] = useState("");

  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const dispatch = useDispatch();

  const nextVideoIndex = useSelector((state) => state.video.nextVideoIndex);
  // const module = useSelector((state) => state.module.module);
  const currentVideo = useSelector((state) => state.video.currentVideo);
  const videoUrl = useSelector((state) => state.video.videoUrl);
  const subUrl = useSelector((state) => state.video.subUrl);
  const playerLoading = useSelector((state) => state.video.playerLoading);
  const showOverlay = useSelector((state) => state.course.showOverlay);
  const showVideoPlayer = useSelector((state) => state.course.showVideoPlayer);
  const showPracticalQustion = useSelector(
    (state) => state.course.showPassedPracticalQ
  );

  const currentVideoRef = useRef();
  currentVideoRef.current = currentVideo?.id;

  const moduleRef = useRef();
  moduleRef.current = moduleDetails.id;

  const subUrlRef = useRef();
  subUrlRef.current = subUrl;

  // const sss =
  //   "https://cykea-bucket.s3.eu-west-1.amazonaws.com/1aefa899-b77b-45ed-b927-0917c359a5d6_1.1+What+is+Cybersecurity.mp4_en.vtt";

  const options = {
    autoplay: play ? true : false,
    controls: true,
    responsive: true,
    // nativeTextTracks: true,
    playbackRates: [0.5, 1, 1.5, 2],
    fluid: true,
    sources: [
      {
        key: nextVideoIndex || videoUrl,
        src: videoUrl,
        type: "video/mp4",
      },
    ],

    tracks: [
      {
        kind: "subtitles",
        src: subUrl,
        srclang: "en",
        label: "English",
        default: true,
      },
    ],
  };

  // useEffect(() => {
  //   const newVideo = moduleDetails.videos[nextVideoIndex];
  //   dispatch(setCurrentVideo(newVideo));
  // }, [nextVideoIndex]);

  const randomVideos = [
    "https://player.vimeo.com/external/500571100.sd.mp4?s=79788b5a482edafb6c6b386c49576987ca9acb74&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/progressive_redirect/playback/726996990/rendition/360p/file.mp4?loc=external&oauth2_token_id=57447761&signature=6b3b84f7448ba4af3d3840e37638d9884673accccb4a23c572373774a27a809e",
    "https://player.vimeo.com/progressive_redirect/playback/714915842/rendition/360p/file.mp4?loc=external&oauth2_token_id=57447761&signature=b5569fc15e2abc85588c4d9daca40b5c47d800eba0eafa36c16810a049d15b4a",
  ];

  useEffect(() => {
    setLoading(true);
    if (currentVideo) {
      api
        .post("/api/v1/course/getSignedVideo", {
          videoID: `${currentVideo.id}`,
        })
        .then((res) => {
          // if (window.location.href.startsWith("http://localhost")) {
          const subUrl = new URL(res.data.urls.subUrl);
          const openSubUrl = subUrl.origin + subUrl.pathname;
          setSubtitleUrl(openSubUrl);
          dispatch(setSubUrl(openSubUrl));

          const videoUrl = new URL(res.data.urls.videoUrl);
          const openVideoUrl = videoUrl.origin + videoUrl.pathname;
          dispatch(setVideoUrl(openVideoUrl));
          setLoading(false);
          // dispatch(
          //   setVideoUrl(
          //     randomVideos[Math.floor(Math.random() * randomVideos.length)]
          //   )
          // );
          // } else {
          //   const url = new URL(res.data.url);
          //   dispatch(setVideoUrl(url));
          //   setTestUrl(url);
          // }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    dispatch(setPlayerLoading(true));
  }, [currentVideo]);

  const checkQuizElegibility = (moduleId) => {
    api
      .get(`/api/v1/practicalQuestion/passedmodule/${moduleId}`)
      .then((res) => console.log())
      .catch((error) => console.log(error));
  };
  const markVideoAsOngoing = () => {
    api
      .post("/api/v1/course/markvideoasongoing", {
        videoId: currentVideoRef.current,
      })

      .catch((err) => console.log(err));
  };

  const markVideoCompleted = (videoId, moduleId) => {
    api
      .post("/api/v1/course/markvideoascomplete", {
        videoId,
      })
      .then((res) => {
        dispatch(
          updateVideoUserState(
            moduleId,
            videoId,
            res.data.video_user.watchStatus
          )
        );
        // checkQuizElegibility(moduleId);
      })
      // .then(checkVideoWatchStatus(videoId, moduleId))
      .then(() => checkQuizElegibility(moduleId))
      .catch((err) => console.log(err));
  };

  const handleOverlayStylingOnPlay = () => {
    const player = playerRef.current.el();
    const overlay = player.querySelector(".vjs-text-track-display");
    if (overlay) {
      overlay.classList.remove("vjs-text-track-display");
    }
  };

  const handleOverlayStylingOnReady = () => {
    const currentPlayer = playerRef.current.el();
    const overlay = currentPlayer.querySelector(".vjs-text-track-no-display");
    if (overlay) {
      overlay.classList.add("vjs-text-track-display");
    }
  };

  const handleOverlayStylingOnCreate = () => {
    const currentPlayer = playerRef.current.el();
    const overlay = currentPlayer.querySelector(".vjs-text-track-display");
    if (overlay) {
      overlay.classList.add("vjs-text-track-no-display");
    }
  };

  const handlePlayerReady = (player) => {
    player.on("waiting", () => {});

    player.on("loadedmetadata", () => {
      if (nextVideoIndex === 0) {
        dispatch(setPlayerLoading(false));
      }
      handleOverlayStylingOnReady();
      setLoading(false);
    });

    player.on("error", () => {
      setLoading(false);
    });

    player.on("dispose", () => {});

    player.on("play", () => {
      markVideoAsOngoing();
      handleOverlayStylingOnPlay();
      // dispatch(setShowOverlay(false));
      // dispatch(setPlayerLoading(false));
    });

    player.on("ended", () => {
      markVideoCompleted(currentVideoRef.current, moduleRef.current);
      onVideoEnd();
      setPlay(false);
    });
  };

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      // videoElement.setAttribute("crossOrigin", "anonymous");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        handlePlayerReady && handlePlayerReady(player);
        // playerRef.current.crossOrigin = "anonymous";
        // player.addRemoteTextTrack(
        //   options.tracks[0]
        //   // [
        //   //   {
        //   //     kind: "subtitles",
        //   //     src: subUrlRef.current,
        //   //     // src: "https://cykea-bucket.s3.eu-west-1.amazonaws.com/85…cf-4a6e-bbae-96a35fb5a5d2_whatiscybersecurity.vtt",
        //   //     srclang: "en", // Language code
        //   //     // label: "en", // Display label for the subtitle
        //   //     default: true,
        //   //   },
        //   // ]
        // );

        ///////////// Add another to the overlay on video component load
        handleOverlayStylingOnCreate();
      }));
    } else {
      const player = playerRef.current;
      // playerRef.current.crossOrigin = "anonymous";
      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.addRemoteTextTrack(options.tracks[0]);
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // useEffect(() => {
  //   if (videoUrl) {
  //     return;
  //   } else {
  //     dispatch(setCurrentVideo(videoList[nextVideoIndex]));
  //   }
  // }, [nextVideoIndex, videoList, videoUrl, dispatch, setCurrentVideo]);

  useEffect(() => {
    if (moduleDetails.videos !== undefined) {
      setVideoList(moduleDetails?.videos);
      dispatch(setCurrentVideo(moduleDetails.videos[nextVideoIndex]));
    }
  }, [moduleIndex, nextVideoIndex, dispatch]);

  // useEffect(() => {
  //   if (moduleDetails.videos !== undefined) {
  //     setVideoList(moduleDetails?.videos);
  //   }
  // }, [videoUrl]);

  const toggleShowPracticalQuestion = () => {
    dispatch(setPlayerLoading(true));
    dispatch(setShowVideoPlayer(!showVideoPlayer));
    dispatch(setShowPracticalQustion(!showPracticalQustion));
  };

  const rewatchPrevVideo = () => {
    dispatch(decreaseVideoIndex());
    dispatch(setShowOverlay(false));
    dispatch(setPlayerLoading(true));
    setPlay(true);
  };

  return (
    <div data-vjs-player className="video__js-container">
      {loading ? <Loader content={true} loaderText="Loading Video..." /> : null}

      <div
        ref={videoRef}
        className={` vjs-big-play-centered vjs-theme-${themeName}`}
        id="my-player"
      >
        {showOverlay ? (
          <div className="video__overlay">
            <div className="video__overlay-content">
              <span className="video__overlay-icon--span">
                {videoList[nextVideoIndex]?.title ? (
                  <VideoOverlayIcon />
                ) : nextVideoIndex === videoList.length ? (
                  <LearningCheckIcon />
                ) : (
                  <PracticalQuestionIcon />
                )}
              </span>
              <span className="video__overlay-text--span">
                <p className="next__content-type--text">Up Next</p>
                <p className="next__content-title">
                  {videoList[nextVideoIndex]?.title
                    ? videoList[nextVideoIndex]?.title
                    : videoList.length === nextVideoIndex
                    ? "Practical Task"
                    : "On to the next"}
                </p>
                <p className="next__content-duration">
                  {videoList[nextVideoIndex]?.duration} Min(s)
                </p>
                <span
                  className="videoOverlay-CTAs--span"
                  style={{
                    display: "flex",
                  }}
                >
                  {nextVideoIndex === 0 ? null : (
                    <button
                      className="next__content-start--CTA"
                      style={{ marginRight: "2rem" }}
                      onClick={() => {
                        rewatchPrevVideo();
                      }}
                    >
                      Re-Watch
                    </button>
                  )}

                  {nextVideoIndex === videoList.length ? (
                    <button
                      className="next__content-start--CTA"
                      onClick={() => toggleShowPracticalQuestion()}
                    >
                      Start
                    </button>
                  ) : (
                    <button
                      className="next__content-start--CTA"
                      onClick={() => {
                        playNextVideo();
                        dispatch(setPlayerLoading(true));
                        setPlay(true);
                      }}
                    >
                      Start
                    </button>
                  )}
                </span>
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VideoPlayerTwo;
