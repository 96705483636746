const QuizOption = ({
  selectAnswer,
  setSelected,
  selected,
  option,
  currentSelected,
  setCurrentSelected,
  questionId,
  index,
  currentQuestionNum,
  answers,
}) => {
  return (
    <label className="container">
      <p className="answer__option">{option}</p>
      <input
        type="radio"
        name="radio"
        value={option}
        onChange={(e) => {
          selectAnswer(questionId, e.target.value);
          setCurrentSelected(e.target.value);
        }}
        checked={
          answers[currentQuestionNum]?.answer === option ||
          currentSelected === option
        }
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default QuizOption;
